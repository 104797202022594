import styled from "styled-components";

// const tabletQuery = '(max-width: 768px) and (min-width: 491px)'
// const desktopQuery = '(min-width: 769px)'
// const mobileQuery = '(max-width: 491px)'

const tabletQuery =
  "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const mobileQuery = "(max-width: 491px)";

const primaryWhite = ({ theme }) => theme.primaryWhite;

export const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media screen and ${desktopQuery} {
    display: none;
  }
`;

export const OuterWrapper = styled.div``;

export const Wrapper = styled.div`
  position: sticky;
  bottom: 20px;
  background: ${({ images }) =>
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${images.fullPic})`};
  background-repeat: no-repeat;
  height: 95.21vh;
  width: 93.36vw;

  @media screen and ${tabletQuery} {
    background-position: ${({ imageFull }) =>
      imageFull.tablet.backgroundPosition};
    background-size: ${({ imageFull }) => imageFull.tablet.backgroundSize};
  }

  @media screen and ${mobileQuery} {
    background-position: ${({ imageFull }) =>
      imageFull.mobile.backgroundPosition};
    background-size: ${({ imageFull }) => imageFull.mobile.backgroundSize};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const TextWrapper = styled.div`
  color: white;

  @media screen and ${tabletQuery} {
    padding: 0.73em 0.692em 1em;
    margin-top: ${({ TextWrapperFullProps }) =>
      TextWrapperFullProps.tablet.marginTop};
    margin-left: ${({ TextWrapperFullProps }) =>
      TextWrapperFullProps.tablet.marginLeft};
    margin-right: ${({ TextWrapperFullProps }) =>
      TextWrapperFullProps.tablet.marginRight};
  }

  @media screen and ${mobileQuery} {
    padding: 0em 0.2em 1em;
    margin-top: ${({ TextWrapperFullProps }) =>
      TextWrapperFullProps.mobile.marginTop};
    margin-left: ${({ TextWrapperFullProps }) =>
      TextWrapperFullProps.mobile.marginLeft};
    margin-right: ${({ TextWrapperFullProps }) =>
      TextWrapperFullProps.mobile.marginRight};
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  margin: 0;

  @media screen and ${tabletQuery} {
    font-size: 2.765em;
    margin-bottom: 0.2em;
  }

  @media screen and ${mobileQuery} {
    font-size: 2.082em;
    margin-bottom: 0.4em;
  }
`;

export const Body = styled.h2`
  font-weight: 400;
  margin: 0;

  @media screen and ${tabletQuery} {
    font-size: 1.382em;
  }

  @media screen and ${mobileQuery} {
    font-size: 1.388em;
  }
`;

export const XOutWrapper = styled.div`
  margin-top: 4.405vh;
  margin-right: 1.97em;
  width: 1.52em;
  height: 1.52em;
  &:hover {
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    margin-right: 2.845em;
    margin-top: 4.369vh;
    width: 2.162em;
    height: 2.162em;
  }

  @media screen and ${mobileQuery} {
    width: 24.44px;
    height: 24.44px;
    margin-top: 29.14px;
    margin-right: 2.22em;
  }
`;

export const XOut = styled.div`
  position: absolute;
  right: 0;
  top: 4.5vh;

  &:before,
  &:after {
    background-color: ${primaryWhite};
    position: absolute;
    right: 1.97em;
    content: "";
    height: 2em;
    width: 0.4em;
    border-radius: 15px;

    @media screen and ${tabletQuery} {
      right: 7vw;
      height: 3em;
    }

    @media screen and ${mobileQuery} {
      width: 5px;
      height: 2.3em;
      right: 2.2em;
    }
  }

  &:before {
    transform: translateX(-0.7em) translateY(-0.25em) rotate(45deg);
  }
  &:after {
    transform: translateX(-0.7em) translateY(-0.25em) rotate(-45deg);
  }

  ${XOutWrapper}:hover &:before, ${XOutWrapper}:hover &:after {
    cursor: pointer;
  }
`;
