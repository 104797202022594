import React, { Fragment, useEffect } from "react";
import Header from "../Header/Header";
import ArrowLink from "../ArrowLink/ArrowLink";
import ExperienceList from "./ExperienceList";
import MoneyBill from "../../assets/images/money-bill@2x.jpg";
import QRScanner from "../../assets/images/eclipse@2x.jpg";
import GrinStars from "../../assets/images/grin-stars@2x.jpg";
import OneFingerClick from "../../assets/images/one-finger-click@2x.jpg";

import {
  Wrapper,
  IconList,
  HowWeDoText,
  IntroSection,
  InformationSection,
  UXListSection,
  BackgroundDiagonal,
  HeaderWrapper,
  Text,
  Heading,
  SubHeading,
  PhoneDiv,
  PageWrap,
  ArrowLinkWrapper,
  DemosSection
} from "./WhatWeDoStyles";
import useWindowSize from "./useWindowSize";

const WhatWeDo = () => {
  const LINK_TEXT = "LEARN MORE ABOUT OUR FEATURES";
  const LINK = "/features";

  useEffect(() => {
    document.getElementById("root").style.height = "2500px";
    return () => {
      document.getElementById("root").style.height = "auto";
      console.warn("worked");
    };
  }, []);

  useEffect(()=>{
    window.addEventListener("scroll", scrollListener)
    return () => window.removeEventListener("scroll", scrollListener)
  })

  const scrollListener = (e) => {
    const demofromTop = document.getElementById("demo-section-list").getBoundingClientRect().top

    if( demofromTop <= 0){
      // alert("Working")
      // document.getElementById("body").style.overflow = "hidden"
      // document.getElementById("pagewrap").style.overflow="scroll";
      // document.getElementById("demo-section-list").style.position = "absolute"
      // document.getElementById("demo-section-list").style.top = 0
      // document.getElementById("demo-section-list").style.left=0;
      // document.getElementById("demo-section-list").style.right=0;
      // document.getElementById("demo-section-list").style.bottom=0;
      // document.getElementById("demo-section-list").style.overflow = "scroll"
      // document.getElementById("demo-section-list").style.minHeight= `${window.innerHeight}px`;
      // document.getElementById("demo-section-list").style.position= "fixed";
      // document.getElementById("demo-section-list").style.overflowY= "scroll";
      // document.getElementById("demo-section-list").style.zIndex=20;
    }
  }

  return (
    <Fragment>
    <PageWrap id="pagewrap">
      <IntroSection>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BackgroundDiagonal />
        <Text>
          <Heading>
            <h1 className="secondary-color">
              <span className="primary-color">What&nbsp;</span>We&nbsp;Do&nbsp;
            </h1>
          </Heading>
          <SubHeading>
            <h3 className="text">we create experiences that convert.</h3>
            <p className="text first-text">
              Websites don’t convert on mobile and nobody is downloading apps.
              They don’t turn traffic into revenue and they are expensive.
            </p>
            <p className="text">
              mobUX uses progressive web apps to deliver a truly mobile-first
              marketing experience{" "}
              <span className="break-sentence">that converts.</span>
            </p>
          </SubHeading>
        </Text>
        <ArrowLinkWrapper>
          <ArrowLink navLink="#demo-section-list" />
        </ArrowLinkWrapper>
        <PhoneDiv />
      </IntroSection>
      <InformationSection>
        <HowWeDoText>
          <Heading>
            <div className="desktop">
              <h2 className="secondary-color">
                <span className="primary-color">How&nbsp;</span>
                we&nbsp;do&nbsp;it&nbsp;&#38;
                <span className="primary-color"> What</span> this means for you?
              </h2>
            </div>
            <div className="mobile">
              <h2 className="secondary-color">
                <span className="primary-color">How&nbsp;</span>
                we&nbsp;do&nbsp;it And <br/>
                <span className="primary-color"> What</span> this means for you?
              </h2>
            </div>
          </Heading>
          <SubHeading>
            <p className="text howwedo-text">
              {" "}
              We do it by creating an interactive, mobile native experience
              quickly that’ll deliver targeted and high-impact engagements to our customers.
            </p>
          </SubHeading>
        </HowWeDoText>
        <Wrapper>
          <ArrowLink text={LINK_TEXT} navLink={LINK} />
        </Wrapper>
        <IconList>
          <ul className="icon__list">
            <li className="icon__list-item icon__list-item_type_money">
              {/* <div className="circle"> */}
                <img className="circle" src={MoneyBill} alt="money-icon" />
              {/* </div> */}
              <p className="icon__text icon__money-text">
                <span className="primary-color">Get more<br/></span> from your ad
                spend
              </p>
            </li>
            <li className="icon__list-item icon__list-item_type_grin">
              {/* <div className="circle"> */}
                <img className="circle" src={GrinStars} alt="grin-icon" />
              {/* </div> */}
              <p className="icon__text icon__grin-text">
                <span className="primary-color">Create memories<br/></span> for your
                traffic
              </p>
            </li>
            <li className="icon__list-item icon__list-item_type_click">
              {/* <div className="circle"> */}
                <img
                  className="circle"
                  src={OneFingerClick}
                  alt="click-icon"
                />
              {/* </div> */}
              <p className="icon__text icon__click-text">
                <span className="primary-color">Convert<br/></span> clicks into sales
              </p>
            </li>
            <li className="icon__list-item icon__list-item_type_scan">
              {/* <div className="circle"> */}
                {/* <div className="icon__scan-line"></div> */}
                <img className="circle" src={QRScanner} alt="scan-icon" />
              {/* </div> */}
              <p className="icon__text icon__scan-text">
                <span className="primary-color">Make every moment<br/></span> a
                digital experience
              </p>
            </li>
          </ul>
        </IconList>
        <button className="learn-more-button">Learn More</button>
      </InformationSection>
    </PageWrap>

    {/* <div style={{background:"red",height:5000,textAlign:"center"}}> */}
    {/* <Heading style={{position:"sticky",top:0}} id="our-demos-title">
        <h2 className="secondary-color" >
          <span className="secondary-color">Our&nbsp;</span>
          <span className="primary-color">Demos</span>
        </h2>
    </Heading> */}
    <UXListSection id="demo-section-list">
          <ExperienceList />
        </UXListSection>
          
        {/* </div> */}
    </Fragment>
  );
};

export default WhatWeDo;
