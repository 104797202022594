import React, { useState, useEffect, useMemo } from "react";

var __assign = function () {
  __assign =
    Object.assign ||
    function __assign(t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s)
          if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
      return t;
    };
  return __assign.apply(this, arguments);
};

var className = "grid-container";
var id = "grid-list";

var getX = function (col, width) {
  return col * width + 34;
};

var getY = function (row, height) {
  return row * height + 10;
};

var Grid = function (props) {
  var children = props.children,
    gridLayout = props.gridLayout,
    unitWidth = props.unitWidth,
    unitHeight = props.unitHeight,
    noOfCols = props.noOfCols,
    filters = props.filters;

  var width = unitWidth * noOfCols + (noOfCols - 1) * 34;

  var _a = useState(gridLayout),
    cards = _a[0],
    updateCards = _a[1];

  var clonedChildrens = useMemo(
    function () {
      return children
        .filter(function (child) {
          return (
            cards.findIndex(function (g) {
              return g.id === child.key;
            }) !== -1
          );
        })
        .map(function (child) {
          var key = child.key;
          var layoutIndex = cards.findIndex(function (g) {
            return g.id === key;
          });
          var cardLayout = cards[layoutIndex];
          var _a = cardLayout.h,
            h = _a === void 0 ? 1 : _a,
            _b = cardLayout.w,
            w = _b === void 0 ? 1 : _b,
            col = cardLayout.col,
            row = cardLayout.row;
          var style = {
            transition: "all 0.5s ease-in-out",
            width: unitWidth * w + "px",
            height: unitHeight * h + "px",
            left: "" + (col ? 34 * col * w + "px" : 0),
            top: "" + (row ? 0.075 * unitHeight * h * row * h + "px" : 0),
            transform:
              "translate(" +
              getX(col, unitWidth) +
              "px, " +
              getY(row, unitHeight) +
              "px)",
            position: "absolute",
          };
          let x = __assign({}, child, {
            props: __assign({}, child.props, { style: style }),
          });

          return x;
        });
    },
    [cards, children, unitHeight, unitWidth, noOfCols]
  );

  useEffect(
    function () {
      var currentCol = 0;
      var intersection = function (arr1, arr2) {
        return arr1.filter(function (i) {
          return (
            -1 !==
            arr2.findIndex(function (a) {
              return a === i;
            })
          );
        });
      };

      var checkedFilterLabels = filters
        .filter(function (f) {
          return f.isChecked;
        })
        .map(function (f) {
          return f.label;
        });

      var filteredCards =
        checkedFilterLabels === "all"
          ? filteredCards
          : gridLayout.filter(function (card) {
              return (
                intersection(checkedFilterLabels, card.filter).length ||
                checkedFilterLabels.includes("all")
              );
            });

      var mappedCards = filteredCards.map(function (card, i) {
        if (currentCol === noOfCols) {
          currentCol = 0;
        }
        return __assign({}, card, {
          row: Math.floor(i / noOfCols),
          col: currentCol++,
        });
      });

      updateCards(mappedCards);
    },
    [filters, gridLayout, noOfCols]
  );

  var style = {
    width: width,
  };

  return React.createElement("div", { className, style, id }, clonedChildrens);
};

export default Grid;
