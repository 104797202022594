import React, { useState, useEffect } from 'react'
import { Wrapper, ImageWrapper, Text, Title, Body, ArrowLinkWrapper } from './ClientStyles'
import { clientData } from './clientData'
import ArrowLink from '../ArrowLink/ArrowLink'

const Client = ({ client, setCarouselClient, desktop }) => {
    const [hovering, setHovering] = useState(false)
    const [touchScreen, setTouchScreen] = useState(null)

    useEffect(() => {
        const usingTouchScreen = !!('ontouchstart' in window) || !!('msmaxtouchpoints' in window.navigator)
        setTouchScreen(usingTouchScreen)
    }, [])

    if (client) {
        const { logo, hoverData } = clientData[client]
        return (
            <Wrapper
                onMouseOver={() => {
                    if(!touchScreen && desktop) setHovering(true)
                }}
                onMouseLeave={() => {
                    if(!touchScreen && desktop) setHovering(false)
                }}
                onClick={e => {
                    // if(touchScreen || !desktop) 
                    setCarouselClient(client)
                }}
            >
                <Text hovering={hovering} hoverData={hoverData}>
                    {/* <Title>{hoverData.title}</Title> */}
                    <Body title={hoverData.title} >{hoverData.body}</Body>
                    <ArrowLinkWrapper 
                    // onClick={() => setCarouselClient(client)}
                    >
                        <ArrowLink 
                            text="MORE"
                            disable={true} 
                            white={true} 
                            smaller={true} 
                            showArrow={false}
                        />
                    </ArrowLinkWrapper>
                </Text>
                <ImageWrapper hovering={hovering} title={hoverData.title} logo={logo} >
                    <img src={logo.image}  />
                </ImageWrapper>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper></Wrapper>
        )
    }

    
}

export default Client