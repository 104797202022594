import React, { useState, useEffect, useContext } from "react";
import validate from "./ContactUsFormValidation";
import { FormWrapper, FormField, FormBody, FormSubmitButton, ErrorMessage } from "./ContactUsFormStyles";
import Button from "../Button/Button";
import { ModalContext } from '../Modal/modalContext';

const API_URL_EMAIL = "https://api.themobux.com/v1/email"
const API_URL_CONTACT = "https://api.themobux.com/v1/contact"

const ContactUsForm = () => {
   const initialValues = {
      personName: "",
      emailAddress: "",
      subject: "",
      message: "",
   };
   const [values, setValues] = useState(initialValues);
   const [errors, setErrors] = useState({});
   const [isSubmitting, setIsSubmitting] = useState(false);
   const { handleModal } = useContext(ModalContext);

   useEffect(() => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
         handleModal("loading");
         sendEmail();
      } else if (isSubmitting) {
         setIsSubmitting(false)
      }
   }, [values, errors, isSubmitting]);

   const resetFields = () => {
      setValues(initialValues);
   };

   const handleChange = (e) => {      
      e.persist();
      setValues((values) => {
         let newValues = { ...values, [e.target.name]: e.target.value };
         setErrors(validate(newValues, e.target.name));
         return newValues;
      });   
   };

   const _buildDataEmail = () => {
      const data = {
         "list": 2,
         "name": values.personName,
         "email": values.emailAddress,
         "phone": 555555555,
         "subject": values.subject,
         "message": values.message
      }

      return data
   };

   const _buildDataContact = () => {
      const data = {
         "list": 2,
         "name": values.personName,
         "email": values.emailAddress,
         "phone": 555555555
      }

      return data
   }

   const sendEmail = () => {
      const dataEmail = _buildDataEmail();
      const dataContact = _buildDataContact()

      fetch(API_URL_EMAIL, {
         method: "POST",
         headers: {
            "Content-Type": "text/plain",
         },
         body: JSON.stringify(dataEmail),
         mode: "cors"
      })
      .then((response) => {  
         if (response.ok) {
            addContact(dataContact)
         }
         else {
            handleModal("sent-error", "contact-us");
         }             
      })            
      .catch((error) => {             
         handleModal("sent-error", "contact-us");
      });

      setIsSubmitting(false);
   };

   const addContact = (dataContact) => {
      fetch(API_URL_CONTACT, {
         method: "POST",
         headers: {
            "Content-Type": "text/plain",
         },
         body: JSON.stringify(dataContact),
         mode: "cors"
      })
      .then((response) => {  
         if (response.ok) {
            handleModal("sent-success", "contact-us");
            resetFields()
         }
         else {
            handleModal("sent-error", "contact-us");
         }             
      })            
      .catch((error) => {             
         handleModal("sent-error", "contact-us");
      });

      setIsSubmitting(false);
   };

   const handleSubmit = async (e) => {
      if (e) e.preventDefault();
      setErrors(validate(values, "all"));
      setIsSubmitting(true);
   };

   return (
      <FormWrapper>
         <FormBody>
            <FormField>
               <input
                  type='text'
                  id='personName'
                  name='personName'
                  value={values.personName}
                  placeholder='Name'
                  onChange={handleChange}
                  aria-label='Name'
                  aria-describedby='errorName'
               />
               <ErrorMessage>
                  <label id='errorName'>{errors.personName && errors.personName}</label>
               </ErrorMessage>
            </FormField>
            <FormField>
               <input
                  type='text'
                  id='emailAddress'
                  name='emailAddress'
                  value={values.emailAddress}
                  placeholder='Email'
                  onChange={handleChange}
                  aria-label='Email'
                  aria-describedby='errorEmail'
               />
               <ErrorMessage>
                  <label id='errorEmail'>{errors.emailAddress}</label>
               </ErrorMessage>
            </FormField>
            <FormField>
               <input
                  type='text'
                  id='subject'
                  name='subject'
                  value={values.subject}
                  placeholder='Subject'
                  onChange={handleChange}
                  aria-label='Subject'
                  aria-describedby='errorSubject'
               />
               <ErrorMessage>
                  <label id='errorSubject'>{errors.subject}</label>
               </ErrorMessage>
            </FormField>
            <FormField>
               <label className='field-label message'>Message</label>
               <textarea
                  id='message'
                  name='message'
                  value={values.message}                  
                  onChange={handleChange}
                  aria-label='Message'
                  aria-describedby='errorMessage'                  
               />
               <ErrorMessage>
                  <label id='errorMessage'>{errors.message}</label>
               </ErrorMessage>
            </FormField>
         </FormBody>
         <FormSubmitButton>
            <Button handleSubmit={handleSubmit}/>
            {/* <input type='submit' value='submit' /> */}
         </FormSubmitButton>
         <ErrorMessage>
            <label id='errorServer'>{errors.server}</label>
         </ErrorMessage>
      </FormWrapper>
   );
};

export default ContactUsForm;
