import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";

export const ScrollWrapper = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 2rem;
  // top:90vh;
  // right: 2rem;
  right: 0.5rem;
  transition: opacity 1s;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

export const ScrollLogo = styled.div`
  display: flex;
  justify-content: center;
  width: 15.5px;
  height: 28px;
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: 1.5px solid white;

  .dot {
    width: 2px;
    height: 2px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid white;
    margin-top: 6px;
    
  }

  @media screen and (max-width: 768px) {

    .dot{
        animation: animate-dot ease-in-out 1.5s;
        animation-delay: 100;
        animation-iteration-count: 8;
    }

    @keyframes animate-dot {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(2px);
        }
        100% {
          transform: translateY(0px);
        }
  }

  

   
`;

export const DownArrow = styled(IoIosArrowDown)`
  color: white;
  margin-top: 2px;

  @media screen and (max-width: 768px) {
    animation: animate-down-arrow ease-in-out 1.7s;
    animation-iteration-count: 8;
    @keyframes animate-down-arrow {
      0% {
        transform: translateY(0px);
      }
      50% {
        transform: translateY(2px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
`;
