import React, { useState, useRef, useEffect } from "react";
import {
  Wrapper,
  HamburgerDashes,
  Titles,
  TitleNavLink,
  Policies,
  PolicyNavLink,
  SocialIcons,
  FacebookLink,
  FacebookIcon,
  InstagramLink,
  InstagramIcon,
  LinkedinLink,
  LinkedinIcon,
  TwitterLink,
  TwitterIcon,
  TermsAndConditionsNavLink,
  XOut,
  TermsAndConditionsText,
  XOutWrapper,
  PolicyLink,
} from "./HamburgerMenuStyles";

// Icons
import { SiFacebook, SiLinkedin, SiTwitter, SiInstagram } from "react-icons/si";

const HamburgerMenu = ({ flippedColors, allPurple }) => {
  const [menuIsOpen, _setMenuIsOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const menuIsOpenRef = useRef(menuIsOpen);

  const setMenuIsOpen = (data) => {
    menuIsOpenRef.current = data;
    _setMenuIsOpen(data);
  };

  const scrollListener = (e) => {
    setMenuIsOpen(false);
  };

  useEffect(() => {
    const wrapper = document.querySelector("div div:first-child");
    wrapper.addEventListener("touchmove", touchMoveListener, false);
    return () => wrapper.removeEventListener("touchmove", touchMoveListener);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  });

  const touchMoveListener = (e) => {
    if (menuIsOpenRef.current) e.preventDefault();
  };

  return (
    <>
      <HamburgerDashes
        flippedColors={flippedColors}
        allPurple={allPurple}
        className="hamburger-menu-icon"
        onClick={() => setMenuIsOpen(true)}
      />
      <Wrapper
        menuIsOpen={menuIsOpen}
        className="hamburger-menu-wrapper"
        onTouchStart={(e) => {
          setTouchStart(e.touches[0].clientX);
        }}
        onTouchMove={(e) => {
          if (e.touches[0].clientX - touchStart > 30) {
            setMenuIsOpen(false);
          }
        }}
        onTouchEnd={(e) => setTouchStart(null)}
      >
        <XOutWrapper onClick={() => setMenuIsOpen(false)}>
          <XOut />
        </XOutWrapper>
        <Titles>
          {/* <TitleNavLink to="/" className="home-link">
            HOME
          </TitleNavLink> */}
          <TitleNavLink to="/" className="what-we-do-link">
            HOME
          </TitleNavLink>
          <TitleNavLink to="/what-we-do" className="what-we-do-link">
            WHAT WE DO
          </TitleNavLink>
          <TitleNavLink to="/features" className="features-link">
            FEATURES
          </TitleNavLink>
          <TitleNavLink to="/our-clients" className="features-link">
            OUR CLIENTS
          </TitleNavLink>
          <TitleNavLink to="/contact-us" className="contact-us-link">
            CONTACT US
          </TitleNavLink>
        </Titles>
        <Policies>
          <PolicyNavLink to="/cookie-policy" className="cookie-policy-link">
            COOKIE POLICY
          </PolicyNavLink>
          <TermsAndConditionsNavLink
            className="terms-and-conditions-link"
            to="/terms-and-conditions"
          >
            <TermsAndConditionsText className="terms-top">
              TERMS &
            </TermsAndConditionsText>
            <TermsAndConditionsText className="terms-bottom">
              CONDITIONS
            </TermsAndConditionsText>
          </TermsAndConditionsNavLink>
          <PolicyNavLink to="/privacy-policy" className="privacy-policy-link">
            PRIVACY POLICY
          </PolicyNavLink>
          {
            // <PolicyNavLink
            //   to="/careers"
            //   className="careers-link">CAREERS
            // </PolicyNavLink>
          }
          <PolicyLink
            href="https://angel.co/company/themobux/jobs"
            target="_blank"
            className="careers-link"
          >
            CAREERS
          </PolicyLink>
        </Policies>
        <SocialIcons>
          <FacebookLink
            className="facebook-link"
            href="https://www.facebook.com/theMobux"
            target="_blank"
          >
            <FacebookIcon />
          </FacebookLink>
          <InstagramLink
            className="instagram-link"
            href="https://www.instagram.com/themobux/"
            target="_blank"
          >
            <InstagramIcon />
          </InstagramLink>
          <LinkedinLink
            className="linkedin-link"
            href="https://www.linkedin.com/company/mobux/"
            target="_blank"
          >
            <LinkedinIcon />
          </LinkedinLink>
          <TwitterLink
            className="twitter-link"
            href="https://twitter.com/themobux"
            target="_blank"
          >
            <TwitterIcon />
          </TwitterLink>
        </SocialIcons>
      </Wrapper>
    </>
  );
};

export default HamburgerMenu;
