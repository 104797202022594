import styled from "styled-components";
import PhoneImageLarge from "../../assets/images/phone_bg_large.png";
import PhoneImageMediumLarge from "../../assets/images/phone_bg_medium_large.png";
import PhoneImageSmall from "../../assets/images/phone_bg_small.png";
import facebook from "../../assets/images/facebook.png";
import facebookHover from "../../assets/images/facebook-hover.png";
import instagram from "../../assets/images/instagram.png";
import instagramHover from "../../assets/images/instagram-hover.png";
import linkedin from "../../assets/images/linkedin.png";
import linkedinHover from "../../assets/images/linkedin-hover.png";
import twitter from "../../assets/images/twitter.png";
import twitterHover from "../../assets/images/twitter-hover.png";
// icons
import { SiFacebook, SiInstagram, SiTwitter, SiLinkedin } from "react-icons/si";

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const secondaryGrey = ({ theme }) => theme.secondaryGrey;
const primaryBlack = ({ theme }) => theme.primaryBlack;
const primaryGreen = ({ theme }) => theme.primaryGreen;

// const tabletQuery = '(max-width: 768px) and (min-width: 491px)'
// const desktopQuery = '(min-width: 769px)'
// const mobileQuery = '(max-width: 491px)'

const tabletQuery =
  "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const shortDesktopQuery = "(min-width: 769px) and (max-height: 801px)";
const mobileQuery = "(max-width: 491px)";

export const Container = styled.div`
  display: grid;

  @media only screen and ${desktopQuery} {
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 35.5% 29.5% 35%;
    grid-template-areas:
      "head head head"
      "text phon ftxt"
      "text phon form"
      "arro phon form";
    height: min-content;
    min-height: 100vh;
    background: linear-gradient(
      -60deg,
      #310854 1%,
      ${primaryPurple} 50.5%,
      ${primaryWhite} 50.5%
    );
  }

  @media only screen and ${tabletQuery} {
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: 46.7% auto 40%;
    grid-template-areas:
      "head head head"
      "text text phon"
      "arro arro phon"
      "ftxt form form";
  }

  @media only screen and ${mobileQuery} {
    grid-template-rows: repeat(6, auto);
    grid-template-columns: 35% 65%;
    grid-template-areas:
      "head head"
      "text text"
      "arro phon"
      "ftxt ftxt"
      "form form"
      "soci soci";
  }
`;

export const BackgroundDiagonal = styled.div`
  background-image: linear-gradient(
    to right bottom,
    ${primaryWhite} 50%,
    ${primaryPurple} 50%
  );

  @media screen and ${desktopQuery} {
    display: none;
  }

  @media screen and ${tabletQuery} {
    margin-top: 13vh;
    grid-area: 2 / 1 / 4 / 4;
  }

  @media screen and ${mobileQuery} {
    margin-top: 35vh;
    grid-area: 2 / 1 / 4 / 3;
    background: linear-gradient(
      -20deg,
      // #310854 1%,
      ${primaryPurple} 50%,
      ${primaryWhite} 50%
    );
  }

  @media screen and ${mobileQuery} and (max-width: 320px) {
    margin-top: 28vh;
  }
`;

export const HeaderWrapper = styled.div`
  grid-area: head;
`;

export const Text = styled.div`
  grid-area: text;

  @media screen and ${desktopQuery} {
    margin: 20.5vh 0 0 4.115vw;
    height: min-content;
  }

  @media screen and ${tabletQuery} {
    margin: 8vh 0 0 3.776vw;
  }

  @media screen and ${mobileQuery} {
    text-align:center;
    margin: 4.7vh 0 0 0vw;
  }
`;

export const Heading = styled.div`
  grid-area: text;

  .secondary-color {
    color: black;
  }

  .primary-color {
    color: ${primaryPurple};
  }

  h1 {
    font-weight: 500;
    letter-spacing: -0.8px;
    opacity: 1;
    line-height: 0.83em;
    margin: 0;
  }

  .that-text, .drives-text {
    font-size: 0.65em;
  }
  h1 {
    font-size: 3em;
  }

  @media only screen and ${desktopQuery} {
    .mobile-text {
      display: inline;
    }

    .that-text {
      display: inline-block;
    }

    .drives-text {
      display: inline;
    }

    .action-text {
      display: inline;
    }
  }

  // Desktop Large
  @media only screen and (max-width: 1200px) {
    h1 {
      font-size: 2.5em;
    }
  }

  @media only screen and ${tabletQuery} {
    h1 {
      width: 10px;
      font-size: 2.56em;
      line-height: 1.1em;
      letter-spacing: 0.03em;
    }

    .that-text::before {
       {
        content: " ";
        display: block;
      }
    }
  }

  @media only screen and ${mobileQuery} {
    h1 {
      font-size: 2.36em;
      line-height: 0.75em;
      letter-spacing: 0em;
      margin-bottom: 2.7vh;
      width: 100%;
    }

    .that-text::before {
       {
        content: " ";
        display: block;
      }
    }

    .drives-text {
      display: inline;
    }
  }
`;

export const SubHeading = styled.div`
  margin-top: 2em;
  text-align: left;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.25;
  color: ${primaryBlack};
  opacity: 1;

  .no-break {
    white-space: nowrap;
  }

  @media only screen and ${desktopQuery} {
    font-size: 0.9em;
  }

  @media only screen and ${desktopQuery} and (min-width: 1440px) {
    font-size: 1em;
  }

  @media only screen and ${tabletQuery} {
    font-size: 3.125vw;
  }

  @media only screen and ${mobileQuery} {
    font-size: 1.11em;
    text-align:center;
    width:80%;
    margin: auto;
    font-weight: 400;
    // .no-break{
    //   display:inline-block
    // }
  }
`;

export const PhoneDiv = styled.div`
  grid-area: phon;

  @media only screen and ${desktopQuery} {
    width: 30vw;
    min-height: 50.625vw;
    height: 90vh;
    margin-top: -3vw;
    background: url(${PhoneImageLarge}) center center no-repeat;
    background-size: contain;
  }

  @media only screen and ${tabletQuery} {
    width: 52vw;
    height: 101.125vw;
    margin-top: 14.7vh;
    margin-left: -13vw;
    background: url(${PhoneImageMediumLarge}) top center no-repeat;
    background-size: contain;
    margin-bottom: 12vh;
  }

  @media only screen and ${mobileQuery} {
    width: 62.5vw;
    height: 105.468vw;
    margin-bottom: -4vh;
    margin-top: 2vh;
    margin-left: -7vw;
    margin: 14.5vh 0 -16vh -17vw;
    background: url(${PhoneImageSmall}) top center no-repeat;
    background-size: contain;
    z-index: 1;
  }
`;

export const MobileButtonWrapper = styled.div`
  grid-area: arro;
  display:none;

  .mobile-button{
    width:160px;
    height: 36px;
    background-color:${primaryPurple};
    color:white;
    font-size:12px;
    font-weight:500;
    border-radius:5px;
    display:flex;
    align-items:center;
    justify-content:center
  }

  @media only screen and ${desktopQuery} {
    margin: 1.2em 0 0 4.115vw;
  }

  @media only screen and ${tabletQuery} {
    margin: 3em 0 0 3.776vw;
    width: 10em;
    display:flex;

  }

  @media screen and ${mobileQuery} {
    margin: 2.3em 0 0 0;
    display:flex;
    width:100vw;
    justify-content:center;
  }
`;

export const ArrowLinkWrapper = styled.div`
  grid-area: arro;

  @media only screen and ${desktopQuery} {
    margin: 1.2em 0 0 4.115vw;
  }

  @media only screen and ${tabletQuery} {
    margin: 3em 0 0 3.776vw;
    width: 10em;
    display:none
  }

  @media screen and ${mobileQuery} {
    margin: 1.5em 0 30vh 5.06vw;
    display:none
  }
`;

export const FormText = styled.div`
  grid-area: ftxt;
  color: ${primaryWhite};
  white-space: pre-line;

  @media only screen and ${desktopQuery} and (min-height: 800px) {
    font-size: 1em;
    margin: 20.44vh 0 1.133em 1.7em;
  }

  @media only screen and ${shortDesktopQuery} {
    margin: 16.44vh 0 1.133em 0;
  }

  @media only screen and ${tabletQuery} {
    background-color: ${primaryPurple};
    padding: 7px 1vw 0 3.776vw;
    font-size: 1.382em;
    font-weight:500
  }

  @media only screen and ${mobileQuery} {
    background-color: ${primaryPurple};
    padding: 28vh 5.06vw 4vh;
    font-size: 1.505em;
    text-align:center;
    font-weight:500
  }
`;

export const FormSection = styled.section`
  grid-area: form;

  @media only screen and ${desktopQuery} and (min-width: 1440px) {
    font-size: 1.15em;
  }

  @media only screen and ${desktopQuery} {
    font-size: 0.8em;
    width: 24.5vw;
    margin-left:2em
  }

  @media only screen and ${tabletQuery} {
    background-color: ${primaryPurple};
    padding: 0 10vw 12vh 0;
  }

  @media only screen and ${mobileQuery} {
    background-color: ${primaryPurple};
    font-size: 1.15em;
    padding: 4.06vw 8% 10vh 8%;
  }
`;

export const SocialIcons = styled.div`
  grid-area: soci;
  background-color: ${primaryPurple};
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-bottom: 5vw;

  @media screen and ${desktopQuery}, ${tabletQuery} {
    display: none;
  }
`;

const SocialImage = styled.a`
  width: 8.91vw;
  height: 8.91vw;
  margin: 0 1.856vw;
  background-size: contain;
  background-repeat: no-repeat;
  text-decoration: none;
`;

export const FacebookLink = styled(SocialImage)`
  //   background-image: url(${facebook});
  //   &:hover {
  //     background-image: url(${facebookHover});
  //   }
`;

export const InstagramLink = styled(SocialImage)`
  //   background-image: url(${instagram});
  //   &:hover {
  //     background-image: url(${instagramHover});
  //   }
`;

export const LinkedinLink = styled(SocialImage)`
  //   background-image: url(${linkedin});
  //   &:hover {
  //     background-image: url(${linkedinHover});
  //   }
`;

export const TwitterLink = styled(SocialImage)`
  //   width: 10.89vw;
  //   background-image: url(${twitter});
  //   &:hover {
  //     background-image: url(${twitterHover});
  //   }
`;

export const FacebookIcon = styled(SiFacebook)`
  width: 100%;
  height: 100%;
  color: white;
  &:hover {
    color: ${primaryGreen};
  }
`;
export const InstagramIcon = styled(SiInstagram)`
  width: 100%;
  height: 100%;
  color: white;
  &:hover {
    color: ${primaryGreen};
  }
`;
export const LinkedinIcon = styled(SiLinkedin)`
  width: 100%;
  height: 100%;
  color: white;
  &:hover {
    color: ${primaryGreen};
  }
`;
export const TwitterIcon = styled(SiTwitter)`
  width: 100%;
  height: 100%;
  color: white;
  &:hover {
    color: ${primaryGreen};
  }
`;
