import styled from "styled-components";
import rightArrow from "../../assets/images/right-arrow.png";
import rightWhiteArrow from "../../assets/images/right-white-arrow.png";
import { NavLink } from "react-router-dom";

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;

const tabletQuery =
  "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const mobileQuery = "(max-width: 491px)";

export const NavLinkWrapper = styled.a`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  flex-wrap: wrap;

  @media screen and ${tabletQuery}, ${mobileQuery} {
    height: 3em;
  }
`;

export const LinkWrapper = styled.a`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  flex-wrap: wrap;

  @media screen and ${tabletQuery}, ${mobileQuery} {
    height: 3em;
  }
`;

export const DisabledLink = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  flex-wrap: wrap;

  @media screen and ${tabletQuery}, ${mobileQuery} {
    height: 3em;
  }
`;

export const Text = styled.div`
  font-family: Rubik;
  font-size: ${({ smaller }) => (smaller ? "1.05vw" : "0.9231em")};
  font-weight: 500;
  color: ${(props) => (props.white ? primaryWhite : primaryPurple)};
  margin-right: 0.505em;

  @media screen and ${tabletQuery} {
    font-size: ${({ smaller }) => (smaller ? "2.2vw" : "1.2673em")};
  }

  @media screen and ${mobileQuery} {
    font-size: 1.179em;
  }
`;

// margin-bottom: 0.2em;
export const ArrowImage = styled.div`
  background-image: url(${(props) =>
    props.white ? rightWhiteArrow : rightArrow});
  background-size: 2.13em 0.81em;
  background-repeat: no-repeat;
  width: 2.13em;
  height: 0.81em;

  @media screen and ${tabletQuery} {
    width: 2.501em;
    height: 0.9143em;
    background-size: 2.501em 0.9143em;
  }

  @media screen and ${mobileQuery} {
    width: 3.013em;
    height: 1.101em;
    background-size: 3.013em 1.101em;
  }
`;
