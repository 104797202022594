import styled from "styled-components";

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const secondaryGrey = ({ theme }) => theme.secondaryGrey;
const primaryBlack = ({ theme }) => theme.primaryBlack;

const tabletQuery = '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
const desktopQuery = '(min-width: 769px) and (orientation: landscape)'
const mobileQuery = '(max-width: 491px)'
const smallDesktopQuery = `${desktopQuery} and (max-height: 800px)`

export const Wrapper = styled.div`
    background-color: #EDEDED;
    cursor:pointer;
    height: 100%;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */

    @media screen and ${tabletQuery}{
        cursor: pointer;
    }
`;


// display: ${({ hovering }) => hovering ? 'none' : 'default'}; 
export const ImageWrapper = styled.div`
    // background-image: url(${({logo}) => logo.image});
    background-repeat: no-repeat;
    background-size: ${({logo}) => logo.backgroundSize};
    background-position: ${({logo}) => logo.backgroundPosition};
    height: 100%;
    width: 100%;
    overflow:hidden;


    img{
        width:${(props) => props.title === "Wild For" ? "100%" 
        : props.title === "Buddy Insurance" ? "50%" 
        : props.title === "House Beer" ? "50%"
        : "70%" };
        margin:0;
        padding:0;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%)
    }

    @media screen and ${mobileQuery}{
        background-size: ${({logo}) => logo.backgroundSizeMobile ? logo.backgroundSizeMobile : logo.backgroundSize };
    }
`;

export const Text = styled.div`
    position: absolute;
    opacity: ${({ hovering }) => hovering ? '1' : '0'}; 
    background-color: ${({ hoverData }) => hoverData.backgroundColor};
    height: 100%;
    width: 100%;
    transition: opacity 0.2s linear;
    color: white;
    z-index:2
`

export const Title = styled.h2`
    margin: 0.781vw 0.99vw 0 0.99vw;
    font-size: 14.2px;
    margin-bottom: 0.5em;
    font-weight: bold;

    @media screen and ${desktopQuery} and (min-height: 800px){
        font-size: 16px;
    }

    @media screen and ${desktopQuery} and (min-height: 900px){
        font-size: 20.4px;

    @media screen and ${desktopQuery} and (min-height: 1000px){
        font-size: 24px;
    }
`;

export const Body = styled.p`
    margin: 13px 0.99vw 0 13px;
    font-size: 10.66px;
    // font-weight: 300;
    line-height: 1.1em;

    @media screen and ${desktopQuery} and (min-height: 800px){
        font-size: 12px;
    }

    @media screen and ${desktopQuery} and (min-height: 900px){
        font-size: 15.3px;

    @media screen and ${desktopQuery} and (min-height: 1000px){
        font-size: 18px;
    }
`;

export const ArrowLinkWrapper = styled.div`
    position: absolute;
    bottom: 10px;
    // text-decoration:underline;
    // text-decoration-thickness:1px;
    right: 5px;
    &:hover {
        cursor: pointer;
    }

    @media screen and ${tabletQuery}{
        bottom: 0vw;
    }
`;