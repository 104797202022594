import React from 'react'
import Header from '../Header/Header'
import ArrowLink from '../ArrowLink/ArrowLink'
import { policies } from './policies'
import './policy.css'
import { Container, TwoPhones, ContentGrid, Title, ArrowLinkWrapper, ArrowLinkMobileWrapper,
         OuterWrapper, ParagraphOne, ParagraphTwo, BackgroundDiagonal } from './PolicyStyles'

const Policy = ({ policy }) => {
    const { title, paragraph1, paragraph2, arrowLinkText, navLink } = policies[policy]

    return (
        <OuterWrapper>
            <Container>
                <Header flippedColors={true} />
                <ContentGrid>
                    <BackgroundDiagonal />
                    <TwoPhones alt="back to back phones" />
                    <Title className="policy-title">{title}</Title>
                    <ParagraphOne>{paragraph1()}</ParagraphOne>
                    <ParagraphTwo>{paragraph2()}</ParagraphTwo>
                    <ArrowLinkWrapper><ArrowLink text={arrowLinkText} navLink={navLink} /></ArrowLinkWrapper>
                    <ArrowLinkMobileWrapper href={navLink}>{arrowLinkText}</ArrowLinkMobileWrapper>
                </ContentGrid>
            </Container>
        </OuterWrapper>
    )
}

export default Policy