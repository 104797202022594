exports.isEmptyString = (str) => {
   if (!str || str === "") {
      return true;
   }
   return false;
};

exports.isValidPhoneNumberFormat = (str) => {
   let regex = /^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/;

   if (regex.test(str)) {
      return true;
   }
   return false;
};

exports.isValidEmailAddress = (str) => {
   let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

   if (regex.test(str)) {
      return true;
   }
   return false;
};

