import React from 'react'
import { Container, Wrapper, TextWrapper, Title, Body, XOut } from './FullFeatureStyles'
import { featuresData } from './featuresData'

const FullFeature = ({ clickedFeature, setClickedFeature }) => {
    const renderFullFeature = () => {
        const { title, body, images, TextWrapperFullProps, imageFull } = featuresData[clickedFeature]

        return (
            <Container onClick={() => setClickedFeature(null)}>
                <Wrapper images={images} imageFull={imageFull}>
                    <XOut />
                    <TextWrapper TextWrapperFullProps={TextWrapperFullProps}>
                        <Title>{title}</Title>
                        <Body>{body()}</Body>
                    </TextWrapper>
                </Wrapper>
            </Container>
        )
    }
    
    return (
        clickedFeature ? renderFullFeature() : null
    )
}

export default FullFeature