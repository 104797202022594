import styled from 'styled-components';

const tabletQuery = "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const smallDesktopQuery = `${desktopQuery} and (max-height: 800px)`;
const mobileQuery = "(max-width: 491px)";
const smallMobileQuery = "(max-width: 320px)";

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryBlack = ({ theme }) => theme.primaryBlack;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const primaryGreen = ({ theme }) => theme.primaryGreen;

export const Container = styled.div`   
   max-width: 98vw;
   height: 50em;
   margin: 0px;   

   .grid-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-left: -34px;
   }

   @media screen and ${desktopQuery} {
      max-width: 87vw;
   }
   @media screen and ${mobileQuery} {
      height: 100em;
   }

   @media screen and ${smallMobileQuery} {
      height: 1250vw;
   }

   
`;

export const TitleContainer = styled.div`   
   max-width: 98vw;
   height: auto;
   margin: 0px auto;
   display:flex;
   flex-direction:column;
   justify-centent:center;
   align-items:center;

   @media screen and ${desktopQuery} {
      max-width: 87vw;
   }

   
`;

export const FilterContainer = styled.div`  
   text-align:center;
   @media screen and ${desktopQuery} {
      margin-bottom: 1em;
      width:800px;
   }

   @media screen and ${tabletQuery} {
      margin-bottom: 2.7em;
      width:100vw;
   }

   @media screen and ${mobileQuery} {
      width:95vw;
      margin-bottom: 1em;
      line-height: 2em;
      text-align: center;
      display:flex;
      justify-content:center;
   }
`;
export const ListContainer = styled.div`

`;

export const Filter = styled.button`
   background-color: transparent;
   border: none;
   outline: none;   
   position: relative;

   &:not(:last-child) {
      margin: 0 1%;
   }

   @media screen and ${mobileQuery} {
      &:not(:last-child) {
         margin-right: 0%;
      }
   }

   > input {
      width: 0;
      height: 0;
      visibility: hidden;

      &:checked {
         & + label {
            color: ${primaryPurple};
            font-weight: 700;
            text-decoration:underline;
            text-underline-offset: 5px;
            text-decoration-color: ${primaryPurple};
            text-decoration-thickness:4px;
         }
      }
   }

   > label {      
      padding: 5px;
      border-bottom: 2px solid transparent;     

      &:hover {
         color: ${primaryGreen};
         cursor: pointer;
      }

      @media screen and ${desktopQuery} {
         font-size: 1rem;
         font-family: 'Rubik', sans-serif;
         font-weight:700; 
      }

      @media screen and ${tabletQuery} {
         font-size: 1.35em;
         font-family: 'Rubik', sans-serif;
         font-weight:500; 
      }

      @media screen and ${mobileQuery} {
         font-size: 1.2em;
         font-family: 'Rubik', sans-serif;
         font-weight:500; 
         padding:0; 
         margin:0;
         width:20px;
      }
   }

   .superscript {      
      color: ${primaryWhite};
      background-color: ${primaryPurple};
      border-radius: 50%;      
      position: absolute;            

      @media screen and ${desktopQuery} {
         font-size: 0.77rem;
         width: calc(0.77rem + 4px);
         height: calc(0.77rem + 4px);
         line-height: calc(0.77rem + 4px);
         right: -14px;
         top: -10px;
         padding: 2px;  
      }

      @media screen and ${tabletQuery} {
         font-size: 0.864rem;
         width: calc(0.864rem + 4px);
         height: calc(0.864rem + 4px);
         line-height: calc(0.864rem + 4px);
         right: -14px;
         top: -10px;
         padding: 2px;
      }

      @media screen and ${mobileQuery} {
         font-size: 0.817rem;
         width: calc(0.817rem + 4px);
         height: calc(0.817rem + 4px);
         line-height: calc(0.817rem + 4px);
         right: -8px;
         top: -6px;
         padding: 2px;
      }
   }
`;

export const ItemWrapper = styled.div`
   &:last-child {
      margin-bottom: 50px;
   }

   .ux-inner-wrapper {
      position: relative;
   }

   .ux-text-wrapper {
      color: ${primaryBlack};      
      position: absolute;      
      left: 4%;
      width:inherit;
      bottom: 0;
   }

   .ux-title,
   .ux-body {    
      font-weight: normal;
   }

   .ux-body {
      font-weight: 500;
   }

   @media screen and ${desktopQuery} {      
      .ux-title,
      .ux-body {
         font-size: 1em;      
         margin-bottom: 0;   
      }      
   }

   @media screen and ${tabletQuery} {      
      .ux-title,
      .ux-body {
         font-size: 1.152em;         
      }      
   }

   @media screen and ${mobileQuery} {      
      .ux-title,
      .ux-body {
         font-size: 1.152em;         
      }      
      .ux-text-wrapper {
         text-align:center;
         left: 4.5%;
      }
   }

   @media screen and ${smallMobileQuery} {      
      .ux-title,
      .ux-body {
         font-size: 1.152em;         
      }      
   }   
`;