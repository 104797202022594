import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  XButton,
  TextContainer,
  NotificationContainer,
  NotificationText,
  NotificationLink,
  NotificationOverlay,
} from "./NotificationStyles.js";
import {
  showNotification,
  hideNotification,
} from "../../actions/cookieActions.js";
import { createCookie, readCookie } from "../../utils/utils.js";

const Notification = () => {
  const COOKIE_NAME = "MobUX_consent";
  const { showCookieNotification } = useSelector((state) => state.cookies);
  const dispatch = useDispatch();

  /**
   * @todo Error handling
   */
  const handleClick = () => {
    try {
      dispatch(hideNotification());
      createCookie(COOKIE_NAME, true);
    } catch (e) {
      console.log("error");
    }
  };

  /**
   * @todo Error handling
   */
  useEffect(() => {
    const existingCookie = readCookie(COOKIE_NAME);

    if (existingCookie === null) {
      try {
        dispatch(showNotification());
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <NotificationOverlay visibility={showCookieNotification ? "true" : "false"}>
      <NotificationContainer
        visibility={showCookieNotification ? "true" : "false"}
        className="slideUp"
      >
        <TextContainer>
          <NotificationText>
            This site uses cookies to provide you with a great user experience.
          </NotificationText>
          <NotificationText>
            By using MobUX, you accept our{" "}
            <NotificationLink to="/cookie-policy">
              use of cookies
            </NotificationLink>
          </NotificationText>
        </TextContainer>
        <XButton onClick={handleClick} />
      </NotificationContainer>
    </NotificationOverlay>
  );
};
export default Notification;
