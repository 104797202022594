import styled from 'styled-components'
import logo from '../../assets/images/logo-highest-res.png'
import logoWhite from '../../assets/images/logo-white-green-hires.png'
import { NavLink } from 'react-router-dom'

// const tabletQuery = '(max-width: 991px) and (min-height: 850px)'
// const tabletQuery = '(max-width: 768px)'

const tabletQuery = '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
const desktopQuery = '(min-width: 769px) and (orientation: landscape)'
const mobileQuery = '(max-width: 491px)'

export const Wrapper = styled.div`
    display: flex;
`;

export const LogoNavLink = styled(NavLink)`
    background-image: url(${ ({ props }) => props.flippedColors ? logoWhite : logo});
    background-size: contain;
    background-repeat: no-repeat;
    width: 8.538em;
    height: 2.308em;
    margin-top: 4.444vh;
    margin-left: 2.269em;
    margin-bottom: 1vh;

    @media screen and ${tabletQuery} {
        width: 8.064em;
        height: 2.189em; 
        margin-left: 1.671em;
        margin-top: 2.148vh;
        background-image: url(${logo});
    }

    @media screen and ${mobileQuery} {
        margin-top: 4.198vh;
        margin-left: 1.180em;
        width: 8.673em;
        height: 2.359em;
        background-image: url(${logo});
    }
`;