import styled from 'styled-components';
import twoPhones from '../../assets/images/two-phones.png';

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const primaryBlack = ({ theme }) => theme.primaryBlack;
const secondaryGrey = ({theme}) => theme.secondaryGrey;

// const tabletQuery = '(max-width: 768px) and (min-width: 491px)'
// const desktopQuery = '(min-width: 769px)'
// const mobileQuery = '(max-width: 491px)'

const tabletQuery = '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
const desktopQuery = '(min-width: 769px) and (orientation: landscape)'
const mobileQuery = '(max-width: 491px)'

const smallDesktopQuery = `${desktopQuery} and (max-height: 800px)`
const smallestDesktopQuery = `${desktopQuery} and (max-height: 700px)`

export const OuterWrapper = styled.div`
`;

export const Container = styled.div`
   @media screen and ${desktopQuery} {
        height: min-content;
        min-height: 120vh;
        background-color: ${primaryPurple};
        background-image: -webkit-linear-gradient(-29.5deg, ${primaryPurple} 30vw, ${primaryWhite} 30vw);
    }
   
   @media screen and ${tabletQuery}, ${mobileQuery} {
        height: min-content;
        background-image: -webkit-linear-gradient(90deg, ${primaryPurple} 92%, ${primaryWhite} 92%);
        background-color: ${primaryWhite};
   }
`;

export const ContentGrid = styled.div`
    display: grid;
    height: min-content;

    @media screen and ${desktopQuery} {
        grid-template-columns: 36% 64%;
        grid-template-rows: repeat(4, auto);
        width: 92vw;
        margin: 0 auto;
    }

    @media screen and ${tabletQuery} {
        grid-template-columns: 55% 45%;
        grid-template-rows: repeat(4, auto);
        margin-top: 6.912vh;
    }

    @media screen and ${mobileQuery}  {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, auto);
    }
`;

export const BackgroundDiagonal = styled.div` 
    background-color: ${primaryPurple};
    background: linear-gradient(-20deg, ${primaryPurple} 59%, ${primaryWhite} 39%);             
    @media screen and ${desktopQuery} {
        display: none;
    }

    @media screen and ${tabletQuery} {
        grid-area: 2 / 1 / 4 / 3;
    }

   @media screen and ${mobileQuery} {
        grid-area: 2 / 1 / 3 / 2;
    }
`;

export const TwoPhones = styled.div`
    background-image: url(${twoPhones});
    background-size: contain;
    background-repeat: no-repeat;
    
    @media screen and ${desktopQuery} {
        grid-area: 1 / 1 / 5 / 2;
        margin: 1.042vw 2vw 0 1vw;
        width: 27.36vw;
        height: 45.69vw;
    }

    @media screen and ${smallestDesktopQuery} {
        grid-area: 1 / 1 / 5 / 2;
        margin: 1.042vw 2vw 0 1vw;
        width: 25vw;
        height: 41.75vw;
    }

    @media screen and ${tabletQuery} {
        grid-area: 2 / 1 / 3 / 3;
        margin: 4.852vh auto 2vh auto;
        width: 51.408vw;
        height: 85.81vw;
    }

    @media screen and ${mobileQuery} {
        grid-area: 2 / 1 / 3 / 2;
        height: 130.05vw;
        width: 76.5vw;
        margin -5vh 0 0 10vw;
    }
`;

export const Title = styled.h1`
    font-size: 3.077em;
    color: ${primaryPurple};
    font-weight: 500;
    background-color: ${primaryWhite};

    @media screen and ${desktopQuery} {
        grid-area: 1 / 2 / 2 / 3;
        margin: 6.965vh 0 2.083vh;
    }

    @media screen and ${smallDesktopQuery}{
        font-size: 2.461em;
    }

    @media screen and ${smallestDesktopQuery} {
        margin: 3vh 0 2.083vh; 
    }

    @media screen and ${tabletQuery}{
        grid-area: 1 / 1 / 2 / 3;
        margin: 0 auto;
        font-size: 2.765em;
    }

    @media screen and ${mobileQuery}{
        grid-area: 1 / 1 / 2 / 2;
        margin: 3.202vh auto 6vh;
        font-size: 2.359em;
     }
`;

const Text = styled.div`
    @media screen and ${desktopQuery} {
        font-size: 1em;
        color: ${secondaryGrey};
    }

    @media screen and ${smallDesktopQuery} {
        font-size: 0.8em;
    }

    @media screen and ${smallestDesktopQuery} {
        font-size: max(14px, 0.75em);
    }

    @media screen and ${tabletQuery}{
        color: ${primaryWhite};
        font-size: 1.152em;
        padding: 0 4vw;
    }

    @media screen and ${tabletQuery} and (max-width: 600px) {
        font-size: 1em;
    }

     @media screen and ${mobileQuery}{
        color: ${primaryWhite};
        background-color: ${primaryPurple};
        font-size: 1.110em;
        padding: 0 4.533vw;
    }
`;

export const ParagraphOne = styled(Text)`  
    @media screen and ${desktopQuery} {
        grid-area: 2 / 2 / 3 / 3;
        padding-bottom: 1em;
    }

    @media screen and ${tabletQuery}{
        grid-area: 3 / 1 / 4 / 3;
        margin: 12vw auto 6vw;
        // margin-left: 33.5vw;
        // padding-right: 6.51vw;
        height: auto;
        font-size:20px
    }

    @media screen and ${mobileQuery}{
        background-color: ${primaryPurple};
        grid-area: 3 / 1 / 4 / 2;
        padding-bottom: 1em;
    }
`;

export const ParagraphTwo = styled(Text)`   


    @media screen and ${desktopQuery} {
        grid-area: 3 / 2 / 4 / 3;
    }

    @media screen and ${tabletQuery}{
        grid-area: 4 / 1 / 5 / 3;
        padding-bottom: 7.13vh;
        font-size:20px;

        .section-title{
            font-size:30px;
            display:block;
            text-align:center;
            margin-top:1em;
        }
    }
    
    @media screen and ${mobileQuery}{
        grid-area: 4 / 1 / 5 / 2;
        background-color: ${primaryPurple};
    }
`;

export const ArrowLinkWrapper = styled.div` 
    @media screen and ${desktopQuery} {
        grid-area: 4 / 2 / 5 / 3;
        margin: 1em 0 2em; 
    }

    @media screen and ${tabletQuery}, ${mobileQuery}{
        display: none;
    }
`;

export const ArrowLinkMobileWrapper = styled.a`
    @media screen and ${desktopQuery} {
        display: none;
    }
    width:260px;
    height:42px;
    background-color:white;
    color:${primaryPurple};
    font-weight:600;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:5px;

    @media screen and ${tabletQuery}{
        grid-area: 5 / 1 / 3 / 3;
        margin: 0vw auto 0 auto;
    }

    @media screen and ${mobileQuery}{
        grid-area: 5 / 1 / 6 / 2;
        margin: 3.6vh 6.47vw 5.7vh auto;
    }
`;
