import React from 'react';
import { Container, Wrapper, TextWrapper, TextHeading, TextMessage, LinkWrapper, ImageDiv } from "./NotFoundStyles";
import ArrowLink from '../ArrowLink/ArrowLink';
import Header from '../Header/Header';
import Notification from "../Notification/Notification";

const NotFound = ({ textHeading, textMessage, linkText, linkUrl }) => (
   <Container>
      <Header />
      <Wrapper>
         <TextWrapper>
            <TextHeading>{textHeading}</TextHeading>
            <TextMessage>{textMessage}</TextMessage>

            <LinkWrapper>
               <ArrowLink text={linkText} link={linkUrl} />
            </LinkWrapper>
         </TextWrapper>

         <ImageDiv />
      </Wrapper>
      {/* <Notification /> */}
   </Container>
);

NotFound.defaultProps = {
    textHeading: `Error \n 404`,
    textMessage: `Unfortunately, this page does not exist. \n But it's okay, we're still here for you!`,
    linkText: `Visit Our Home Page`,
    linkUrl: '/'
}

export default NotFound;
