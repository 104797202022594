import styled from "styled-components";
import PhoneImageLarge from "../../assets/images/frontphone-lg.png";
import PhoneImageMediumLarge from "../../assets/images/frontphone-medium-lg.png";
import PhoneImageSmall from "../../assets/images/frontphone-small.png";

const primaryPurple = ({ theme }) => theme.primaryPurple;
const secondaryGrey = ({ theme }) => theme.secondaryGrey;
const secondaryLightGrey = ({ theme }) => theme.secondaryLightGrey;
const primaryWhite = ({ theme }) => theme.primaryWhite;

const tabletQuery =
  "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const mobileQuery = "(max-width: 490px)";
const smallMobileQuery = "(max-width: 320px)";

export const PageWrap = styled.div`
  // height:100%;
  display:block;
  overflow:scroll
`;

export const IntroSection = styled.section`
  display: grid;
  height: min-content;
  min-height: 100vh;

  @media only screen and ${desktopQuery} {
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 42% 4% 54%;
    grid-template-columns: 41% 5% 54%;
    grid-template-areas:
      "head head head"
      "text . phon"
      "text . phon"
      "arro . phon";
    height: min-content;
    min-height: 100vh;
    background: linear-gradient(
      -60deg,
      #310854 1%,
      ${primaryPurple} 50.5%,
      ${primaryWhite} 50.5%
    );
  }

  @media only screen and ${tabletQuery} {
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: 53% 47%;
    grid-template-areas:
      "head head"
      "text phon"
      "text phon"
      "text phon";
  }

  @media only screen and ${mobileQuery} {
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: 50% 50%;
    grid-template-areas:
      "head head"
      "text text"
      ". phon";
  }
`;

export const BackgroundDiagonal = styled.div`
  background-image: linear-gradient(
    to right bottom,
    ${primaryWhite} 50%,
    ${primaryPurple} 50%
  );
  background-color: ${primaryPurple};

  @media screen and ${desktopQuery} {
    display: none;
  }

  @media screen and ${tabletQuery} {
    margin-top: 13vh;
    grid-area: 2 / 1 / 4 / 4;
  }

  @media screen and ${mobileQuery} {
    margin-top: 14vh;
    grid-area: 2 / 1 / 4 / 4;
    background-image: linear-gradient(
      -50deg,
      ${primaryPurple} 50%,
      ${primaryWhite} 50%
    );
  }

  @media screen and ${mobileQuery} and (max-width: 320px) {
    margin-top: 28vh;
  }

  @media screen and ${smallMobileQuery} {
    margin-top: 20vh;
  }
`;

export const InformationSection = styled.section`

  .learn-more-button{
    display:none
  }

  @media screen and ${mobileQuery}{
    .learn-more-button{
      display:block;
      width:160px;
      height:36px;
      background-color:${primaryPurple};
      border-radius:5px;
      color:white;
      font-size:12px;
      margin: 25px auto 0 auto;
      font-family: 'Rubik', sans-serif;
      font-weight: 500;
    }
    
  }

  @media screen and ${smallMobileQuery} {

  }

`;

export const UXListSection = styled.section`
  height: auto;
  padding-top: 0;
  display: flex;
  flex-direction:column;
  align-items:center;
  // position:sticky;
  // top:20px
`;

export const HeaderWrapper = styled.div`
  grid-area: head;
`;

export const Text = styled.div`
  grid-area: text;

  @media screen and ${desktopQuery} {
    margin: 25.7vh 0 0 4.115vw;
    height: min-content;

    .text {
      max-width: 731px;
    }
  }

  @media screen and ${tabletQuery} {
    margin: 8vh 0 0 3.776vw;
  }

  @media screen and ${mobileQuery} and (min-width: 321px) {
    margin: 4.7vh 0 0 5.06vw;

    .text {
      max-width: 300px;
    }

    .text:last-of-type {
      max-width: 265px;
    }

    .break-sentence {
      display: block;
    }
  }

  @media screen and ${smallMobileQuery} {
    margin: 4.7vh 0 0 5.06vw;

    .text {
      max-width: 90%;
    }

    .text:last-of-type {
      max-width: 72%;
    }

    .break-sentence {
      display: block;
    }
  }
`;

export const Heading = styled.div`
  grid-area: text;

  .secondary-color {
    color: black;
  }
  .desktop{
    display:initial
  }
  .mobile{
    display:none
  }

  .primary-color {
    color: ${primaryPurple};
  }

  h1 {
    font-weight: 500;
    letter-spacing: -0.8px;
    opacity: 1;
    line-height: 0.33em;
    margin: 0;
    font-size: 72px;
  }

  h2 {
    font-size: 2em;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    text-transform:capitalize
  }

  // Desktop Large
  @media only screen and (min-width: 1440px) {
    h1 {
      font-size: 72px;
    }
    h2 {
      font-size: 2em;
    }
  }

  @media only screen and ${tabletQuery} {
    h1 {
      font-size: 42px;
      line-height: 1.1em;
      letter-spacing: 0.03em;
    }
    h2 {
      font-size: 1.8em;
    }
  }

  @media only screen and ${mobileQuery} {

    .desktop{
      display:none
    }

    .mobile{
      display:initial
    }

    h1 {
      font-size: 32px;
      line-height: 1em;
      letter-spacing: 0em;
      margin-bottom: 2.7vh;
      width: 100%;
    }
    h2 {
      font-size: 24px;
      font-family: 'Rubik', sans-serif;
      font-weight: 500;
      margin-top:48px
    }
  }
`;

export const SubHeading = styled.div`
  margin-top: 0em;
  text-align: left;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.25;
  // font-size:28px;
  color: black;
  opacity: 1;
  p{
    // font-size:26px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
  }
  
  p:nth-child(2){
    margin-top 3.5vw
  }

  @media only screen and ${desktopQuery} {
    font-size:0.93em;
    h3{
      font-size:1.5em;
      font-family: 'Rubik', sans-serif;
      font-weight: 500;
      color:black;
    }
  }

  @media only screen and ${desktopQuery} and (min-width: 1440px) {
    font-size:24px;
    h3{
      font-size:32px;
      font-family: 'Rubik', sans-serif;
      color:black;
      width:900px
    }
  }

  @media only screen and ${tabletQuery} {
    font-size: 3.125vw;
    // line-height: 1.05em;
  }

  @media only screen and ${mobileQuery} {
    font-size: 1.11em;
    margin: 0.3em 0 0 0;
  }
`;

export const PhoneDiv = styled.div`
  grid-area: phon;

  @media only screen and ${desktopQuery} {
    width: 30vw;
    min-height: 50.625vw;
    height: 90vh;
    margin-top: -3vw;
    background: url(${PhoneImageLarge}) center center no-repeat;
    background-size: contain;
  }

  @media only screen and ${tabletQuery} {
    margin-top: 26vh;
    background: url(${PhoneImageMediumLarge}) left top no-repeat;
    background-size: contain;
    margin-bottom: 3vh;
  }

  @media only screen and ${mobileQuery} {
    margin-top: -9vh;
    background: url(${PhoneImageSmall}) center 2vh no-repeat;
    background-size: contain;
    z-index: 1;
    margin-left: -10vw;
  }
`;

export const HowWeDoText = styled.div`
  text-align: center;

  @media screen and ${desktopQuery} {
    margin: 15.5vh 10.23vw 0 10.63vw;
    height: min-content;

    .text {
      text-align: center;
      margin: 0 12vw 0 12vw;
      font-weight:300
    }
    .text.howwedo-text {
      margin-top: 2.2em;
    }
  }

  @media screen and ${tabletQuery} {
    margin: 5.04vh 4.818vw 0 4.818vw;

    .text {
      text-align: center;
      margin: 0;
    }
  }

  @media screen and ${mobileQuery} {
    margin: 3.696vh 7.74vw 0 7.74vw;

    .text.howwedo-text {
      margin: 0 0.5em 0 0.3em;
      text-align: center;
    }
  }
`;

export const Wrapper = styled.div`
  text-align: center;

  @media only screen and (min-width: 1440px) and (orientation: landscape) {
    width: 36%;
    margin: 1em 34vw 0 34vw;
  }

  @media only screen and ${desktopQuery} {
    margin: 1em auto 0;
  }

  @media only screen and ${tabletQuery} {
    margin: 0 auto 0 auto;
    width: 80%;
  }

  @media screen and ${mobileQuery} {
    margin: 0.487em 0 0 19.72vw;
    display:none;
    .arrowlink-text {
      width: 67%;
      text-align: left;
    }
  }

  @media screen and ${smallMobileQuery} {
    .arrowlink-text {
      font-size: 1em;
    }
    display:none
  }
`;

export const IconList = styled.div`
  .primary-color {
    color: ${primaryPurple};
  }
  p{
    text-align:center
  }

  .icon__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 12.5vh 0 0 0;
    display: flex;

    align-items: flex-start;
    justify-content: space-between;
    align-content: flex-start;
  }

  .circle {
    border-radius: 50%;
    background-color: ${primaryPurple};

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;
  }

  .icon__text {
    font-weight: 500;
    letter-spacing: -0.3px;
  }

  .icon__scan-line {
    border: solid 1.5px ${primaryPurple};
    position: absolute;
  }

  @media only screen and ${desktopQuery} {
    width: 65.36%;
    margin: 1.74em auto 11.176vh auto;

    .circle {
      width: 8.3vw;
      height: 8.3vw;
    }

    .icon__scan-line {
      border: solid 1.5px ${primaryPurple};
      width: 7.05vw;
      max-width: 136px;
      position: absolute;
    }

    .icon__text {
      font-size: 0.8em;
      margin: 1.4em 0 0;
    }

    .icon__list-item {
      max-width: 250px;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:space-between
    }

    .icon__money-text {
      width: 12.99vw;
    }

    .icon__grin-text {
      width: 12.97vw;
    }

    .icon__click-text {
      width: 10.835vw;
    }

    .icon__scan-text {
      width: 13.23vw;
    }

    .icon__money {
      width: 100%;
      height: 100%;
    }

    .icon__grin {
      // width: 3.5vw;
      // height: 3.5vw;
      width: 100%;
      height: 100%;
    }

    .icon__click {
      // width: 3.5vw;
      // height: 4vw;
      width: 100%;
      height: 100%;
    }

    .icon__scan {
      // width: 5.05vw;
      // height: 4.62vw;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and ${tabletQuery} {
    margin: 1.154em 3.256vw 5.04vh 3.786vw;

    .circle {
      width: 17.448vw;
      height: 17.448vw;
    }

    // .icon__money {
    //   width: 9.257vw;
    //   height: 5.684vw;
    // }

    // .icon__grin {
    //   width: 8.205vw;
    //   height: 8.205vw;
    // }

    // .icon__click {
    //   width: 6.286vw;
    //   height: 7.136vw;
    // }

    // .icon__scan {
    //   width: 9.132vw;
    //   height: 8.422vw;
    // }

    // .icon__scan-line {
    //   width: 12.05vw;
    //   max-width: 96.3px;
    // }

    .icon__text {
      font-size: 1em;
      line-height: 0.87em;
      margin: 0.668em 0 0;
      letter-spacing: -0.21px;
    }

    .icon__money-text {
      width: 19.279vw;
    }

    .icon__grin-text {
      width: 22.788vw;
    }

    .icon__click-text {
      width: 18.885vw;
    }

    .icon__scan-text {
      width: 14.976vw;
    }
  }

  @media screen and ${mobileQuery} {
    margin: 0em auto 3.696vh auto;
    width: 81%;

    .icon__text {
      font-size: 0.8em;
      letter-spacing: -0.1px;
      margin: .807em 0 0 0;
    }
    .icon__list {
      display:grid;
      grid-template-columns:1fr 1fr;
      justify-items:center;
      margin: 3.5vh 0 0 0;
    }

    .circle {
      width: 17.5vw;
      height: 17.5vw;
      margin:20px auto 0  auto
    }

    .icon__money-text {
      width: auto;
    }

    .icon__grin-text {
      width: auto;
    }

    .icon__click-text {
      width: auto;
    }

    .icon__scan-text {
      width: auto;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: 20px;
`;

export const ArrowLinkWrapper = styled.div`
  grid-area: arro;

  @media only screen and ${desktopQuery} {
    margin: 0em 0 0 4.115vw;
  }

  @media only screen and ${tabletQuery} {
    margin: 3em 0 0 3.776vw;
    width: 10em;
  }

  @media screen and ${mobileQuery} {
    // margin: 1.5em 0 30vh 5.06vw;
    display:none
  }
`;

export const DemosSection = styled.section``
