import styled from "styled-components";
import mapHi from '../../assets/images/map-hi-pin.png'

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const secondaryGrey = ({ theme }) => theme.secondaryGrey;
const primaryBlack = ({ theme }) => theme.primaryBlack;

const tabletQuery = '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
const desktopQuery = '(min-width: 769px) and (orientation: landscape)'
const mobileQuery = '(max-width: 491px)'
const smallDesktopQuery = `${desktopQuery} and (max-height: 800px)`

export const Container = styled.div`
   display: grid;
   
   @media only screen and ${desktopQuery} {
      grid-template-rows: auto auto 1fr;
      grid-template-columns: 60% 40%;
      grid-template-areas: "head head"
                           "text form"
                           "mapp form";
      height: min-content;
      min-height: 100vh;
      background: linear-gradient(-60deg, #310854 1%, ${primaryPurple} 50.5%, ${primaryWhite} 50.5%);             
   }

    @media only screen and ${tabletQuery}{
        background:  -webkit-linear-gradient(90deg, ${primaryPurple} 50%, ${primaryWhite} 50%);
        grid-template-rows: repeat(4, auto);
        grid-template-columns: 50% 50%;
        grid-template-areas: "head head"
                             "text text"
                             "mapp mapp"
                             "form form";
    }

    @media only screen and ${mobileQuery}{
        background:  -webkit-linear-gradient(90deg, ${primaryPurple} 70%, ${primaryWhite} 70%);
        grid-template-rows: auto auto auto 3vh auto;
        grid-template-columns: 50 50%;
        grid-template-areas: "head head"
                             "text text"
                             "mapp mapp"
                             "mapp mapp"
                             "form form";
    }
`;

export const BackgroundDiagonal = styled.div` 
background: linear-gradient(-20deg, ${primaryPurple} 50.5%, ${primaryWhite} 50.5%);             
    
    @media screen and ${desktopQuery} {
            display: none;
    }

    @media screen and ${tabletQuery} {
        margin-top: 12vw;
        grid-area: 2 / 1 / 4 / 4;
    }

    @media screen and ${mobileQuery} {
        margin-top: 35vw;
        grid-area: 2 / 1 / 4 / 3;
    }

    @media screen and ${mobileQuery} and (max-width: 360px) {
        
    }
`;

export const PurpleSpace = styled.div`
    @media screen and ${desktopQuery}, ${tabletQuery}{
        display: none;
    }

    @media screen and ${mobileQuery}{
        display: block;
        grid-area: 4 / 1 / 5 / 3;
    }
`;

export const HeaderWrapper = styled.div`
    grid-area: head;
`;

export const Text = styled.div` 
    grid-area: text;
    
    
    @media screen and ${desktopQuery}{
        margin: 6.296vh 0 0 4.115vw;
        height: min-content;
    }

    @media screen and ${desktopQuery} and (min-width: 1100px) and (max-height: 800px){
        margin-top: 20px;
    }

    @media screen and ${tabletQuery}{
        margin: 4.25vh 0 0 3.776vw;
        margin: 5.72vw 0 0 3.886vw;
        text-align:center
    }

    @media screen and ${mobileQuery}{
        margin: 5.5vw 0 0 8vw;
        width: 84.267vw;
        text-align:left
    }
`;

export const Heading = styled.div`
   color: ${primaryPurple};
   font-weight: 500;

    @media only screen and ${desktopQuery}{
        font-size: 3.077em;
    }

    @media only screen and ${smallDesktopQuery}{
        font-size: 2.266em;
    }

    @media only screen and ${tabletQuery}{
        font-size: 2.765em;
    }

    @media only screen and ${mobileQuery} {
        font-size: 2.25em;
        margin-bottom: 0.2em;
        text-align:center
    }
`;

export const SubHeading = styled.div`
   font-weight: 400;
   color: ${secondaryGrey};
       
    .purple-address {
        color: ${primaryPurple};
        font-weight: 500;
        margin-top: 1em;
    }

    @media only screen and ${desktopQuery}{
        font-size: 1em;   
        margin-right: 6em;
        width: 40.89vw;
        
    }

    @media only screen and ${smallDesktopQuery}{
        width: 40.89vw;
        font-size: 0.85em;
    }

    @media only screen and ${tabletQuery} { 
        width:85%;
        margin:2em auto 0 auto;
        font-size: 2.7vw;   
        // line-height: 1.0;  
        .purple-address {
            margin-top: 1.2em;
        }

        .sub-coffee::before {
            {
                content: " ";
                display: inline;
            }
        }

        .sub-touch::before {
            {
                content: " ";
                display: inline;
            }
        }
    }   

    @media only screen and ${mobileQuery} {     
        margin-top:1.2em;
        font-size: 1.11em;     
        
        .purple-address {
            margin-top: 1.2em;
        }

        @media screen and ${mobileQuery} and (max-width: 360px) {
            .sub-newyork::before {
                {
                    content: " ";
                    display: block;
                }
            }
        }
    }
`;


export const Map = styled.a`
    grid-area: mapp;
    background-image: url(${mapHi}); 
    background-repeat: no-repeat;
    border-radius:10px;

    @media only screen and ${desktopQuery}{
        margin: 2.7vh 0 0 4.115vw;
        width: 40.89vw;
        height: 24.60vw;
        margin-bottom: 2vw;
        background-size: 220%;
        background-position: 50% 80%;
    }

    @media only screen and ${tabletQuery}{
        margin: 1.3em 0 12vh 3.776vw;
        margin: 1.3em 0 16.15vw 3.776vw;
        width: 92.44vw;
        height: 50.11vw;
        background-size: 220%;
        background-position: 60% 85%;
    }

    @media screen and ${mobileQuery}{
        margin: 1.3em 0 0 8vw;
        width: 84vw;
        height: 55vw;
        background-size: 300%;
        background-position: 55% 100%;
    }
`;

export const FormSection = styled.section`
    grid-area: form;

    @media only screen and ${desktopQuery}{
        font-size: 0.9em;   
        width: 26vw;   
        margin-top: 14.8vh;
        margin-left: 3vw;
    }

    @media screen and ${desktopQuery} and (min-width: 1100px) and (max-height: 800px){
        margin-top: 60px;
    }

    @media screen and ${desktopQuery} and (max-width: 1100px) and (max-width: 1110px){
        margin-top: 15vh;
        margin-left: 4vw;
    }

    @media only screen and ${tabletQuery} { 
        margin-top: -5vh;
        margin-top: -6.73vw;
        padding: 0 15vw 12vh 15vw;
        padding: 0 10vw 16.15vw 10.94vw;
    }

    @media only screen and ${mobileQuery} {  
        margin-top: 5vh;
        font-size: 1.15em;
        padding: 0 8vw 5vh 8vw;

    }
`;






