import React from "react"
import nou from "../../assets/images/clients-nou.png";
import gradient from "../../assets/images/gradient/Gradient.png";
import housebeer from "../../assets/images/house-beer/House_Beer.png";
import zvook from "../../assets/images/clients-zvook.png";
import wildfor from "../../assets/images/wild-for/Wild_For.png";
import frome from "../../assets/images/frome/Frome.png";
import willas from "../../assets/images/willas/Willas_Kitchen.png";
import buddyInsurance from "../../assets/images/buddy-insurance/clients-basil.png";
import dassInc from "../../assets/images/dass-inc/Dass.png";
import basil from "../../assets/images/clients-basil.png";
import heineken from "../../assets/images/clients-heineken.png";
import shiseido from "../../assets/images/clients-shiseido.png";
import suntory from "../../assets/images/clients-suntory.png";
import velo from "../../assets/images/clients-velo.png";

// import heineken1 from "../../assets/images/heineken/1.png";
// import heineken2 from "../../assets/images/heineken/2.png";
// import heineken3 from "../../assets/images/heineken/3.png";
// import heineken4 from "../../assets/images/heineken/4.png";
// import heineken5 from "../../assets/images/heineken/5.png";
// import heineken6 from "../../assets/images/heineken/6.png";
// import heineken7 from "../../assets/images/heineken/7.png";
// import heineken8 from "../../assets/images/heineken/8.png";

import nouProject1 from "../../assets/images/clients/optimized/TheNouProject/Nou_size_correction_optimized.png";
import nouProject2 from "../../assets/images/clients/optimized/TheNouProject/Nou_size_correction_optimized2.png";
import nouProject3 from "../../assets/images/clients//optimized/TheNouProject/Nou_size_correction_optimized3.png"
// import nouProject5 from "../../assets/images/clients/The-Nou-Project/1_Nou_Project-5.png";
// import nouProject6 from "../../assets/images/clients/The-Nou-Project/1_Nou_Project-6.png";

// Gradient
import gradientProject1 from "../../assets/images/clients/optimized/Gradient/Gradient_Client_Page_(optimized).png";
import gradientProject2 from "../../assets/images/clients/optimized/Gradient/Gradient_Client_Page_(optimized)2.png";
import gradientProject3 from "../../assets/images/clients/optimized/Gradient/Gradient_Client_Page_(optimized)3.png";
import gradientProject4 from "../../assets/images/clients/optimized/Gradient/Gradient_Client_Page_(optimized)4.png";


// Housebeer
import houseBeerProject1 from "../../assets/images/clients/optimized/HouseBeer/House_Beer_client_page.png";
import houseBeerProject2 from "../../assets/images/clients/optimized/HouseBeer/House_Beer_client_page2.png";
import houseBeerProject3 from "../../assets/images/clients/optimized/HouseBeer/House_Beer_client_page3.png";


// Zvook
import zvookProject1 from "../../assets/images/clients/optimized/Zvook/Zvook_Client_Page_(optimized).png";
import zvookProject2 from "../../assets/images/clients/optimized/Zvook/Zvook_Client_Page_(optimized)2.png";
import zvookProject3 from "../../assets/images/clients/optimized/Zvook/Zvook_Client_Page_(optimized)3.png";

// Wild-For
import wildforProject1 from "../../assets/images/clients/optimized/WildFor/Wild_For_Client_Page_(optimized).png";
import wildforProject2 from "../../assets/images/clients/optimized/WildFor/Wild_For_Client_Page_(optimized)2.png";
import wildforProject3 from "../../assets/images/clients/optimized/WildFor/Wild_For_Client_Page_(optimized)3.png";
import wildforProject4 from "../../assets/images/clients/optimized/WildFor/Wild_For_Client_Page_(optimized)4.png";

// frome
import fromeProject1 from "../../assets/images/clients/optimized/Frome/Frome_client_page_(optimized).png";
// import fromeProject2 from "../../assets/images/clients/FROME/Frome_client_page2.png";
// import fromeProject3 from "../../assets/images/clients/FROME/Frome_client_page3.png";
// import fromeProject4 from "../../assets/images/clients/FROME/Frome_client_page4.png";
// import fromeProject5 from "../../assets/images/clients/FROME/Frome_client_page5.png";

// willas
// import willasProject1 from "../../assets/images/clients/FROME/Frome_client_page.png";
// import willasProject2 from "../../assets/images/clients/FROME/Frome_client_page2.png";
// import willasProject3 from "../../assets/images/clients/FROME/Frome_client_page3.png";
// import willasProject4 from "../../assets/images/clients/FROME/Frome_client_page4.png";
// import willasProject5 from "../../assets/images/clients/FROME/Frome_client_page5.png";

// buddy Insurance
import buddyInsuranceProject1 from "../../assets/images/clients/optimized/BuddyInsurance/Buddy_client_page_(optimized).png";
import buddyInsuranceProject2 from "../../assets/images/clients/optimized/BuddyInsurance/Buddy_client_page_(optimized)2.png";
import buddyInsuranceProject3 from "../../assets/images/clients/optimized/BuddyInsurance/Buddy_client_page_(optimized)3.png";


// dassInc
import dassIncProject1 from "../../assets/images/clients/optimized/Dass/Dass_Client_Page.png";
import dassIncProject2 from "../../assets/images/clients/optimized/Dass/Dass_Client_Page2.png";
import dassIncProject3 from "../../assets/images/clients/optimized/Dass/Dass_Client_Page3.png";

// Willas
import willasProject1 from "../../assets/images/clients/optimized/Willas/Willas_client_page_(optimized).png";
import willasProject2 from "../../assets/images/clients/optimized/Willas/Willas_client_page_(optimized)2.png";
import willasProject3 from "../../assets/images/clients/optimized/Willas/Willas_client_page_(optimized)3.png";




export const clientOrder = [
  "nou",
  "gradient",
  "houseBeer",
  "zvook",
  "wildFor",
  "frome",
  "willas",
  "buddyInsurance",
  "dassInc"
];

export const clientData = {
  nou: {
    logo: {
      image: `${nou}`,
      backgroundSize: "90%",
      backgroundPosition: "50% 49%",
      backgroundSizeMobile: "60%",
    },
    hoverData: {
      title: "The Nou Project",
      body:
        [<b>The Nou Project </b>, "activated to stand out on shoppable Instagram by delivering a custom brand experience elevated above the standard product list, whilst still focusing on driving sales."],
      backgroundColor: "rgb(66,64,59)",
    },
    pics: [nouProject1, nouProject2, nouProject3],
    description: "Shoppable Instagram looks the same for everyone. Creating an experience that seamlessly works with Instagram, delivers fully custom content, while not complicating or adding to the customer journey is what we’ve thought up when we came up with this solution."
  },
  zvook: {
    logo: {
      image: `${zvook}`,
      backgroundSize: "90%",
      backgroundPosition: "50% 49%",
      backgroundSizeMobile: "60%",
    },
    hoverData: {
      title: "Zvook",
      body:
        [<b>Zvook </b>, " needed support in seamlessly transitioning listeners from the podcast audio storytelling experience to a visual one. Using short code, the activation was built for mobile while providing detailed analytics."],
      backgroundColor: "rgb(232,111,113)",
    },
    pics: [
      zvookProject1,
      zvookProject2,
      zvookProject3,
    ],
    description: "Podcasts are a high-frequency communication channel, whereby users respond to hyper custom ads down to the podcast episode and show. Creating multiple landing experiences at scale, with speed, while collecting data is what we’ve solved. Create one for all your podcasts or one for each ad."

  },
  basil: {
    logo: {
      image: `${basil}`,
      backgroundSize: "90%",
      backgroundPosition: "50% 49%",
      backgroundSizeMobile: "60%",
    },
    hoverData: {
      title: "Basil",
      body: "this is what we did for them",
      backgroundColor: "rgb(214,122,69)",
    },
    pics: [
      // heineken1,
      // heineken2,
      // heineken3,
      // heineken4,
      // heineken5,
      // heineken6,
      // heineken7,
      // heineken8,
    ],
    description: "Podcasts are a high-frequency communication channel, whereby users respond to hyper custom ads down to the podcast episode and show. Creating multiple landing experiences at scale, with speed, while collecting data is what we’ve solved. Create one for all your podcasts or one for each ad."

  },
  heineken: {
    logo: {
      image: `${heineken}`,
      backgroundSize: "90%",
      backgroundPosition: "50% 42%",
    },
    hoverData: {
      title: "Heineken",
      body:
        "Activation of Heineken at F1 events detailing for attendees the various events at the event and how they can book a place and attend it from pre-event to post-event",
      backgroundColor: "#255427",
    },
    pics: [
      // heineken1,
      // heineken2,
      // heineken3,
      // heineken4,
      // heineken5,
      // heineken6,
      // heineken7,
      // heineken8,
    ],
    description: "Podcasts are a high-frequency communication channel, whereby users respond to hyper custom ads down to the podcast episode and show. Creating multiple landing experiences at scale, with speed, while collecting data is what we’ve solved. Create one for all your podcasts or one for each ad."

  },
  shiseido: {
    logo: {
      image: `${shiseido}`,
      backgroundSize: "90%",
      backgroundPosition: "50% 47%",
    },
    hoverData: {
      title: "Shiseido",
      body: "this is what we did for them",
      backgroundColor: "rgb(198,22,51)",
    },
    pics: [
      // heineken1,
      // heineken2,
      // heineken3,
      // heineken4,
      // heineken5,
      // heineken6,
      // heineken7,
      // heineken8,
    ],
    description: "Podcasts are a high-frequency communication channel, whereby users respond to hyper custom ads down to the podcast episode and show. Creating multiple landing experiences at scale, with speed, while collecting data is what we’ve solved. Create one for all your podcasts or one for each ad."

  },
  suntory: {
    logo: {
      image: `${suntory}`,
      backgroundSize: "90%",
      backgroundPosition: "50%",
    },
    hoverData: {
      title: "Suntory",
      body: "this is what we did for them",
      backgroundColor: "rgb(96,194,219)",
    },
    pics: [
      // heineken1,
      // heineken2,
      // heineken3,
      // heineken4,
      // heineken5,
      // heineken6,
      // heineken7,
      // heineken8,
    ],
    description: "Podcasts are a high-frequency communication channel, whereby users respond to hyper custom ads down to the podcast episode and show. Creating multiple landing experiences at scale, with speed, while collecting data is what we’ve solved. Create one for all your podcasts or one for each ad."

  },
  velo: {
    logo: {
      image: `${velo}`,
      backgroundSize: "60%",
      backgroundPosition: "50% 48%",
    },
    hoverData: {
      title: "Velo",
      body: "this is what we did for them",
      backgroundColor: "rgb(26,157,168)",
    },
    pics: [
      // heineken1,
      // heineken2,
      // heineken3,
      // heineken4,
      // heineken5,
      // heineken6,
      // heineken7,
      // heineken8,
    ],
    description: "Podcasts are a high-frequency communication channel, whereby users respond to hyper custom ads down to the podcast episode and show. Creating multiple landing experiences at scale, with speed, while collecting data is what we’ve solved. Create one for all your podcasts or one for each ad."

  },

  gradient: {
    logo: {
      image: `${gradient}`,
      backgroundSize: "60%",
      backgroundPosition: "50% 48%",
    },
    hoverData: {
      title: "Gradient",
      body: [<b>Gradient</b>, " creates amazing events and wanted a digital companion to promote and activate at events to ensure attendees get the most out of their experience with relevant info and ways to continue the conversation with the brand afterwards."],
      backgroundColor: "rgb(0,0,0)",
    },
    pics: [
      gradientProject1,
      gradientProject2,
      gradientProject3,
      gradientProject4,
    ],
    description: "Amplify the human connection via technology at in-person and virtual events. Provide digital touchpoints for attendees to have interactive experiences that supercharge the event and collect helpful data. Use that data to continue a personalized conversation and develop a brand-customer relationship based on understanding what they need."

  },
  houseBeer: {
    logo: {
      image: `${housebeer}`,
      backgroundSize: "60%",
      backgroundPosition: "50% 48%",
    },
    hoverData: {
      title: "House Beer",
      body: [<b>House Beer</b>, " launched a new product during Covid. With restaurants and bars closed for customers to trial the product, they decided they needed a mobile-first product launch, with virtual tastings, and easy-to order online or store locator."],
      backgroundColor: "#B1005D",
    },
    pics: [
      houseBeerProject1,
      houseBeerProject2,
      houseBeerProject3,
    ],
    description: "Mobile first launches are becoming increasingly more critical for brands. Many brands rely on events to create awareness and trial. While a valuable component to an omni-channel approach, events are costly, not easily scalable, and not possible during a pandemic. Brands, especially those that rely on events, need to be able to diversify their launches. Mobile caters to the core group of consumers, allowing them to connect with your brand anywhere. We helped House Beer become more mobile friendly, more nimble, and more contactless."
  },
  wildFor: {
    logo: {
      image: `${wildfor}`,
      backgroundSize: "60%",
      backgroundPosition: "50% 48%",
    },
    hoverData: {
      title: "Wild For",
      body: [<b>Wild For’s</b>, " website was lacking so they wanted something modern and flexible that provided “buy now” capabilities. They sent traffic away from their low-tech site to a high-impact interactive experience that told their story in the format they wanted."],
      backgroundColor: "#F97501",
    },
    pics: [
      wildforProject1,
      wildforProject2,
      wildforProject3,
      wildforProject4,
    ],
    description: "Storytelling is a powerful way for brands to guide customers through the user journey. Websites are brand-to-mass tools with very little guidance. Instead of spending a fortune in time and money on building out all the details. We created a mobile microsite that is experiential with a guided funnel. We did it in less than a day with built-in analytics."

  },

  frome: {
    logo: {
      image: `${frome}`,
      backgroundSize: "60%",
      backgroundPosition: "50% 48%",
    },
    hoverData: {
      title: "Frome",
      body: ["Downloading an app can be a hurdle, which is why ", <b>Frome</b>, " created an experience to make sure people know the value of the app before determining the need to download it."],
      backgroundColor: "#B1B1B1",
    },
    pics: [
      fromeProject1,
    ],
    description: "Downloading an app is a big commitment. With the average person downloading zero each month, it has become increasingly hard for apps to succeed. Highlighting the benefits of an app reduces the feeling of a big commitment and increases app awareness and downloads. Through interaction and high-impact imagery, mobUX mimics the user-experience of an app to market them on other channels beyond the Google & Apple stores, while driving traffic there for the final conversion."

  },

  willas: {
    logo: {
      image: `${willas}`,
      backgroundSize: "60%",
      backgroundPosition: "50% 48%",
    },
    hoverData: {
      title: "Willa's Kitchen",
      body: [<b>Willa’s Kitchen</b>, " wanted to make it easy for customers to understand their brand and products without sacrificing their story. Big visuals, clear headlines, & loud CTAs did the trick."],
      backgroundColor: "#6DBAC4",
    },
    pics: [
      willasProject1,
      willasProject2,
      willasProject3
    ],
    description: "With so many products out there, brand discovery and path to purchase requires an omni-channel approach with digestible content. For new brands, being able to launch quickly across multiple channels, together with knowing who their customers are and what they are doing, is critical. Sharing detailed information in easy-to-consume chunks and fitting seamlessly into their omni-channel marketing funnel is equally important. Building a single experience that launches simultaneously and runs across all media channels, while saving time and money, is mobUX's core competency."

  },

  buddyInsurance: {
    logo: {
      image: `${buddyInsurance}`,
      backgroundSize: "60%",
      backgroundPosition: "50% 48%",
    },
    hoverData: {
      title: "Buddy Insurance",
      body: [<b>Buddy Insurance</b>, " activated a mobile-first experience, offering access to a quick quote and checkout process in the right format: a mobile format. On-demand insurance means easy-activation on-the-go."],
      backgroundColor: "#CC5E4F",
    },
    pics: [
      buddyInsuranceProject1,
      buddyInsuranceProject2,
      buddyInsuranceProject3
    ],
    description: "Using interactive content not only helps qualify leads, but it also adds value. It makes the first step in a buyer's journey memorable, it answers their most pressing questions, and it generates greater engagement through virality. We put interactive content inside interactive experiences on mobile to ensure the greatest level of access and ease of use."

  },

  dassInc: {
    logo: {
      image: `${dassInc}`,
      backgroundSize: "60%",
      backgroundPosition: "50% 48%",
    },
    hoverData: {
      title: "Dass Inc.",
      body: [<b>Dass</b>, " needed a partner to support them with their shopper marketing needs: versatile high-impact experiences that convert shoppers into buyers. The 360° touch point campaign allows for activation ranging from on-pack to in-store."],
      backgroundColor: "#38568D",
    },
    pics: [
      dassIncProject1,
      dassIncProject2,
      dassIncProject3,
    ],
    description: "Data collection at offline channels has always been difficult. Using existing QR Code & shortcode infrastructure, we’re able to create custom experiences that seamlessly bring people online, collect data, and allow for future retargeting. Convert your shoppers into buyers with on-premise touchpoints."

  },
  
};
