import React, { useState, useEffect, useRef } from 'react';
import { ButtonContainer , Text, ButtonTab, TripleSpan} from './ButtonStyles';

const Button = ({text, handleSubmit }) => {
  const [touchStart, setTouchStart] = useState(null)
  const [triggered, setTriggered] = useState(false)
  const [touchscreen, setTouchscreen] = useState(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    const usingTouchscreen = !!('ontouchstart' in window) || !!('msmaxtouchpoints' in window.navigator)
    setTouchscreen(usingTouchscreen)
  }, [])

  useEffect(() => {
    if (triggered){
      setTimeout(() => {
        handleSubmit()
      }, 300)
      setTimeout(() => {
        setTouchStart(false)
        setTriggered(false)
        buttonRef.current.style.marginLeft = ""
      }, 500)
    }
  }, [triggered])

  const handleTouchStart = e => setTouchStart(e.touches[0].clientX)

  const handleTouchMove = e => {
    if (!triggered){
      const displacement = e.touches[0].clientX - touchStart
       if (displacement > 0 && displacement <= 150) {
           buttonRef.current.style.marginLeft = `${displacement}px`
       } else if (displacement > 150) {
          setTriggered(true)
       } else {
         buttonRef.current.style.marginLeft = ""
       }
    }
  }

  const handleTouchEnd = e => {
          setTouchStart(null)
          if (!triggered) buttonRef.current.style.marginLeft = ""
        }

  return  (
    <ButtonContainer onClick={e => e.preventDefault()}>
      <ButtonTab 
        ref={buttonRef}
        className={`button-tab ${touchStart && !triggered
          ? 'dragging' 
          : triggered 
            ? 'triggered'
            : 'not-dragging'}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={e => {
          e.preventDefault()
          if (!touchscreen) setTriggered(true)
        }}
      >
        <TripleSpan>{'>>>'}</TripleSpan>
      </ButtonTab>
      <Text>
        {touchscreen ? 'Swipe to send' : "Click to send"}
      </Text>
    </ButtonContainer>
  )
}

export default Button;

Button.defaultProps = {
  text: "Click to send",
  handleSubmit: () => {}
}