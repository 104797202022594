import React, { useState } from "react";

const useModal = () => {
   const [isShowing, setIsShowing] = useState(false);
   const [page, setPage] = useState("Page")
   const [modalContent, setContent] = useState("Modal content");

   const handleModal = (content = false, page = null) => {
      setIsShowing(!isShowing);
      if (content) {
         setContent(content);
      }
      if (page) {
         setPage(page)
      }
   };

   return { isShowing, handleModal, modalContent, page };
};

export default useModal;
