import styled from "styled-components";
import xButton from "../../assets/images/x-button.png";
import { Link } from "react-router-dom";

const white = ({ theme }) => theme.primaryWhite;
const primaryPurple = ({ theme }) => theme.primaryPurple;
const darkViolet = ({ theme }) => theme.darkViolet;
const primaryDarkPurple = ({ theme }) => theme.primaryDarkPurple;

export const NotificationContainer = styled.div`
  position: sticky;
  bottom: 2px;
  background-image: linear-gradient(
    91deg,
    ${primaryPurple} 50%,
    ${darkViolet} 292%,
    ${primaryDarkPurple} 295%
  );
  min-height: 96px;
  width: 100%;

  display: ${(props) => (props.visibility === "true" ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 426px) and (max-width: 768px) {
    background-image: linear-gradient(
      93deg,
      ${primaryPurple} 50%,
      ${darkViolet} 292%,
      ${primaryDarkPurple} 294%
    );
  }

  @media screen and (min-width: 375px) and (max-width: 425px) {
    background-image: linear-gradient(
      115deg,
      ${primaryPurple} 34%,
      ${darkViolet} 110%,
      ${primaryDarkPurple} 238%
    );
    width: 92%;
    height: 68.5%;
    min-height: 341px;
    max-height: 341px;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;
  }

  @media screen and (max-width: 374px) {
    background-image: linear-gradient(
      115deg,
      ${primaryPurple} 34%,
      ${darkViolet} 110%,
      ${primaryDarkPurple} 238%
    );
    width: 92%;
    height: 68.5%;
    min-height: 341px;
    max-height: 380px;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;
  }

  .slideUp {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 96px;
  }
`;

export const NotificationText = styled.p`
  color: ${white};
  font-size: calc(20px + (24 - 20) * ((100vw - 768px) / (1920 - 768)));
  font-family: Rubik;
  margin: 0;

  @media screen and (min-width: 426px) and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 425px) {
    font-size: 24px;
    margin: 0;
    text-align: center;
    font-weight: 400;
  }
`;

export const NotificationLink = styled(Link)`
  color: ${white};
  text-decoration: underline;
  font-weight: 500;
`;

export const XButton = styled.div`
  background-image: url(${xButton});
  background-repeat: no-repeat;
  margin-right: 3.15%;
  background-size: 28px 28px;
  height: 28px;
  width: 28px;

  .close {
    transform: translateX(-96px);
  }

  @media screen and (min-width: 426px) and (max-width: 991px) {
    margin-right: 30px;
  }

  @media screen and (max-width: 425px) {
    margin: auto auto 35px;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
  }
`;

export const NotificationOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: column-reverse;
  visibility: ${(props) =>
    props.visibility === "true" ? "visible" : "hidden"};
  opacity: ${(props) => (props.visibility === "true" ? 1 : 0)};
  display: ${(props) => (props.visibility === "true" ? "flex" : "none")};

  transition: opacity 0.3s linear 3s;
`;

export const TextContainer = styled.div`
  width: 65%;
  width: 80%;
  margin: 0 0 0 4.15%;

  @media screen and (min-width: 426px) and (max-width: 1024px) {
    width: 90%;
    margin: 2.25% 0 2.25% 4.15%;
  }

  @media screen and (max-width: 425px) {
    margin: 30% 0 0;
    width: 87%;
  }
`;
