import React from "react";
import { Content } from "./SentNotificationStyles";
import { ModalContext } from "../Modal/modalContext";
import logoIcon from "../../assets/images/mobUX_White_Green.png";

const SentNotification = ({ type, page = null }) => {
  let { modelContent } = React.useContext(ModalContext);

  const setIcon = () => {
    switch (type) {
      case "sent-error":
        return (
          <svg viewBox="0 0 40 40">
            <path d="M 10,10 L 30,30 M 30,10 L 10,30" strokeWidth="2" />
          </svg>
        );
      case "sent-success":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.019 244.757">
            <g transform="translate(-11247.584 3751.976) rotate(-30)">
              <path
                d="M11568.78,2467.016l39.638,78.457-39.638,78.457,198.606-78.457Z"
                strokeLinejoin="round"
                strokeWidth="7"
              />
              <path
                d="M11794.721,2545.473H11645.7"
                transform="translate(-35.998)"
                strokeWidth="10"
              />
            </g>
          </svg>
        );
      case "loading":
        return <img width="100%" src={logoIcon} alt="mobUX logo" />;
      default:
        return null;
    }
  };
  const setMessage = () => {
    switch (type) {
      case "sent-error":
        return page === "contact-us" ? (
          <div className="message">
            Unfortunately, there's been an{" "}
            <span className="highlight error">
              error in sending the message.
            </span>
            <br />
            Please try again shortly.
          </div>
        ) : (
          <div className="message">
            Unfortunately, there's been an{" "}
            <span className="highlight error">error in sharing the demo.</span>
            <br />
            Please try again shortly.
          </div>
        );
      case "sent-success":
        return page === "contact-us" ? (
          <div className="message">
            Your message's been <span className="highlight success">sent!</span>
            <br />
            Thanks for reaching out! We'll be in touch with you shortly.
          </div>
        ) : (
          <div className="message">
            The demo has been <span className="highlight success">sent!</span>
            <br />
            We hope you enjoy it, do contact us for more information.
          </div>
        );
      case "loading":
        return <div className="message">Please wait as we are loading...</div>;
      default:
        return null;
    }
  };

  modelContent = (
    <Content>
      <div className="icon">{setIcon()}</div>
      {setMessage()}
    </Content>
  );

  return modelContent;
};

export default SentNotification;
