import React, { useState } from "react";
import { OuterWrapper, Wrapper, TextWrapper, Title, Body } from "./ExperienceItemStyles";

const ExperienceItem = ({
   feature,
   featuresData,
   clickedFeature,
   setClickedFeature,
   isHovering,
   wrapperClassName,
   contentClassName,
   textWrapperClassName,
   titleClassName,
   descriptionClassName,
}) => {
   const [hovering, setHovering] = useState(isHovering);
   const { title, body, link, images } = featuresData[feature];
   const selected = clickedFeature === feature;

   return (
      <OuterWrapper
         href={link}
         target='_blank'
         className={wrapperClassName}
         onMouseOver={() => setHovering(true)}
         onMouseLeave={() => setHovering(false)}
      >         
         <Wrapper
            src={images.colorPic}
            alt={title}
            className={contentClassName}
            clicked={selected}
            hovering={hovering}
         />
         <TextWrapper className={textWrapperClassName}>
            <Title style={{
               fontFamily: "'Rubik', sans-serif",
               fontWeight: "700",
               fontSize:"0.9em"

            }} className={titleClassName}>{title}</Title>
            {body && <Body style={{
               fontFamily: "'Rubik', sans-serif",
               fontWeight: "300",
               fontSize:"0.9em"
            }} className={descriptionClassName}>{typeof body === "function" ? body() : body}</Body>}
         </TextWrapper>
      </OuterWrapper>
   );
};

export default ExperienceItem;
