import styled from "styled-components";
import facebook from "../../assets/images/facebook.png";
import facebookHover from "../../assets/images/facebook-hover.png";
import instagram from "../../assets/images/instagram.png";
import instagramHover from "../../assets/images/instagram-hover.png";
import linkedin from "../../assets/images/linkedin.png";
import linkedinHover from "../../assets/images/linkedin-hover.png";
import twitter from "../../assets/images/twitter.png";
import twitterHover from "../../assets/images/twitter-hover.png";
import hamburgerIcon from "../../assets/images/menu-button-white.png";
import hamburgerIconHover from "../../assets/images/menu-button-hover.png";
import hamburgerIconPurple from "../../assets/images/menu-button-purple.png";
import hamburgerTabletIcon from "../../assets/images/hamburger-tablet.png";
import hamburgerTabletHoverIcon from "../../assets/images/hamburger-tablet-hover.png";
import { NavLink } from "react-router-dom";
import { SiFacebook, SiInstagram, SiLinkedin, SiTwitter } from "react-icons/si";

const primaryDarkPurple = ({ theme }) => theme.primaryDarkPurple;
const primaryGreen = ({ theme }) => theme.primaryGreen;
const primaryWhite = ({ theme }) => theme.primaryWhite;

// const desktopQuery = '(min-width: 769px)'
// const tabletQuery = '(max-width: 768px) and (min-width: 491px)'
// const mobileQuery = '(max-width: 491px)'

const tabletQuery =
  "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const mobileQuery = "(max-width: 491px)";

export const HamburgerDashes = styled.div`
  margin-left: auto;
  margin-top: 5.1vh;
  margin-right: 1.97em;
  height: 1.8em;
  width: 2.2em;
  background-image: url(${(props) =>
    props.flippedColors || props.allPurple
      ? hamburgerIconPurple
      : hamburgerIcon});
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url(${hamburgerIconHover});
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    margin-right: 1.872em;
    margin-top: 2.348vh;
    height: 2.2em;
    width: 2.45em;
    background-image: url(${hamburgerTabletIcon});

    &:hover {
      background-image: url(${hamburgerTabletHoverIcon});
    }
  }

  @media screen and ${mobileQuery} {
    margin-top: 4.398vh;
    margin-right: 1.422em;
    width: 2.9em;
    height: 2.3em;
    background-size: 2.45em 2em;
    background-image: url(${hamburgerTabletIcon});

    &:hover {
      background-image: url(${hamburgerTabletHoverIcon});
    }
  }
`;

export const XOutWrapper = styled.div`
  margin-top: 4.405vh;
  margin-right: 1.97em;
  width: 1.52em;
  height: 1.52em;
  &:hover {
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    margin-right: 2.845em;
    margin-top: 4.369vh;
    width: 2.162em;
    height: 2.162em;
  }

  @media screen and ${mobileQuery} {
    width: 24.44px;
    height: 24.44px;
    margin-top: 29.14px;
    margin-right: 2.22em;
  }
`;

export const XOut = styled.div`
  &:before,
  &:after {
    background-color: ${primaryWhite};
    position: absolute;
    right: 1.97em;
    content: "";
    height: 2em;
    width: 4px;
    border-radius: 15px;

    @media screen and ${tabletQuery} {
      right: 3.2em;
      height: 2.845em;
    }

    @media screen and ${mobileQuery} {
      width: 4px;
      height: 2.3em;
      right: 2.2em;
    }
  }

  &:before {
    transform: translateX(-0.7em) translateY(-0.25em) rotate(45deg);
  }
  &:after {
    transform: translateX(-0.7em) translateY(-0.25em) rotate(-45deg);
  }

  ${XOutWrapper}:hover &:before, ${XOutWrapper}:hover &:after {
    background-color: ${primaryGreen};
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  z-index: 10;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: ${primaryDarkPurple};
  font-family: Rubik;
  color: ${primaryWhite};
  width: ${(props) => (props.menuIsOpen ? "9.76em" : "0px")};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  overflow: hidden;
  transition: width 0.25s cubic-bezier(0.56, 1, 0.56, 1);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */

  @media screen and ${tabletQuery} {
    width: ${(props) => (props.menuIsOpen ? "61.6vw" : "0px")};
  }

  @media screen and ${mobileQuery} {
    width: ${(props) => (props.menuIsOpen ? "82.13vw" : "0px")};
  }
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-height: 31.481vh;
  min-height: 6em;
  margin-top: 5.078vh;
  margin-bottom: 2.768vh;
  margin-right: 1.65em;

  @media screen and ${tabletQuery} {
    margin-top: 5.057vh;
    max-height: 35.644vh;
    margin-right: 3.053em;
    min-height: 12em;
  }

  @media screen and ${mobileQuery} {
    margin-top: 5.06vh;
    max-height: 35.08vh;
    margin-right: 2.151em;
  }
`;

export const TitleNavLink = styled(NavLink)`
  font-size: 1em;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  color: ${primaryWhite};
  // margin:1.3em 0;
  margin:25px 0;

  &:hover {
    color: ${primaryGreen};
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    font-size: 1.374em;
    margin: 25px 0;
  }

  @media screen and ${mobileQuery} {
    font-size: 1.365em;
    margin: 18px 0;
  }
`;

export const Policies = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: auto;
  flex: 1;
  max-height: 18.056vh;
  min-height: 4.5em;
  margin-right: 1.65em;

  @media screen and (max-height: 500px) {
    margin-bottom: 4.31vh;
  }

  @media screen and ${tabletQuery} {
    max-height: 21.2vh;
    margin-right: 3.111em;
    min-height: 6em;
  }

  @media screen and ${mobileQuery} {
    max-height: 20.84vh;
    margin-bottom: 5.022vh;
    margin-right: 2.22em;
    margin-top:6em
  }
`;

export const TermsAndConditionsNavLink = styled(NavLink)`
  color: ${primaryWhite};
`;

export const TermsAndConditionsText = styled.div`
  font-size: 0.62em;
  font-weight: 300;
  text-align: right;
  white-space: nowrap;

  &.terms-top {
    margin-bottom: 0;
  }
  &.terms-bottom {
    margin-top: 0;
  }

  &:hover {
    color: ${primaryGreen};
    cursor: pointer;
  }

  ${TermsAndConditionsNavLink}:hover & {
    color: #34e0b3;
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    font-size: 1.274em;
  }

  @media screen and ${mobileQuery} {
    font-size: 1.11em;
  }
`;

export const PolicyNavLink = styled(NavLink)`
  font-size: 0.62em;
  font-weight: 300;
  text-align: right;
  white-space: nowrap;
  color: ${primaryWhite};

  &.terms-top {
    margin-bottom: 0;
  }
  &.terms-bottom {
    margin-top: 0;
  }

  &:hover {
    color: ${primaryGreen};
    cursor: pointer;
  }

  ${TermsAndConditionsNavLink}:hover & {
    color: #34e0b3;
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    font-size: 1.274em;
  }

  @media screen and ${mobileQuery} {
    font-size: 1.11em;
  }
`;

export const PolicyLink = styled.a`
  font-size: 0.62em;
  font-weight: 300;
  text-align: right;
  white-space: nowrap;
  color: ${primaryWhite};

  &.terms-top {
    margin-bottom: 0;
  }
  &.terms-bottom {
    margin-top: 0;
  }

  &:hover {
    color: ${primaryGreen};
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    font-size: 1.382em;
  }

  @media screen and ${mobileQuery} {
    font-size: 1.11em;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  width: 7.08em;
  justify-content: space-between;
  margin-top: 3.635vh;
  margin-bottom: 4.31vh;
  margin-right: 1.15em;
  height: 3.33vh;

  @media screen and ${tabletQuery} {
    margin-top: 3.541vh;
    width: 13.9em;
    margin-right: 3.11em;
    height: 4.25vh;
    margin-bottom: 4.91vh;
  }

  @media screen and ${mobileQuery} {
    display: none;
  }

  @media screen and (max-height: 500px) {
    display: none;
  }
`;

const SocialImage = styled.a`
  width: 1.3em;
  height: 1.3em;
  background-size: contain;
  background-repeat: no-repeat;
  text-decoration: none;

  .social-icon-style {
    width: 100%;
    height: 100%;
    color: white;
  }

  .social-icon-style:hover {
    color: red;
  }
`;

export const FacebookLink = styled(SocialImage)`
  // background-image: url(${facebook});
  // &:hover { background-image: url(${facebookHover}); }
`;

export const InstagramLink = styled(SocialImage)`
  // background-image: url(${instagram});
  // &:hover {
  //   background-image: url(${instagramHover});
  // }
`;

export const LinkedinLink = styled(SocialImage)`
  // background-image: url(${linkedin});
  // &:hover {
  //   background-image: url(${linkedinHover});
  // }
`;

export const TwitterLink = styled(SocialImage)`
  // width: 1.57em;
  // background-image: url(${twitter});
  // &:hover {
  //   background-image: url(${twitterHover});
  // }

  // @media screen and ${tabletQuery} {
  //   width: 3.082em;
  // }
`;

export const FacebookIcon = styled(SiFacebook)`
  width: 100%;
  height: 100%;
  color: white;
  &:hover {
    color: ${primaryGreen};
  }
`;
export const InstagramIcon = styled(SiInstagram)`
  width: 100%;
  height: 100%;
  color: white;
  &:hover {
    color: ${primaryGreen};
  }
`;
export const LinkedinIcon = styled(SiLinkedin)`
  width: 100%;
  height: 100%;
  color: white;
  &:hover {
    color: ${primaryGreen};
  }
`;
export const TwitterIcon = styled(SiTwitter)`
  width: 100%;
  height: 100%;
  color: white;
  &:hover {
    color: ${primaryGreen};
  }
`;
