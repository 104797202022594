import styled from "styled-components";
import { GrFormClose } from "react-icons/gr";
import rectangle from "../../assets/images/rectangle-77@2x.jpg"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


const tabletQuery =
  "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const mobileQuery = "(max-width: 491px)";

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryGreen = ({ theme }) => theme.primaryGreen;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const primaryBlack = ({ theme }) => theme.primaryBlack;
const secondaryGrey = ({ theme }) => theme.secondaryGrey;

export const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  width:100vw;
  height:100vh;
  background: url(${rectangle}) no-repeat fixed center;
  background-size: cover;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  overscroll-behavior: contain;
  overflow:hidden;

  @media screen and ${desktopQuery} {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000066;
    
  }

  @media screen and ${tabletQuery}, ${mobileQuery} {
    background-color:${secondaryGrey};
    overflow-y: scroll;
  }
`;

export const InnerWrapper = styled.div`
  @media screen and ${desktopQuery} {
    background-color: #FFFFFF;
    width: 95%;
    height: 90%;
    border-radius:5px;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.3);
  }

  @media screen and ${tabletQuery}, ${mobileQuery} {
    background: linear-gradient(-20deg, ${secondaryGrey} 41.5%, ${primaryWhite} 41.5%);
    // background-color: ${primaryWhite};
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }

  @media only screen and ${tabletQuery} {
    display: grid;
    grid-template-rows: repeat(3, auto) 60px;
    overflow:scroll;
    grid-template-areas:
      "head"
      "text"
      "grid"
      "bloc";
  }

  @media only screen and ${mobileQuery} {
    background: linear-gradient(-20deg, ${secondaryGrey} 48.5%, ${primaryWhite} 41.5%);
    display: grid;
    grid-template-rows: repeat(3, auto) 150px;
    overflow:scroll;
    grid-template-areas:
      "head"
      "text"
      "grid"
      "bloc";
  }
`;
// background:  -webkit-linear-gradient(90deg, ${primaryPurple} 50%, ${primaryWhite} 50%);

export const XOutWrapper = styled.div`
  position: absolute;
  top: 0%;
  right:  0%;
  // width: 1.52em;
  // height: 1.52em;
  margin:15px;
  &:hover {
    cursor: pointer;
  }

  @media screen and ${tabletQuery}, ${mobileQuery} {
    display: none;
  }
`;

export const XOut = styled.div`
  &:before,
  &:after {
    background-color: ${primaryPurple};
    position: fixed;
    right: 3%;
    content: "";
    height: 2em;
    width: 0.15em;
    border-radius: 15px;
  }

  &:before {
    transform: translateX(-0.7em) translateY(-0.25em) rotate(45deg);
  }
  &:after {
    transform: translateX(-0.7em) translateY(-0.25em) rotate(-45deg);
  }

  ${XOutWrapper}:hover &:before, ${XOutWrapper}:hover &:after {
    background-color: ${primaryGreen};
    cursor: pointer;
  }

  @media screen and ${tabletQuery}, ${mobileQuery} {
    display: none;
  }
`;

export const BackgroundDiagonal = styled.div`
  background-image: linear-gradient(
    to right bottom,
    ${primaryWhite} 50%,
    ${({ backgroundColor }) => backgroundColor} 50%
  );

  @media screen and ${desktopQuery} {
    display: none;
  }

  @media screen and ${tabletQuery} {
    margin-top: 18vw;
    grid-area: 2 / 1 / 4 / 2;
  }

  @media screen and ${mobileQuery} {
    margin-top: 27vw;
    grid-area: 2 / 1 / 4 / 2;
  }

  @media screen and ${mobileQuery} and (max-width: 320px) {
  }
`;

export const PurpleSpace = styled.div`
  grid-area: bloc;

  @media screen and ${desktopQuery} {
    display: none;
  }

  @media screen and ${tabletQuery}, ${mobileQuery} {
    display: block;
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

export const HeaderWrapper = styled.div`
  grid-area: head;

  @media screen and ${desktopQuery} {
    display: none;
  }

  @media screen and ${tabletQuery}, ${mobileQuery} {
    margin-bottom:3em;
  }
`;

export const Text = styled.div`
  grid-area: text;
  color: ${secondaryGrey};

  @media screen and ${desktopQuery} {
    // display: none;
    margin-top: 5vw;
    // margin-left: 3.78vw;
    width:90%;
    margin-bottom: 2vw;
  }

  @media screen and ${tabletQuery} {
    width:100%;
    margin-top: 0.216vw;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and ${mobileQuery} {
    margin-top: 2.6vw;
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleArrow = styled(IoIosArrowBack)`
  &:hover {
    cursor: pointer;
  }
  color:${primaryPurple};

  @media screen and ${desktopQuery} {
    display: none;
  }

  position: relative;
  width: 5.5vw;
  height: 6.5vw;
  margin-right: 1vw;
  margin-bottom: 0.2vw;

  &:before,
  &:after {
    background-color: ${primaryPurple};
    position: absolute;
    content: "";
    height: 4vw;
    width: 1.12vw;
    border-radius: 2vw;
  }

  &:before {
    transform: translateY(1.8vw) translateX(1.5vw) rotate(135deg);
  }
  &:after {
    transform: translateY(-0.28vw) translateX(1.5vw) rotate(-135deg);
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: 500;
  color:${primaryPurple};

  @media screen and ${desktopQuery} {
    // display: none;
    font-size:36px;
  }

  @media screen and ${tabletQuery} {
    font-size: 32px;
    color:${secondaryGrey};
    width:100%;
    text-align:center;
  }

  @media screen and ${mobileQuery} {
    font-size: 20px;
    font-weight: bold;
    color:${secondaryGrey};
    width:100%;
    text-align:center;
  }
`;

export const Body = styled.p`
  margin: 0;
  line-height:1.5;
  margin-top:1.2em;

  @media screen and ${desktopQuery} {
    font-size:20px;
  }

  @media screen and ${tabletQuery} {
    font-size: 1.15em;
    // font-size:20px;
    margin-top: 1.8em;
    line-height: 1.2em;
  }

  @media screen and ${mobileQuery} {
    font-size: 16px;
    line-height: 1.3;
    width: 87vw;
    margin: 1.1em auto;
  }
`;

export const PhotoGrid = styled.div`
  grid-area: grid;
  // background-color:yellow;

  .transition-group {
    width: 100%;
    height: 100%;
  }

  @media screen and ${desktopQuery} {
    width: 85%;
    height: 80%;
    // margin-left: 6.82vw;
    // margin-top: 3.65vw;
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 0.99vw;
    border-radius: 10px;
  }

  @media screen and ${tabletQuery} {
    // height: 50vw;
    width:100%;
    height:${window.innerHeight/1.7}px;
    margin:0;
    // display:flex;
    // margin-left: 3.78vw;
    // margin-right: 3.78vw;
    // margin-top: 5vw;
    // margin-bottom: -20px;
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 1.6vw;
  }

  @media screen and ${mobileQuery} {
    width:100%;
    height: 53.6vw;
    margin:0;
    // margin-left: 3.78vw;
    // margin-right: 3.78vw;
    // margin-top: 6vw;
    // margin-bottom: -130px;
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 1.6vw;
  }
`;

export const LargePhotoWrapper = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  // background-color:red;

  .large-photo {
    position: absolute;
  }

  .slide-left-enter {
    left: 100%;
  }
  .slide-left-enter.slide-left-enter-active {
    left: 0%;
    transition: left 500ms ease-in-out;
  }
  .slide-left-exit {
    right: 0%;
  }
  .slide-left-exit.slide-left-exit-active {
    right: 100%;
    transition: right 500ms ease-in-out;
    animation-fill-mode: forwards;
  }

  .slide-right-enter {
    right: 100%;
  }
  .slide-right-enter.slide-right-enter-active {
    right: 0%;
    transition: right 500ms ease-in-out;
  }
  .slide-right-exit {
    left: 0%;
  }
  .slide-right-exit.slide-right-exit-active {
    left: 100%;
    transition: left 500ms ease-in-out;
    animation-fill-mode: forwards;
  }

  @media screen and ${mobileQuery} {
    border-radius: 0px;
  }
`;

export const LargePhoto = styled.div`
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 10px;
  border:none;
  position:relative;
  
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and ${tabletQuery}, ${mobileQuery} {
    background-size: cover;
  }
  @media screen and ${mobileQuery} {
    border-radius: 0px;
  }
}
`;
// background-image: url(${({ fileName }) => fileName});

export const PhotoIndex = styled.div`
  display: grid;

  @media screen and ${desktopQuery} {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.73vw;
    width:85%
  }

  @media screen and ${tabletQuery} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1.82vw;
    width:100%;
  }

  @media screen and ${mobileQuery} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
`;

export const SmallPhoto = styled.div`
  width:  90px;
  height: 68px;
  background-image: url(${({ fileName }) => fileName});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow:1px 1px 4px lightgrey;
  border-radius:3px;

  transition: opacity 0.5s, transform 0.5s;

  &.unselected {
    opacity: 0.7;

    @media screen and ${tabletQuery}, ${mobileQuery} {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.selected {
    opacity: 1;
    border:5px solid ${primaryPurple};
    @media screen and ${tabletQuery}, ${mobileQuery} {
      transform: scale(1.06);
      border:3px solid ${primaryPurple};
    }
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and ${tabletQuery}, ${mobileQuery} {
    width:  120px;
    height: 100px;
  }

  @media screen and ${mobileQuery} {
    width:  60px;
    height: 40px;
  }

`;

export const RightArrowWrapper = styled.div`
  position: absolute;
  background-color:rgba(255,255,255,0.75);
  border-radius:100%;
  display:flex;
  align-items:center;
  justify-content:center;


  &:hover {
    cursor: pointer;
  }

  @media screen and ${desktopQuery} {
    right: 2vw;
    top: 45%;
    width: 40px;
    height: 40px;
    display:none
  }

  @media screen and ${tabletQuery} {
    right: 2vw;
    top: 45%;
    width: 40px;
    height: 40px;
  }

  @media screen and ${mobileQuery} {
    right:2vw;
    top: 45%;
    width: 30px;
    height: 30px;
  }
`;

export const DesktopRightArrowWrapper = styled.div`

  &:hover {
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    display:none
  }

  @media screen and ${mobileQuery} {
    display:none
  }

`

export const DesktopLeftArrowWrapper = styled.div`

  &:hover {
    cursor: pointer;
  }

  @media screen and ${tabletQuery} {
    display:none
  }
  @media screen and ${mobileQuery} {
    display:none
  }

`

// desktop
// left: 59vw;
// top: 40.37vh;

export const RightArrow = styled(IoIosArrowForward)`
  color:${primaryPurple};
  width:25px;
  height:25px;

`;

export const DesktopRightArrow = styled(IoIosArrowForward)`
  color:${primaryPurple};
  width:45px;
  height:45px;

`;

export const LeftArrowWrapper = styled.div`
  position: absolute;
  background-color:rgba(255,255,255,0.75);
  border-radius:100%;
  display:flex;
  align-items:center;
  justify-content:center;

  &:hover {
    cursor: pointer;
  }

  @media screen and ${desktopQuery} {
    left: 1vw;
    top:45%;
    width: 40px;
    height: 40px;
    display:none
  }

  @media screen and ${tabletQuery} {
    left: 2vw;
    top:45%;
    width: 40px;
    height: 40px;
  }

  @media screen and ${mobileQuery} {
    left: 2vw;
    top:45%;
    width: 30px;
    height: 30px;
  }
`;

export const LeftArrow = styled(IoIosArrowBack)`
  color:${primaryPurple};
  width:25px;
  height:25px;

`;

export const DesktopLeftArrow = styled(IoIosArrowBack)`
  color:${primaryPurple};
  width:45px;
  height:45px;

`;


export const CloseIcon = styled(GrFormClose)`

  color:  ${primaryPurple};

`


export const FlexWrapper = styled.div`
  display:  flex; 
  flex-direction:row;
  width:  100%; 
  height: 100%; 
  position: relative;

  @media screen and ${tabletQuery}, ${mobileQuery} {
    flex-direction:column
  }

`

export const DesktopPhotoIndexWrapper = styled.div`

  @media screen and ${desktopQuery}{
    disply: initial;
  }

  @media screen and ${tabletQuery}{
    disply: none;
    overflow:hidden;
    width:0;
    height:0
  }

  @media screen and ${mobileQuery}{
    disply: none;
    overflow:hidden;
    width:0;
    height:0
  }

`
export const MobilePhotoIndexWrapper = styled.div`

  @media screen and ${desktopQuery}{
    disply: none;
    overflow:hidden;
    width:0;
    height:0
  }
  
  @media screen and ${tabletQuery}, ${mobileQuery}{
    disply: initial;
    margin-top:2em;
  }

`

export const FlexWrapperLeft  = styled.div`
  flex:1;
  width:50%; 
  height:100%;
  // border:"1px solid black",
  display: flex;
  justify-content:flex-end;

  @media(max-width: 1300px){
    flex: 2;
  }


  @media screen and ${tabletQuery}, ${mobileQuery} {
    flex:0.5;
    width:100%;
    justify-content:center;
  }


  @media screen and ${mobileQuery} {
    flex:0.8;
  }

`

export const FlexWrapperRight = styled.div`
  flex:1.6;
  width:50%;
  display:flex;
  align-items:center;
  justify-content:space-between;
  // background-color:yellow;


  @media screen and ${tabletQuery}, ${mobileQuery} {
    flex:1.5;
    width:90%;
    margin:auto;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    // background-color:green;
  }

  @media screen and ${mobileQuery} {
    width:100%;
  }

`

export const FlexWrapperContainer = styled.div`

  width:90%;
  height:100%;
  // margin:auto;
  display:flex;
  flex-direction:column;
  justify-content:space-between;

  @media screen and ${tabletQuery}, ${mobileQuery} {
    width:90%
  }

`
