import styled from "styled-components";

const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const mobileQuery = "(max-width: 491px)";
const smallDesktopQuery = `${desktopQuery} and (max-height: 800px)`;

const secondaryGrey = ({ theme }) => theme.secondaryGrey;

export const OuterWrapper = styled.a`
   height: 81%;
   width: 100%;
   margin:auto;
   display: flex;   
   flex-direction: column;
   justify-content: center;
   align-items: center;
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Old versions of Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
   user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Opera and Firefox */
   
   &:hover {      
      cursor: pointer;      
   }
   `;
   
   export const Wrapper = styled.img`
   height: 100%;
   width: 92%;  
   box-shadow:2px 2px 18px #888888;
   border-radius:5px; 
   transition:0.2s;

   &:hover{
      box-shadow:15px 2px 18px #888888;

   }
`;

export const TextWrapper = styled.div`
   @media screen and (min-width: 1024px) {
      padding: 0.73em 0.692em 1em 0;      
   }
   width: 96%;
   margin: auto;
`;

export const Title = styled.h2`   

   // font family changed in inline styling

   margin: 0;


   @media screen and ${desktopQuery} {
      font-size: 1.154em;
      margin-bottom: 0.6em;
   }

   @media screen and ${smallDesktopQuery} {
      font-size: 1em;
   }

   @media screen and ${mobileQuery} {
      font-size: 1.388em;
   }
`;

export const Body = styled.div`

   // font family changed in inline styling

   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   letter-spacing: -.4px;
   word-break: break-word;
   

   @media screen and ${desktopQuery} {
      font-size: 0.769em;
   }

   @media screen and ${smallDesktopQuery} {
      font-size: 0.666em;
   }
`;
