export const breakpoints = {
   largeDesktop: {
      min: 1921,
      max: Infinity,
      col: 5,
   },
   desktop: {
      min: 769,
      max: 1920,
      col: 4,
   },
   tablet: {
      min: 491,
      max: 768,
      col: 3,
   },
   phone: {
      min: 320,
      max: 490,
      col: 2,
   },
   smallPhone: {
      max: 321,
      min: -Infinity,
      col: 1,
   },
};