import styled from 'styled-components';

import PhoneNewsletterImageLarge from '../../assets/images/frontphone-half-newsletter.png';
import PhoneNewsletterImageMedium from '../../assets/images/frontphone-newsletter-medium.png';
import PhoneNewsletterImageSmall from '../../assets/images/frontphone-newsletter-small.png';

const primaryDarkPurple = ({ theme }) => theme.primaryDarkPurple;
const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const primaryLightGreen = ({ theme }) => theme.primaryLightGreen;

const tabletQuery = '(max-width: 768px) and (min-width: 491px), (orientation: portrait)';
const desktopQuery = '(min-width: 769px) and (orientation: landscape)';
const mobileQuery = '(max-width: 490px)';

export const Wrapper = styled.main`
   width: 100%;
   height: 100vh;
   background: linear-gradient(-60deg, ${primaryDarkPurple} 2%, ${primaryPurple} 98%);
   text-align: center;
   display: flex;
   flex-direction: column;
`;

export const Logo = styled.img`
   width: 50vmin;
   align-self: center;
   margin-top: 5vh;
`;

export const Text = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;   
   flex: 1 0 45px;         
`;

export const Heading = styled.h1`
   font-size: 2.31em;
   font-weight: 500;
   color: ${primaryWhite};  
   
   @media only screen and ${tabletQuery} {
      font-size: 6vw;         
   }
`;

export const Description = styled.p`
   font-size: 1.923em;
   color: ${primaryWhite};
   white-space: pre-line;
   // max-width: 80%;
   margin-left: auto;
   margin-right: auto;
   margin-block-start: 0;
   display: inline;

   a {
      color: ${primaryLightGreen};
   }

   @media only screen and ${tabletQuery} {
      white-space: normal;
      font-size: 5vw;
   }

   max-width: ${ props => props.isSubscribed ? `100%` : `82%` }
`;

export const Image = styled.div`
   flex: 2 1 auto;

   @media only screen and ${desktopQuery} {                
      background: url(${PhoneNewsletterImageLarge}) bottom center no-repeat;
      background-size: contain;
   }

   @media only screen and ${tabletQuery}{      
      background: url(${PhoneNewsletterImageMedium}) top center no-repeat;
      background-size: 70vw auto;        
   }
  
   @media only screen and ${mobileQuery} {           
      background: url(${PhoneNewsletterImageSmall}) top center no-repeat;
      background-size: 80vw auto;      
   }

`;