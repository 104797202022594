import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import loadingReducer from './reducers/loadingReducer.js'
import cookiesReducer from './reducers/cookiesReducer.js'
import { combineReducers } from 'redux'


const rootReducer = combineReducers({
  loading: loadingReducer,
  cookies: cookiesReducer
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)))

export default store
