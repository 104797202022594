import React, { useState, useEffect, useRef, Fragment } from "react";
import ExperienceGrid from './ExperienceGrid';
import { Container, TitleContainer,FilterContainer, Filter, ItemWrapper } from "./ExperienceListStyles";
import { experiencesData, filtersData } from "./experiencesData";
import Item from "./ExperienceItem";
import useWindowSize from './useWindowSize';
import { Heading } from "./WhatWeDoStyles";
import { breakpoints } from '../../modules/breakpoints';


const Experiences = () => {
   const calculateFilterCount = () => {
      const filtersCount = {
         all: Object.values(experiencesData).length,
      };

      Object.values(experiencesData).forEach((d) => {
         d.filter.forEach((f) => {
            filtersCount[f] = (filtersCount[f] || 0) + 1;
         });
      });

      filtersData.forEach(f => {
         for (const key in filtersCount) {
            if (f.label === key) f["count"] = filtersCount[key];
         }
      })

      return filtersData;
   };   

   const [filters, updateFilters] = useState(calculateFilterCount());
   const [clickedFeature, _setClickedFeature] = useState(null);
   const windowSize = useWindowSize();
   
   const clickedFeatureRef = useRef(clickedFeature);  
   
   const _setGridColumn = () => {               
      if (windowSize) {
         let gridSize = 0.90 * windowSize;

         for (const device in breakpoints) {            
            if (gridSize >= breakpoints[device].min && gridSize <= breakpoints[device].max) return breakpoints[device].col;
         }
      }

      return 3;
   }

   const updateGridSize = () => {      
      let col = _setGridColumn();  
      
      return {
         col: col,         
         itemWidth: Math.floor((windowSize * 0.80) / col),
         itemHeight: Math.floor((windowSize * 0.80 * 1.6) / col), //1.43
      };      
   }

   const [grid, setGrid] = useState(updateGridSize());

   useEffect(() => {        
      setGrid(updateGridSize());      
   }, [windowSize]);

   const setClickedFeature = (data) => {
      clickedFeatureRef.current = data;
      _setClickedFeature(data);
   };

   const gridItemProps = {
      clickedFeature: clickedFeature,
      setClickedFeature: setClickedFeature,
      className: "ux-item",
      isHovering: false,
      wrapperClassName: "ux-outer-wrapper",
      contentClassName: "ux-inner-wrapper",
      textWrapperClassName: "ux-text-wrapper",
      titleClassName: "ux-title",
      descriptionClassName: "ux-body",
      featuresData: experiencesData,
   };

   

   const changeFilter = (event) => {
      const { target: { value } } = event;   
      

      updateFilters((state) =>
         state.map((f) => {
            if (f.label === value) {
               return { ...f, isChecked: true, };
            } else {
               return { ...f, isChecked: false, };
            }
         })
      );
   };

   return (
      <Fragment>
          <div style={{position:"sticky",top:0,background:"white",width:"100vw",zIndex:2,padding:"10px 0"}} >
             <TitleContainer>
               <Heading style={{textAlign:"center"}} id="our-demos-title">
                  <h2 className="secondary-color" >
                     <span className="secondary-color">Our&nbsp;</span>
                     <span className="primary-color">Experiences</span>
                  </h2>
               </Heading>
               {/* <FilterContainer>
                  {filters.map((f) => (
                     <Filter key={`${f.label}_key`} count={f.count}>
                        <input id={f.label} type='checkbox' value={f.label} onChange={changeFilter} checked={f.isChecked} />
                        <label htmlFor={f.label}>{f.name}</label>
                        {
                           // f.isChecked &&
                           //    <div className='superscript'>{f.count}</div>
                        }
                     </Filter>
                  ))}
               </FilterContainer> */}
            </TitleContainer>
         </div>
         <Container>
            <ExperienceGrid
               gridLayout={Object.values(experiencesData)}
               noOfCols={grid.col}
               unitWidth={grid.itemWidth}
               unitHeight={grid.itemHeight}
               filters={filters}
            >
               {Object.values(experiencesData).map((ux) => {
                  return (
                     <ItemWrapper key={ux.id} >
                        <Item feature={ux.id} {...gridItemProps} />
                     </ItemWrapper>
                  );
               })}
            </ExperienceGrid>

         </Container>
      </Fragment>
   );
}

export default Experiences;
