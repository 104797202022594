import React, { useState, useEffect } from 'react'
import { Wrapper, InnerWrapper, XOut, XOutWrapper,
         PhotoGrid, PhotoIndex, SmallPhoto, LargePhoto,
         RightArrow, LeftArrow, LargePhotoWrapper, HeaderWrapper,
         BackgroundDiagonal, PurpleSpace, Text, Title, Body,
         TitleWrapper, TitleArrow, RightArrowWrapper, LeftArrowWrapper,CloseIcon, FlexWrapper,
         DesktopPhotoIndexWrapper,MobilePhotoIndexWrapper,FlexWrapperLeft,FlexWrapperContainer,FlexWrapperRight, 
         DesktopLeftArrowWrapper, DesktopRightArrowWrapper, DesktopLeftArrow, DesktopRightArrow } from './CarouselStyles'
import { clientData } from './clientData'
import Header from '../Header/Header';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import CloseImg from "../../assets/images/close-button@2x.jpg";

const Carousel = ({ carouselClient, setCarouselClient }) => {
    const nodeRef = React.createRef(null)
    const { hoverData, description } = clientData[carouselClient]
    const [photos, setPhotos] = useState([])
    const [largePhotoIndex, setLargePhotoIndex] = useState(null)
    const [right, setRight] = useState(true)

    useEffect(() => {
        setPhotos(clientData[carouselClient].pics)
        setLargePhotoIndex(0)
    }, [])

    const renderPhotoIndex = () => {
        return photos.map((fileName, index) => (
            <SmallPhoto 
                key={index} 
                fileName={fileName}
                className={largePhotoIndex === index ? 'selected' : 'unselected'} 
                onClick={() => switchPhoto(index)}
            />
        ))
    }

    const switchPhoto = newIndex => {
        newIndex > largePhotoIndex ? setRight(true) : setRight(false)
        setTimeout(() => {
            if (newIndex < 0){
                setLargePhotoIndex(photos.length-1)
            } else{
                setLargePhotoIndex((newIndex)%photos.length)
            }
        }, [0])
        
    }

    const renderCarousel = () => {
        return (
            <Wrapper>
                <InnerWrapper>
                    <HeaderWrapper>
                        <Header/>
                    </HeaderWrapper>
                    {/* <BackgroundDiagonal backgroundColor={hoverData.backgroundColor} /> */}
                    {/* <PurpleSpace backgroundColor={hoverData.backgroundColor} /> */}
                    <FlexWrapper>
                        <XOutWrapper onClick={() => setCarouselClient(null)}>
                            {/* <XOut /> */}
                            <img src={CloseImg}s width={30} height={30}/>
                            {/* <CloseIcon size={70}></CloseIcon> */}
                        </XOutWrapper>
                        <FlexWrapperLeft>
                            <FlexWrapperContainer>
                                <Text>
                                    <TitleWrapper>
                                        <TitleArrow onClick={() => setCarouselClient(null)} />
                                        <Title>{hoverData.title}</Title>
                                        <TitleArrow style={{opacity:0}} onClick={() => setCarouselClient(null)} />
                                    </TitleWrapper>
                                    <Body>{description}</Body>
                                </Text>
                                <DesktopPhotoIndexWrapper>
                                    <PhotoIndex>
                                        {renderPhotoIndex()}
                                    </PhotoIndex>
                                </DesktopPhotoIndexWrapper>
                            </FlexWrapperContainer>
                        </FlexWrapperLeft>
                        <FlexWrapperRight>
                            <DesktopLeftArrowWrapper>
                                <DesktopLeftArrow className="outer" onClick={() => switchPhoto(largePhotoIndex - 1)} /> 
                            </DesktopLeftArrowWrapper>
                            <PhotoGrid>
                                <LargePhotoWrapper className={`${right ? 'going-right' : 'going-left'}`}>
                                    <TransitionGroup className="transition-group">
                                        <CSSTransition
                                            appear={false}
                                            nodeRef={nodeRef}
                                            classNames={`${right ? 'slide-left' : 'slide-right'}`}                                 
                                            timeout={{ enter: 500, exit: 500 }}                                 
                                            key={largePhotoIndex}>
                                            <LargePhoto 
                                                className="large-photo" 
                                                ref={nodeRef} 
                                                fileName={photos[largePhotoIndex]}
                                                style={{width:"100%",height:"100%",position:"relative"
                                                // backgroundImage: `url(${photos[largePhotoIndex]})`
                                            }}
                                            >
                                                <img 
                                                    width="100%" 
                                                    style={{position:"absolute", top:"50%",
                                                    left:"50%", transform:"translate(-50%,-50%)",
                                                    borderRadius:10}} src={photos[largePhotoIndex]} 
                                                />
                                            </LargePhoto>
                                        </CSSTransition> 
                                    </TransitionGroup>
                                    <RightArrowWrapper onClick={() => switchPhoto(largePhotoIndex + 1)}>
                                        <RightArrow/>
                                    </RightArrowWrapper>
                                    <LeftArrowWrapper onClick={() => switchPhoto(largePhotoIndex - 1)}>
                                        <LeftArrow /> 
                                    </LeftArrowWrapper> 
                                </LargePhotoWrapper>
                            </PhotoGrid>
                            <DesktopRightArrowWrapper>
                                <DesktopRightArrow onClick={() => switchPhoto(largePhotoIndex + 1)}/>
                            </DesktopRightArrowWrapper>
                            <MobilePhotoIndexWrapper>
                                <PhotoIndex>
                                    {renderPhotoIndex()}
                                </PhotoIndex>
                            </MobilePhotoIndexWrapper>
                        </FlexWrapperRight>
                    </FlexWrapper>
                </InnerWrapper>
            </Wrapper>
        )
    }
    
    return carouselClient ? renderCarousel() : null
    
}

export default Carousel