import styled from "styled-components";

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const primaryGreen = ({ theme }) => theme.primaryGreen;
const primaryLightGreen = ({ theme }) => theme.primaryLightGreen;
const primaryRed = ({ theme }) => theme.primaryRed;

const tabletQuery =
  "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const mobileQuery = "(max-width: 491px)";

export const FormWrapper = styled.form`
  font-family: inherit;
  font-weight: 400;
  font-size: 1.15em;

  @media screen and ${desktopQuery} and (max-height: 800px) {
    font-size: 0.9em;
  }
`;

export const FormBody = styled.div``;

export const FormField = styled.div`
  input {
    border-radius: 0;
    padding: 7px 0;
  }

  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
    color: ${primaryWhite};
    border: 0;

    border-bottom: 1px solid ${primaryWhite};
    outline: 0;
    background: transparent;
    transition: border-color 0.2s;
    width: 100%;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      border: 0;
      border-bottom: 1px solid ${primaryWhite};
      outline: 0;
      background-color: ${primaryPurple};
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: ${primaryWhite} !important;
    }

    &::placeholder {
      color: ${primaryWhite};
      opacity: 0.7;
    }

    &:focus {
      border-bottom-width: 2px;
      padding-bottom: 6px;
      border-image: linear-gradient(
        to right,
        ${primaryGreen},
        ${primaryLightGreen}
      );
      border-image-slice: 1;
      background: transparent;
      outline: 0;
    }

    &:active,
    &:visited {
      background: transparent;
      border: 0;
      padding-bottom: 7px;
      border-bottom: 1px solid ${primaryWhite};
      outline: 0;
    }
  }

  @media screen and ${desktopQuery} {
    textarea {
      height: 11.35vw;
      border-radius:10px
    }
  }

  @media screen and ${tabletQuery} {
    textarea {
      height: 34.11vw;
      border-radius:10px
    }
  }

  @media screen and ${mobileQuery} {
    textarea {
      height: 52.5vw;
      border-radius:10px
    }
  }

  .field-label {
    color: ${primaryWhite};
    opacity: 0.7;
  }

  .field-label.message {
    display: block;
    margin-bottom: 7px;

    @media screen and ${desktopQuery} {
      padding-top: 0em;
    }

    @media screen and ${tabletQuery} {
      padding-top: 0.7em;
    }

    @media screen and ${mobileQuery} {
      padding-top: 0.5em;
    }
  }

  textarea {
    border: 1px solid ${primaryWhite};
    margin-top: 0.5vw;
    padding: 10px;
    box-sizing: border-box;
    line-height: 1.75em;
    resize: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: none;
    }
  }

  input,textarea{
    padding-bottom: 0.2em;
  }

  @media screen and ${tabletQuery} {
    padding-bottom: 0.2em;

  }

  @media screen and ${mobileQuery} {
    padding-bottom: 0.2em;
  }
`;

export const FormSubmitButton = styled.div`
  padding-top: 0.2em;
  @media screen and ${tabletQuery} {
    padding-top: 2.2em;
    display:flex;
    justify-content:center
  }

  @media screen and ${mobileQuery} {
    padding-top: 0.8em;
    display:flex;
    justify-content:center
  }
`;

export const ErrorMessage = styled.div`
  height: 0.5em;
  width: 100%;
  color: ${primaryRed};
  font-family: inherit;
  font-size: 0.67em;
  white-space: nowrap;

  @media screen and ${desktopQuery} {
    margin-bottom: 1.2em;
  }

  @media screen and ${tabletQuery} {
    margin-bottom: 0.7em;
  }

  @media screen and ${mobileQuery} {
    margin-bottom: 0.3em;
  }
`;
