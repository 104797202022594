import React from 'react'
import { NavLink } from 'react-router-dom'

export const policies = {
    cookiePolicy: {
        title: 'Cookie Policy',
        paragraph1: () => {
            return (
                <>
                    This Cookie Policy explains how mobUX, Inc. (“<strong>mobUX</strong>,” “<strong>we</strong>,” “<strong>us</strong>“ or “<strong>our</strong>”) uses cookies and similar technologies in connection with the www.themobux.com website and any other website that we own or control and which posts or links to this Cookie Policy (collectively, the “<strong>Service</strong>“).
                </>
            )
        },
        paragraph2: () => {
            return (
                <>
                    <strong className="section-title">What are cookies?</strong>
                    <br/><br/>
                    Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies serve different purposes, like helping us understand how a site is being used, letting you navigate between pages efficiently, remembering your preferences and generally improving your browsing experience. 
                    <br/><br/>
                    Our Service may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them). We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which we use to recognize your computer or mobile device when it revisits our Service; and (2) third party cookies, which are served by service providers or business partners on our Service, and can be used by these parties to recognize your computer or mobile device when it visits other websites. Third party cookies can be used for a variety of purposes, including site analytics and social media features.
                    <br/><br/>
                    <strong className="section-title">What types of cookies and similar tracking technologies does mobUX use on the Service?</strong>
                    <br/><br/>
                    On the Service, we use cookies and other tracking technologies in the following categories described below: Type, Description, Who serves the cookies, How to control them.
                    <br/><br/>

                    <u>Type: </u>
                    Analytics
                    <br/>
                    <u>Description: </u>
                    These cookies help us understand how our Service is performing and being used.  These cookies may work with web beacons included in emails we send to track which emails are opened and which links are clicked by recipients.
                    <br/>
                    <u>Who serves the cookies (link to privacy policy/site): </u>
                    <a href="https://policies.google.com/privacy?hl=en">Google Analytics</a>; <a href="https://www.activecampaign.com/legal/privacy-policy">ActiveCampaign</a>
                    <br/>
                    <u>How to control them: </u>See 'your choices' below. 
                    <br/><br/>
                    Google Analytics uses its own cookies. You can find out more information about Google Analytics cookies <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">here</a> and about how Google protects your data <a href="http://www.google.com/analytics/learn/privacy.html">here</a>.  You can prevent the use of Google Analytics relating to your use of our Service by downloading and installing a browser plugin available <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">here</a>.
                    
                    <br/><br/>

                    <u>Type: </u>Essential
                    <br/>
                    <u>Description: </u>
                    These cookies are necessary to allow the technical operation of our Service (e.g., they enable you to move around on a website and to use its features).
                    <br/>
                    <u>Who serves the cookies (link to privacy policy/site): </u>
                    <a href="https://policies.google.com/privacy?hl=en">Google Tag Manager</a>
                    <br/>
                    <u>How to control them: </u>See 'your choices' below. 
                    
                    <br/><br/>

                    <u>Type: </u>Functionality / Performance
                    <br/>
                    <u>Description: </u>
                    These cookies enhance the performance and functionality of our Service.
                    <br/>
                    <u>Who serves the cookies (link to privacy policy/site): </u>
                    <a href="https://www.intercom.com/terms-and-policies#privacy">Intercom</a>
                    <br/>
                    <u>How to control them: </u>See 'your choices' below. 
                    
                    <br/><br/>

                    <strong className="section-title">Other technologies</strong>
                    <br/><br/>
                    In addition to cookies, our Service may use other technologies, such as Flash technology and pixel tags to collect information automatically.
                    <br/><br/>
                    <em>Browser Web Storage</em>
                    <br/><br/>
                    We may use browser web storage (including via HTML5), also known as locally stored objects (“LSOs”), for similar purposes as cookies. Browser web storage enables the storage of a larger amount of data than cookies. Your web browser may provide functionality to clear your browser web storage.
                    <br/><br/>
                    <em>Web Beacons</em>
                    <br/><br/>
                    We may also use web beacons (which are also known as pixel tags and clear GIFs) on our Service and in our HTML formatted emails to track the actions of users on our Service and interactions with our emails. Unlike cookies, which are stored on the hard drive of your computer or mobile device by a website, pixel tags are embedded invisibly on webpages or within HTML formatted emails. Pixel tags are used to demonstrate that a webpage was accessed or that certain content was viewed, typically to measure the success of our marketing campaigns or engagement with our emails and to compile statistics about usage of the Service, so that we can manage our content more effectively.
                    <br/><br/>
                    <strong className="section-title" >Your choices</strong>
                    <br/><br/>
                    Most browsers let you remove or reject cookies.  To do this, follow the instructions in your browser settings.  Many browsers accept cookies by default until you change your settings.  Please note that if you set your browser to disable cookies, the Service may not work properly.  
                    <br/><br/>
                    For more information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>.  If you do not accept our cookies, you may experience some inconvenience in your use of our Service. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our Service.
                    <br/><br/>
                    For more information about how we collect, use and share your information, see our <NavLink to="/privacy-policy">Privacy Policy</NavLink>.
                    <br/><br/>
                    <strong className="section-title">Changes</strong>
                    <br/><br/>
                    Information about the cookies we use may be updated from time to time, so please check back on a regular basis for any changes.
                    <br/><br/>
                    <strong className="section-title">Questions</strong>
                    <br/><br/>
                    If you have any questions about this Cookie Policy, please contact us by email at <a href="mailto:help@themobux.com">help@themobux.com</a>.
                </>
            )
        },
        arrowLinkText: "SPEAK TO US",
        navLink: '/contact-us' 
    },
    termsAndConditions: {
        title: 'Terms of Use',
        paragraph1: () => {
            return (
                <>
                    PLEASE READ THESE TERMS OF USE (“<strong>TERMS</strong>”) CAREFULLY. THESE TERMS ARE A LEGAL CONTRACT BETWEEN YOU (“<strong>USER</strong>”) AND MOBUX, INC. (<strong>“MOBUX,” “OUR,” “WE,” OR “US”</strong>). 
                </>
            )
        },
        paragraph2: () => {
            return (
                <div className="privacy-policy">
                    <strong >SECTION 8 OF THESE TERMS IS AN ARBITRATION CLAUSE THAT REQUIRES MOST DISPUTES BETWEEN US TO BE RESOLVED ON AN INDIVIDUAL, NON-CLASS ACTION BASIS THROUGH BINDING AND FINAL ARBITRATION INSTEAD OF IN COURT. SEE SECTION 8 FOR MORE INFORMATION REGARDING THIS ARBITRATION CLAUSE, AND HOW TO OPT OUT.</strong> 
                    <br/><br/>
                    By accessing or using themobux.com (<strong>“Site”</strong>), or clicking on a button or taking another action to signify your acceptance of these Terms, you: (1) agree to be bound by these Terms and any future amendments and additions to these Terms as published through the Site; (2) represent you are of legal age in your jurisdiction of residence to form a binding contract; and (3) represent that you have the authority to enter into these Terms personally and, if applicable, on behalf of any company, organization or other legal entity on whose behalf you use the Site. <strong>Except as otherwise provided herein, if you do not agree to be bound by these Terms, you may not access or use the Site.</strong> 
                    <br/><br/>
                    Subject to Section 8.9 of these Terms, mobUX reserves the right to modify these Terms or its policies relating to the Site at any time, effective upon posting of an updated version of these Terms on the Site. You should regularly review these Terms, as your continued use of the Site after any such changes constitutes your agreement to such changes.
                    <ol>
                        <li>
                            <span>
                                <strong>ACCESS TO THE SITE.</strong>  
                            </span>
                        </li>
                    </ol>
                    <span className="formal-li">1.1</span>
                    <span className="formal-title">License.</span>
                    Subject to these Terms, mobUX grants you a non-transferable, non-exclusive, revocable, limited license to (a) use and access the Site solely for your own personal, noncommercial use; and (b) access, download and use the content and information made available by mobUX on the Site solely for personal and/or academic, noncommercial purposes. 
                    <br/><br/>
                    <span className="formal-li">1.2</span>
                    <span className="formal-title">Certain Restrictions.</span>
                    <strong style={{marginRight: "20px"}}></strong>  
                    The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site or any content thereon in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.  Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.  All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.
                    <br/><br/>
                    <span className="formal-li">1.3</span>
                    <span className="formal-title">Modification.</span>
                    mobUX reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you.  You agree that mobUX will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.
                    <br/><br/>
                    <span className="formal-li">1.4</span>
                    <span className="formal-title">No Support or Maintenance.</span>
                    You acknowledge and agree that mobUX will have no obligation to provide you with any support or maintenance in connection with the Site.
                    <br/><br/>
                    <span className="formal-li">1.5</span>
                    <span className="formal-title">Ownership.</span>
                    You acknowledge that all the intellectual property rights, including copyrights, patents, trade marks, and trade secrets, in the Site and its content are owned by mobUX or mobUX’s suppliers.  Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 1.1. mobUX and its suppliers reserve all rights not granted in these Terms.  There are no implied licenses granted under these Terms.
                    <ol start="2">
                        <li>
                            <span>
                                <strong>FEEDBACK.</strong>  If you provide mobUX with any feedback or suggestions regarding the Site (“<strong>Feedback</strong>”), you hereby grant mobUX a royalty-free, worldwide, transferable, sublicensable, irrevocable, perpetual license to use or incorporate into the Site or any products or services of mobUX or otherwise exploit in any manner mobUX sees fit any such Feedback.  mobUX will treat any Feedback you provide to mobUX as non-confidential and non-proprietary.  You agree that you will not submit to mobUX any information or ideas that you consider to be confidential or proprietary.
                            </span>
                        </li>
                        <li>
                            <span>
                                <strong>INDEMNIFICATION.</strong>  You agree to indemnify and hold mobUX (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Site; (b) your violation of these Terms; or (c) your violation of applicable laws or regulations.  mobUX reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims.  You agree not to settle any matter without the prior written consent of mobUX.  mobUX will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
                            </span>
                        </li>
                        <li>
                            <span>
                                <strong>THIRD-PARTY LINKS; OTHER USERS</strong>
                            </span>   
                        </li>
                    </ol>
                    <span className="formal-li">4.1</span>
                    <span className="formal-title">Third-Party Links.</span>
                    The Site may contain links to third-party websites and services (collectively, “<strong>Third-Party Links</strong>”).  Such Third-Party Links are not under the control of mobUX, and mobUX is not responsible for any Third-Party Links.  mobUX provides access to these Third-Party Links only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links.  You use all Third-Party Links at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.  You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links.
                    <br/><br/>
                    <span className="formal-li">4.2</span>
                    <span className="formal-title">Release.</span>
                    You hereby release and forever discharge mobUX (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site (including any interactions with, or act or omission of, other Site users or any Third-Party Links).  IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
                    <ol start="5">
                        <li>
                            <span>
                                <strong>DISCLAIMERS.</strong>  
                            </span>
                        </li>
                    </ol>
                    THE SITE, AND ANY CONTENT THEREON, IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND MOBUX  (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.  WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE.  IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.
                    <br/><br/>
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.  SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.  
                    <ol start="6">
                        <li>
                            <span>
                                <strong>LIMITATION ON LIABILITY</strong>  
                            </span>
                        </li>
                    </ol>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL MOBUX  (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF MOBUX  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. 
                    <br/><br/>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO TEHSE TERMS (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.  YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.
                    <br/><br/>
                    SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.   
                    <ol start="7">
                        <li>
                            <span>
                                <strong>TERM AND TERMINATION.</strong>  Subject to this Section, these Terms will remain in full force and effect while you use the Site.  We may suspend or terminate your rights to use the Site at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.  Upon termination of your rights under these Terms, your right to access and use the Site will terminate immediately. mobUX will not have any liability whatsoever to you for any termination of your rights under these Terms.  Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2-9.
                            </span>
                        </li>
                        <li>
                            <span>
                                <strong>DISPUTE RESOLUTION.</strong>  <strong><em>Please read the following arbitration agreement in this Section (“Arbitration Agreement”) carefully.  It requires you to arbitrate disputes with mobUX and limits the manner in which you can seek relief from us.  </em></strong>
                            </span>
                        </li>
                    </ol>
                    <span className="formal-li">8.1</span>
                    <span className="formal-title">Applicability of Arbitration Agreement.</span>
                    You agree that any dispute, claim, or request for relief relating in any way to your access or use of the Website, to any products sold or distributed through the Website, or to any aspect of your relationship with mobUX, will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims or seek relief in small claims court if your claims qualify,; and (2) you or mobUX may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents).  <strong>This Arbitration Agreement shall apply, without limitation, to all disputes or claims and requests for relief that arose or were asserted before the effective date of these Terms or any prior version of these Terms.</strong>
                    <br/><br/>
                    <span className="formal-li">8.2</span>
                    <span className="formal-title">Arbitration Rules and Forum.</span>
                    The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.  To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your dispute or claim or request for relief to our registered agent: Legalinc Corporate Services Inc., 651 N Broad Street, Suite 206, Middletown, Delaware 19709.  The arbitration will be conducted by JAMS, an established alternative dispute resolution provider.  Disputes involving claims, counterclaims, or request for relief under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at <a href="http://www.jamsadr.com/rules-streamlined-arbitration/">http://www.jamsadr.com/rules-streamlined-arbitration/</a>; all other disputes shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at <a href="http://www.jamsadr.com/rules-comprehensive-arbitration/">http://www.jamsadr.com/rules-comprehensive-arbitration/</a>.  JAMS’s rules are also available at www.jamsadr.com or by calling JAMS at 800-352-5267.  If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum.  If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver from JAMS, mobUX will pay them for you.  In addition, mobUX will reimburse all such JAMS’s filing, administrative, hearing and/or other fees for disputes, claims, or requests for relief totaling less than $10,000 unless the arbitrator determines the claims are frivolous.  
                    <br/><br/>
                    You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the country where you live or at another mutually agreed location. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. 
                    <br/><br/>
                    <span className="formal-li">8.3</span>
                    <span className="formal-title">Authority of Arbitrator.</span>
                    The arbitrator shall have exclusive authority to (a) determine the scope and enforceability of this Arbitration Agreement and (b) resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to, any assertion that all or any part of this Arbitration Agreement is void or voidable.  The arbitration will decide the rights and liabilities, if any, of you and mobUX.  The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim. The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and the Terms (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and us.  
                    <br/><br/>
                    <span className="formal-li">8.4</span>
                    <span className="formal-title">Waiver of Jury Trial.</span>
                    YOU AND MOBUX HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY.  You and mobUX are instead electing that all disputes, claims, or requests for relief shall be resolved by arbitration under this Arbitration Agreement, except as specified in Section 8.1 above.  An arbitrator can award on an individual basis the same damages and relief as a court and must follow these Terms as a court would.  However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.  
                    <br/><br/>
                    <span className="formal-li">8.5</span>
                    <span className="formal-title">Waiver of Class or Other Non-Individualized Relief. </span>
                    ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.  If a decision is issued stating that applicable law precludes enforcement of any of this subsection’s limitations as to a given dispute, claim, or request for relief, then such aspect must be severed from the arbitration and brought into the state or federal courts located in the State of New York.  All other disputes, claims, or requests for relief shall be arbitrated.
                    <br/><br/>
                    <span className="formal-li">8.6</span>
                    <span className="formal-title">30-Day Right to Opt Out.</span>
                    You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt out to: help@themobux.com, within 30 days after first becoming subject to this Arbitration Agreement. Your notice must include your name and address, your mobUX username (if any), the email address you used to set up your mobUX account (if you have one), and an unequivocal statement that you want to opt out of this Arbitration Agreement.   If you opt out of this Arbitration Agreement, all other parts of this Agreement will continue to apply to you.  Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.
                    <br/><br/>
                    <span className="formal-li">8.7</span>
                    <span className="formal-title">Severability.</span>
                    Except as provided in Section 8.5, if any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect.
                    <br/><br/>
                    <span className="formal-li">8.8</span>
                    <span className="formal-title">Survival of Agreement.</span>
                    This Arbitration Agreement will survive the termination of your relationship with mobUX.
                    <br/><br/>
                    <span className="formal-li">8.9</span>
                    <span className="formal-title">Modification.</span>
                    Notwithstanding any provision in these Terms to the contrary, we agree that if mobUX makes any future material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such change becoming effective by writing mobUX at the following address: 
                    <br/>
                    150 W 28th Street, Floor 2, New York, NY 10001.
                    <br/><br/>
                    <ol start="9">
                        <li>
                            <span>
                                <strong>GENERAL.</strong>  
                            </span>
                        </li>
                    </ol>
                    <span className="formal-li">9.1</span>
                    <span className="formal-title">Electronic Communications.</span>
                    The communications between you and mobUX may take place via electronic means, whether you visit the Site or send mobUX e-mails, or whether mobUX posts notices on the Site or communicates with you via e-mail.  For contractual purposes, you (a) consent to receive communications from mobUX in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that mobUX provides to you electronically satisfy any legal requirement that such communications would satisfy if it were to be in writing.  The foregoing does not affect your statutory rights, including but not limited to the Electronic Signatures in Global and National Commerce Act at 15 U.S.C. §7001 et seq. (“<strong>E-Sign</strong>”).
                    <br/><br/>
                    <span className="formal-li">9.2</span>
                    <span className="formal-title">Assignment.</span>
                    The Terms, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated or otherwise transferred by you without mobUX’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.
                    <br/><br/>
                    <span className="formal-li">9.3</span>
                    <span className="formal-title">Force Majeure. </span>
                    mobUX shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including, but not limited to, acts of God, war, terrorism, riots, embargos, acts of civil or military authorities, fire, floods, accidents, strikes or shortages of transportation facilities, fuel, energy, labor or materials. 
                    <br/><br/>
                    <span className="formal-li">9.4</span>
                    <span className="formal-title">Questions, Complaints, Claims. </span>
                    If you have any questions, complaints or claims with respect to the Site, please contact us at: <a href="mailto:help@themobux.com">help@themobux.com</a>.  We will do our best to address your concerns.  If you feel that your concerns have been addressed incompletely, we invite you to let us know for further investigation.
                    <br/><br/>
                    <span className="formal-li">9.5</span>
                    <span className="formal-title">Exclusive Venue.</span>
                    To the extent the parties are permitted under these Terms to initiate litigation in a court, both you and mobUX agree that all claims and disputes arising out of or relating to the Terms will be litigated exclusively in the state or federal courts located in New York City, New York.
                    <br/><br/>
                    <span className="formal-li">9.6</span>
                    <span className="formal-title">Governing Law. </span>
                    THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF NEW YORK, CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS DOES NOT APPLY TO THE TERMS.
                    <br/><br/>
                    <span className="formal-li">9.7</span>
                    <span className="formal-title">Notice.</span>
                    Where mobUX requires that you provide an e-mail address, you are responsible for providing mobUX with your most current e-mail address.  In the event that the last e-mail address you provided to mobUX is not valid, or for any reason is not capable of delivering to you any notices required/ permitted by the Terms, mobUX’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice.  You may give notice to mobUX at the following address: 150 W 28th Street, Floor 2, New York, NY 10001.  Such notice shall be deemed given when received by mobUX by letter delivered by nationally recognized overnight delivery service or first class postage prepaid mail at the above address.
                    <br/><br/>
                    <span className="formal-li">9.8</span>
                    <span className="formal-title">Waiver</span>
                    Any waiver or failure to enforce any provision of the Terms on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.
                    <br/><br/>
                    <span className="formal-li">9.9</span>
                    <span className="formal-title">Severability. </span>
                    If any portion of these Terms is held invalid or unenforceable, that portion shall be construed in a manner to reflect, as nearly as possible, the original intention of the parties, and the remaining portions shall remain in full force and effect.
                    <br/><br/>
                    <span className="formal-li">9.10</span>
                    <span className="formal-title">Export Control.</span>
                    You may not use, export, import, or transfer the Site except as authorized by U.S. law, the laws of the jurisdiction in which you obtained the Site, and any other applicable laws.  In particular, but without limitation, the Site may not be exported or re-exported (a) into any United States embargoed countries, or (b) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Department of Commerce’s Denied Person’s List or Entity List. By using the Site, you represent and warrant that (y) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country and (z) you are not listed on any U.S. Government list of prohibited or restricted parties. You also will not use the Site for any purpose prohibited by U.S. law, including the development, design, manufacture or production of missiles, nuclear, chemical or biological weapons.  You acknowledge and agree that products, services or technology provided by mobUX are subject to the export control laws and regulations of the United States.  You shall comply with these laws and regulations and shall not, without prior U.S. government authorization, export, re-export, or transfer mobUX products, services or technology, either directly or indirectly, to any country in violation of such laws and regulations.
                    <br/><br/>
                    <span className="formal-li">9.11</span>
                    <span className="formal-title">Consumer Complaints.</span>
                    In accordance with California Civil Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952-5210.
                    <br/><br/>
                    <span className="formal-li">9.12</span>
                    <span className="formal-title">Entire Agreement.</span>
                    The Terms are the final, complete and exclusive agreement of the parties with respect to the subject matter hereof and supersedes and merges all prior discussions between the parties with respect to such subject matter.
                </div>
            )
        },
        arrowLinkText: "SPEAK TO US",
        navLink: '/contact-us' 
    },
    privacyPolicy: {
        title: 'Privacy Policy',
        paragraph1: () => {
            return (
                <>
                    This “Privacy Policy” describes the privacy practices of mobUX, Inc. (“<strong>mobUX,</strong>” “<strong>we</strong>,” “<strong>us</strong>” or “<strong>our</strong>”) in connection with the www.themobux.com website and any other website that we own or control and which posts or links to this Privacy Policy (collectively, the “<strong>Service</strong>”), and the rights and choices available to individuals with respect to their information. 
                </>
            )
        },
        paragraph2: () => {
            return (
                <>
                    <strong className="section-title">What we collect from you</strong>
                    <br/><br/>
                    <strong >Personal Information We Collect</strong> 
                    <br/><br/>
                    <strong>Information you provide to us.</strong> Personal information that you may provide through the Service or otherwise includes: 
                    <ul>
                        <li>
                            <strong>Contact information</strong>, such as your first name, last name, professional title, organizational affiliation, postal address, office location, email address and telephone number; 
                        </li>
                        <li>
                            <strong>Communications</strong>, such as information you provide when you respond to surveys, participate in market research activities, participate in telephone conferences with our representatives (which may be recorded with your permission where permitted by law); report a problem with Service, receive customer support or otherwise communicate with us;
                        </li>
                        <li>
                            <strong>Transaction information</strong>, such as your bank account information, Automated Clearing House (ACH) payout information, credit card or other payment card details that you use to pay for the Service, and your Service-related billing information and transaction history;
                        </li>
                        <li>
                            <strong>Marketing information</strong>, such your preferences for receiving marketing communications and details about how you engage with them; and     
                        </li>
                        <li>
                            <strong>Other information</strong> that you choose to provide but is not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.
                        </li>
                    </ul>
                    <strong>Information from third party sources.</strong>  We may combine personal information we receive from you with personal information we obtain from other sources, such as business partners, such as joint marketing partners; and publicly accessible sources, such as social media platforms. 
                    <br/><br/>
                    <strong>Data collected automatically.</strong> We, our service providers, and our business partners may automatically log the following information about you, your computer or mobile device, and your activity over time on the Site and other online services:
                    <ul>
                        <li>
                            <strong>Device data</strong>, such as your computer or mobile device operating system type and version number, manufacturer and model, browser type, screen resolution, IP address, unique identifiers, and general location information such as city or town.
                        </li>
                        <li>
                            <strong>Usage data</strong>, such as the website you visited before browsing to the Site, pages or screens you viewed, how long you spent on a page or screen, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access.
                        </li>
                    </ul>
                    Some of our automatic collection is facilitated by:
                    <ul>
                        <li>
                            <strong>Cookies</strong>, which are text files stored on a visitor‘s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently and remembering your preferences, enabling functionality, and helping us understand user activity and patterns. See our Cookie Policy for more information.
                        </li>
                        <li>
                            <strong>Web beacons</strong>, also known as pixel tags or clear GIFs, which are typically used to demonstrate that a webpage or email was accessed or opened, or that certain content within it was viewed or clicked, typically to compile statistics about usage of websites and the success of marketing campaigns.
                        </li>
                    </ul>
                    <strong>How We Use Your Personal Information</strong>
                    <br/><br/>
                    We use your personal information for the following purposes and as otherwise described in this Privacy Policy or at the time of collection:
                    <br/><br/>
                    <strong>Service delivery.</strong>  We use your personal information to:
                    <ul>
                        <li>provide, operate and improve the Service</li>
                        <li>provide information about our products and services</li>
                        <li>communicate with you about the Service, including by sending you announcements, updates, security alerts, and support and administrative messages</li>
                        <li>understand your needs and interests, and personalize your experience with the Service and our communications</li>
                        <li>provide support and maintenance for the Service</li>
                        <li>respond to your requests, questions and feedback.</li>
                    </ul>
                    <strong>Research and development.</strong>  We may use your personal information for research and development purposes, including to analyze and improve the Service and our business and develop other products and services.  As part of these activities, we may create aggregated, de-identified or other anonymous data from personal information we collect.  We make personal information into anonymous data by removing information that makes the data personally identifiable to you.  We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.   
                    <br/><br/>
                    <strong>Marketing communications.</strong>  We may send you mobUX-related marketing communications as permitted by law. You will have the ability to opt-out of our marketing and promotional communications as described in the “Opt out of marketing” section below. 
                    <br/><br/>
                    <strong>Compliance and protection.</strong>  We may use your personal information to:
                    <ul>
                        <li>protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
                        <li>audit our internal processes for compliance with legal and contractual requirements and internal policies;</li>
                        <li>enforce our Terms of Use;</li>
                        <li>protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft; and</li>
                        <li>comply with applicable laws, lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.</li>
                    </ul>
                    <strong>Other purposes with your consent.</strong> In some cases we will ask for your consent to collect, use or share your personal information for other purposes. 
                    <br/><br/> 
                    <strong>How We Share Your Personal Information</strong>
                    <br/><br/>
                    We do not share your personal information with third parties without your consent, except in the following circumstances or as described in this Privacy Policy:
                    <br/><br/>
                    <strong>Affiliates.</strong>  We may share your personal information with our subsidiaries and corporate affiliates for purposes consistent with this Privacy Policy.
                    <br/><br/>
                    <strong>Service providers.</strong>  We may share your personal information with third party companies and individuals that provide services on our behalf or help us operate the Service (such as customer support, hosting, analytics, email delivery, marketing, and database management services). These third parties may use your personal information only as directed or authorized by us and in a manner consistent with this Privacy Policy, and are prohibited from using or disclosing your information for any other purpose.
                    <br/><br/>
                    <strong>Professional advisors.</strong> We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.
                    <br/><br/>
                    <strong>Compliance and protection.</strong> We may disclose your personal information as we believe appropriate to government or law enforcement officials or private parties (a) for the compliance and protection purposes described above; (b) as required by law, lawful requests or legal process, such as to respond to subpoenas or requests from government authorities; (c) where permitted by law in connection with any legal investigation; and (d) to prosecute or defend legal claims.
                    <br/><br/>
                    <strong>Business transfers.</strong>  We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution. 
                    <br/><br/>
                    <strong>Your Choices </strong>
                    <br/><br/>
                    In this section, we describe the rights and choices available to all users. Users who are located within the European Union can find additional information about their rights below.
                    <br/><br/>
                    <strong>Access, update, correct or delete your Information.</strong> You may review, update, correct or delete your personal information by emailing us at <a href="mailto:help@themobux.com">help@themobux.com</a>.
                    <br/><br/>
                    <strong>Marketing communications.</strong>  You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email.  You may continue to receive service-related and other non-marketing emails.  
                    <br/><br/>
                    <strong>Cookies.</strong>  We may allow service providers and other third parties to use cookies and similar technologies to track your browsing activity over time and across the Service and third party websites. For more details, see our Cookie Policy.
                    <br/><br/>
                    <strong>Do Not Track.</strong>  Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit.  We currently do not respond to “Do Not Track” or similar signals.  To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.
                    <br/><br/>
                    <strong>Choosing not to share your personal information.</strong> Where we are required by law to collect your personal information, or where we need your personal information in order to provide the Service to you, if you do not provide this information when requested (or you later ask to delete it), we may not be able to provide you with our services.  We will tell you what information you must provide to receive the Service by designating it as required at the time of collection or through other appropriate means.
                    <br/><br/>
                    <strong>Other sites and services</strong>
                    <br/><br/>
                    The Service may contain links to other websites and other online services operated by third parties.  These links are not an endorsement of, or representation that we are affiliated with, any third party.  In addition, our content may be included on web pages or online services that are not associated with us. We do not control third party websites or online services, and we are not responsible for their actions.  Other websites and services follow different rules regarding the collection, use and sharing of your personal information.  We encourage you to read the privacy policies of the other websites and online services you use.
                    <br/><br/>
                    <strong>Security practices</strong>
                    <br/><br/>
                    The security of your personal information important to us. We take a number of organizational, technical and physical measures designed to protect the personal information we collect, both during transmission and once we receive it. However, no security safeguards are 100% secure and we cannot guarantee the security of your information.
                    <br/><br/>
                    <strong>International data transfers</strong>
                    <br/><br/>
                    mobUX is headquartered in the United States and has affiliates and service providers in other countries, and your personal information may be collected, used and stored in the United States or other locations outside of your home country. Privacy laws in the locations where we handle your personal information may not be as protective as the privacy laws in your home country.
                    <br/><br/>
                    European Union users should read the important information provided below about transfer of personal information outside of the European Union. 
                    <br/><br/>
                    <strong>Children</strong>
                    <br/><br/>
                    The Service is not directed at, and mobUX does not knowingly acquire or receive personal information from, children under the age of 16. If we learn that any user of the Service is under the age of 16, we will take appropriate steps to delete that individual’s personal information and restrict that individual from future access to the Service.
                    <br/><br/><br/>
                    <strong>Changes to this Privacy Policy </strong>
                    <br/><br/>
                    We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service. We may, and if required by law will, also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or another manner through the Service.
                    <br/><br/>
                    Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon implementation of the new changes on the Service (or as otherwise indicated at the time of posting). In all cases, your continued use of the Service after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.
                    <br/><br/>
                    <strong>How to Contact Us</strong>
                    <br/><br/>
                    If you have any questions or concerns about our Privacy Policy, please contact us:
                    <br/><br/>
                    <div style={{marginLeft: "30px"}}>mobUX, Inc.<br/> 150 W 28th Street, Floor 2<br/> New York, New York 10001</div>
                    <br/>
                    <span style={{marginLeft: "30px"}}><a href="mailto:help@themobux.com">Email: help@themobux.com</a></span>
                    <br/><br/><br/>
                    <strong>Information for European Union Users</strong>
                    <br/><br/>
                    <strong>Personal information.</strong> References to “personal information” in this Privacy Policy are equivalent to “personal data” governed by European data protection legislation. 
                    <br/><br/>
                    <strong>Controller.</strong>  mobUX, Inc. is the controller of your personal information covered by this Privacy Policy for purposes of European data protection legislation.  
                    <br/><br/>
                    <strong>Legal bases for processing.</strong>  The legal bases on which we process your personal information as described in this Privacy Policy will depend on the type of personal information and the specific context in which we process it.  However, the legal bases we typically rely on are set out in the table below.  We rely on our legitimate interests as our legal basis only where those interests are not overridden by the impact on you (unless we have your consent or our processing is otherwise required or permitted by law).
                    <br/><br/>
                    <table>
                        <thead>
                            <tr>
                                <th>PROCESSING PURPOSE</th>
                                <th>LEGAL BASIS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Service delivery</td>
                                <td>Processing is necessary to perform the contract governing our operation of the Site or the provision of the Services, or to take steps that you request prior to engaging our Services.  Where we cannot process your personal data as required to operate the Site or Services on the grounds of contractual necessity, we process your personal information for this purpose based on our legitimate interest in providing you with the Services you access and request. </td>
                            </tr>
                            <tr>
                                <td>Marketing</td>
                                <td>Processing is based on your consent where that consent is required by applicable law.  Where we rely on your consent you have the right to withdraw it any time in the manner indicated when you consent or in the Service. Where such consent is not required by applicable law, we process your personal information for these purposes based on our legitimate interests in promoting our business.</td>
                            </tr>
                            <tr>
                                <td>Research & development</td>
                                <td>Processing is based on our legitimate interests in performing research and development to improve our services and develop new services. </td>
                            </tr>
                            <tr>
                                <td>Compliance & protection</td>
                                <td>Processing is necessary to comply with our legal obligations or based on our legitimate interests in protecting our or others’ rights, privacy, safety or property.</td>
                            </tr>
                            <tr>
                                <td>Other purposes with your consent</td>
                                <td>Processing is based on your consent. Where we rely on your consent you have the right to withdraw it anytime in the manner indicated when you consent or in the Service.</td>
                            </tr>
                        </tbody>
                    </table>
                    <br/><br/>
                    <strong>Use for new purposes.</strong>  We may use your personal information for reasons not described in this Privacy Policy where permitted by law and the reason is compatible with the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis.
                    <br/><br/>
                    <strong>Retention.</strong>  We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
                    <br/><br/>
                    To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.
                    <br/><br/>
                    <strong>Your rights.</strong>  European data protection laws give you certain rights regarding your personal information. You may ask us to take the following actions in relation to your personal information that we hold:
                    <ul>
                        <li><strong>Opt-out.</strong> Stop sending you direct marketing communications. You may continue to receive Service-related and other non-marketing communications.</li>
                        <li><strong>Access.</strong> Provide you with information about our processing of your personal information and give you access to your personal information.</li>
                        <li><strong>Correct.</strong> Update or correct inaccuracies in your personal information.</li>
                        <li><strong>Delete.</strong> Delete your personal information.</li>
                        <li><strong>Transfer.</strong> Transfer a machine-readable copy of your personal information to you or a third party of your choice.</li>
                        <li><strong>Restrict.</strong> Restrict the processing of your personal information.</li>
                        <li><strong>Object.</strong> Object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights.</li>
                    </ul>
                    You can submit these requests by email to <a href="mailto:help@themobux.com">help@themobux.com</a> or our postal address provided above. We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">here</a>.
                    <br/><br/><br/>
                    <strong>Cross-Border Data Transfer</strong>
                    <br/><br/>
                    If we transfer your personal information out of the European Economic Area or Switzerland and are required to apply additional safeguards to your personal information under European data protection legislation, we will do so. Such safeguards may include applying the European Commission Model contracts for the transfer of personal data to third countries described <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en#international-data-transfers-using-model-contracts">here</a>, or for transfers to third parties in the United States, ensuring they participate in the EU-U.S. Privacy Shield Framework or Swiss-U.S. Privacy Shield Framework. Please contact us for further information about any such transfers or the specific safeguards applied.
                </>
            )
        },
        arrowLinkText: "SPEAK TO US",
        navLink: '/contact-us' 
    }
}

