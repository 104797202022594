import React from "react";
import ReactDOM from "react-dom";
import {
  ModalContainer,
  ModalArea,
  CloseButton,
  CloseSpan,
  CloseIcon,
  ModalBody,
} from "./ModalStyles";
import { ModalContext } from "./modalContext";
import SentNotification from "../Overlay/SentNotification";

const Modal = () => {
  let { isShowing, handleModal, modalContent, page } = React.useContext(
    ModalContext
  );
  let component;

  switch (modalContent) {
    case "sent-success":
    case "sent-error":
    case "loading":
      component = <SentNotification type={modalContent} page={page} />;
      break;
    default:
      break;
  }

  if (isShowing) {
    return ReactDOM.createPortal(
      <ModalContainer tag="aside" role="dialog" tabIndex="-1" ariaModal="true">
        <ModalArea>
          <CloseButton
            ariaLabel="Close Modal"
            ariaLabelledBy="close-modal"
            onClick={() => handleModal()}
          >
            <CloseSpan>Close</CloseSpan>
            <CloseIcon viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </CloseIcon>
          </CloseButton>
          <ModalBody>{component}</ModalBody>
        </ModalArea>
      </ModalContainer>,
      document.querySelector("#modal-root")
    );
  } else return null;
};

export default Modal;
