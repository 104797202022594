import styled from "styled-components";
import PhoneImageLarge from "../../assets/images/phone_bg_large.png";
import PhoneImageMediumLarge from "../../assets/images/phone_bg_medium_large.png";
import PhoneImageMedium from "../../assets/images/phone_bg_medium.png";
import PhoneImageSmall from "../../assets/images/phone_bg_small.png";

const primaryDarkPurple = ({ theme }) => theme.primaryDarkPurple;
const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const secondaryGrey = ({ theme }) => theme.secondaryGrey;
const smallDesktopQuery = `(min-width: 1024px)`;
const iPadPro = `(min-device-width: 1024px) and (max-device-height: 1366px)`;
const tabletQuery = `(min-width: 481px) and (max-width: 1023px)`;
const phoneQuery = `(max-width: 480px)`;
const largeTabletQuery = `(min-width: 768px) and (max-width: 1023px)`;
const smallTabletQuery = `(min-width: 481px) and (max-width: 767px)`;
const largePhoneQuery = `(max-width: 480px)`;
const smallPhoneQuery = `(max-width: 320px)`;

export const Container = styled.div`
   overflow: hidden;
   height: min-content;

   // Desktop
   @media only screen and ${smallDesktopQuery} { 
      height: 100vh;     
      background: linear-gradient(-60deg, ${primaryDarkPurple} 1%, ${primaryPurple} 50%, ${primaryWhite} 50%);
   }

   // Tablet Portrait
   @media only screen and ${tabletQuery} and (orientation: portrait) {  
      height: 100vh;    
      background: linear-gradient(-50deg, ${primaryDarkPurple} 11%, ${primaryPurple} 43%, ${primaryWhite} 43%);
   }

   // Tablet Landscape
   @media only screen and ${tabletQuery} and (orientation: landscape) {  
      height: min-content;    
      background: linear-gradient(-50deg, ${primaryDarkPurple} 11%, ${primaryPurple} 35%, ${primaryWhite} 35%);
   }      

   // Phone Portrait
   @media only screen and ${phoneQuery} and (orientation: portrait) {    
      height: 100vh;  
      background: linear-gradient(-50deg, ${primaryDarkPurple} 1%, ${primaryPurple} 44%, ${primaryWhite} 44%);
   }

   // Phone Landscape
   @media only screen and ${phoneQuery} and (orientation: landscape) {    
      height: min-content;  
      background: linear-gradient(-50deg, ${primaryDarkPurple} 1%, ${primaryPurple} 36%, ${primaryWhite} 36%);
   }

   // Phone Small
   @media only screen and ${smallPhoneQuery} {
      background: linear-gradient(-50deg, ${primaryDarkPurple} 1%, ${primaryPurple} 37%, ${primaryWhite} 37%);
   }

   // Tall Phone
   @media only screen and (max-width: 480px) and (min-height: 700px) {
      background: linear-gradient(-50deg, ${primaryDarkPurple} 1%, ${primaryPurple} 47%, ${primaryWhite} 47%);
   }   
`;

export const Wrapper = styled.div`
   // overflow: hidden;   

   @media only screen and ${smallDesktopQuery} {       
      display: grid;     
      grid-template-columns: 33% 67%;      
      grid-template-areas: 
         'text image .';             
      width: 92vw;
      margin: -5.5vh auto;
   }

   @media only screen and ${tabletQuery}, ${phoneQuery} and (orientation: landscape) {
      display: grid;
      grid-template-columns: 60% 40%;      
      grid-template-areas: 
         'text image';         
      width: 92vw;
      margin: 13.5vh auto;      
   }

   @media only screen and ${phoneQuery} and (orientation: portrait) {
      display: grid;
      grid-template-columns: 40% 60%;
      grid-template-rows: auto;
      grid-template-areas: 
         'text text'
         'link image';         
      width: 92vw;
      margin: 3vh auto;
   }
`;

export const TextWrapper = styled.div`   
   font-size: inherit;
   grid-area: text;
   color: ${secondaryGrey};

   @media only screen and ${smallDesktopQuery} {
      align-self: center;
   }
`;

export const TextHeading = styled.div`   
   font-weight: 500;
   line-height: 1em;
   text-transform: uppercase;
   white-space: pre-line;

   @media only screen and ${smallDesktopQuery} {
      font-size: 5.384em;
   }

   @media only screen and ${tabletQuery} {
      font-size: 4.838em;
   }

   @media only screen and ${phoneQuery} {
      font-size: 3.75em;
   }

`;

export const TextMessage = styled.p`   
   line-height: 1.17em;
   white-space: pre-line;

   @media only screen and ${smallDesktopQuery} {
      font-size: 1.155em;
   }

   @media only screen and ${tabletQuery} {
      font-size: 1.38em;
   }

   @media only screen and ${largePhoneQuery} {
      font-size: 1.11em;
      max-width: 78vw;
   }   
`;

export const LinkWrapper = styled.div`
   grid-area: link;      
   text-transform: uppercase;

   @media only screen and ${smallDesktopQuery} {
      font-size: 0.923em;
   }

   @media only screen and ${tabletQuery} {
      font-size: 1.094em;
      max-width: 50vw;
   }

   @media only screen and ${phoneQuery} {
      font-size: 1.11em;
      max-width: 36vw;
   }
`;

export const ImageDiv = styled.div`
   grid-area: image;

   // Desktop
   @media only screen and ${smallDesktopQuery} {
      width: 30vw;
      height: 90vh;
      background: url(${PhoneImageLarge}) center center no-repeat;
      background-size: contain;
   }

   // Tablet
   @media only screen and ${largeTabletQuery} {
      width: auto;
      height: 100vh;
      background: url(${PhoneImageMediumLarge}) top center no-repeat;
      background-size: contain;
   }

   // Tablet Small
   @media only screen and ${smallTabletQuery}, ${largePhoneQuery} and (orientation: landscape) {
      width: auto;
      height: 100vh;
      background: url(${PhoneImageMedium}) top center no-repeat;
      background-size: contain;
   }

   // Phone
   @media only screen and ${largePhoneQuery} and (orientation: portrait) {
      margin-top: -4em; //-9vh;
      height: 100vh;
      max-height: 55vh;
      background: url(${PhoneImageSmall}) left top no-repeat;
      background-size: contain;
   }

   @media only screen and ${smallPhoneQuery} and (orientation: portrait) {      
      max-height: 43vh;      
   }
`;
