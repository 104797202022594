import React, { useContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  __RouterContext,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, PageWrapper } from "../modules/globalStyles";
import { theme } from "../modules/theme";
import { useSelector } from "react-redux";
import Home from "./Home/Home";
import WhatWeDo from "./WhatWeDo/WhatWeDo";
import FeaturesContainer from "./Features/FeaturesContainer";
import OurClients from "./OurClients/OurClients";
import ContactUs from "./ContactUs/ContactUs";
import Policy from "./Policies/Policy";
import Careers from "./Careers/Careers";
import Subscription from "./Subscription/Subscription";
import NotFound from "./NotFound/NotFound";
import { ModalProvider } from "./Modal/modalContext";
import { useTransition, animated } from "react-spring";

// SLide Image
import slideImage from "../assets/images/Scroll Icon@6x.png";
import ScrollIndicator from "./ScrollIndicator";

const App = () => {
  const { showCookieNotification } = useSelector((state) => state.cookies);
  const { location } = useContext(__RouterContext);

  const screens = useTransition(location, (location) => location.pathname, {
    from: { transform: "translateX(100%)" },
    enter: { transform: "translateX(0px)" },
    leave: { transform: "translateX(-100%)" },
    // from: { opacity: "0" },
    // enter: { opacity: "1" },
    // leave: { opacity: "0" },
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const renderRoutes = () => {
    return screens.map(({ item, props, key }) => (
      <animated.div style={props} key={key}>
        <Switch location={item}>
          <Route exact path="/">
            <PageWrapper>
              <Home />
            </PageWrapper>
          </Route>
          <Route path="/what-we-do">
            <PageWrapper>
              <WhatWeDo />
            </PageWrapper>
          </Route>
          <Route path="/features">
            <PageWrapper>
              <FeaturesContainer />
            </PageWrapper>
          </Route>
          <Route path="/our-clients">
            <PageWrapper>
              <OurClients />
            </PageWrapper>
          </Route>
          <Route path="/contact-us" component={ContactUs}>
            <PageWrapper>
              <ContactUs />
            </PageWrapper>
          </Route>
          <Route
            key="privacy-policy"
            exact
            path="/privacy-policy"
            render={(props) => <Policy {...props} policy="privacyPolicy" />}
          />
          <Route
            key="terms-and-conditions"
            exact
            path="/terms-and-conditions"
            render={(props) => (
              <Policy {...props} policy="termsAndConditions" />
            )}
          />
          <Route
            key="cookie-policy"
            exact
            path="/cookie-policy"
            render={(props) => <Policy {...props} policy="cookiePolicy" />}
          />
          <Route exact path="/careers" component={Careers} />
          <Route
            key="subscribe"
            exact
            path="/subscribe"
            render={(props) => <Subscription {...props} isSubscribed={true} />}
          />
          <Route
            key="unsubscribe"
            exact
            path="/unsubscribe"
            render={(props) => <Subscription {...props} isSubscribed={false} />}
          />
          <Route path="/" component={NotFound} />
        </Switch>
      </animated.div>
    ));
  };

  return (
    //  <Router>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ModalProvider>
        {renderRoutes()}
        {!showCookieNotification && <ScrollIndicator />}
      </ModalProvider>
    </ThemeProvider>
    //  {/* </Router> */}
  );
};

export default App;
