import React from "react";
import {
  Container,
  HeaderWrapper,
  Heading,
  Text,
  BackgroundDiagonal,
  PhoneDiv,
  SubHeading,
  FormSection,
  ArrowLinkWrapper,
  FormText,
  // SocialIcons,
  // FacebookLink,
  // InstagramLink,
  // LinkedinLink,
  // TwitterLink,
  // FacebookIcon,
  // InstagramIcon,
  // LinkedinIcon,
  // TwitterIcon,
  MobileButtonWrapper
} from "./HomeStyles";
import DemoForm from "../DemoForm/DemoForm";
import ArrowLink from "../ArrowLink/ArrowLink";
import Header from "../Header/Header";
import Notification from "../Notification/Notification";
import { NavLink } from "react-router-dom";

const Home = () => (
  <Container>
    <HeaderWrapper>
      <Header />
    </HeaderWrapper>
    <BackgroundDiagonal />
    <Text>
      <Heading>
        <h1>
          <span className="primary-color mobile-text">Mobile&nbsp;</span>
          <span className="secondary-color content-text">Content&nbsp;</span>
          <span className="secondary-color that-text">that&nbsp;</span>
          <span className="primary-color drives-text">drives action.</span>
        </h1>
      </Heading>
      <SubHeading>
        <div className="no-break">Custom mobile landing experiences</div>
        <div className="no-break">delivered in a bite-size story format</div>
        <div className="no-break">that gets you the engagement you</div>
        <div className="no-break">need and the conversion you want.</div>
      </SubHeading>
    </Text>
    <MobileButtonWrapper>
      <a href="/what-we-do#demo-section-list" className="mobile-button">EXPLORE OUR WORKS</a>
    </MobileButtonWrapper>
    <ArrowLinkWrapper>
      <ArrowLink navLink="/what-we-do#demo-section-list" />
    </ArrowLinkWrapper>
    <PhoneDiv />
    <FormText>{DemoForm.defaultProps.headingText}</FormText>
    <FormSection>
      <DemoForm />
    </FormSection>
    {/* <SocialIcons>
      <FacebookLink
        className="facebook-link"
        href="https://www.facebook.com/theMobux"
        target="_blank"
      >
        <FacebookIcon />
      </FacebookLink>
      <InstagramLink
        className="instagram-link"
        href="https://www.instagram.com/themobux/"
        target="_blank"
      >
        <InstagramIcon />
      </InstagramLink>
      <LinkedinLink
        className="linkedin-link"
        href="https://www.linkedin.com/company/mobux/"
        target="_blank"
      >
        <LinkedinIcon />
      </LinkedinLink>
      <TwitterLink
        className="twitter-link"
        href="https://twitter.com/themobux"
        target="_blank"
      >
        <TwitterIcon />
      </TwitterLink>
    </SocialIcons> */}
    <Notification />
  </Container>
);

export default Home;
