import styled, { createGlobalStyle } from "styled-components";
import { responsive } from "./responsive";

const tabletQuery =
  "(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)";
const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const mobileQuery = "(max-width: 491px)";

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }   
  body {    
    color: ${({ theme }) => theme.primaryBlack};
    height: 100vh;
    text-rendering: optimizeLegibility;
    font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
  }
  a {
    color: ${({ theme }) => theme.primaryLightGreen};
    text-decoration: none;

    @media screen and ${desktopQuery}{
      color: ${({ theme }) => theme.primaryPurple};
    }
  }
  @media screen and (min-width: 320px) {
    html, body {      
      font-size: calc(14px + (26 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
  }

`;

export const PageWrapper = styled.div`
  position: absolute;
  width: 100%;
`;
