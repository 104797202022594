import React from 'react'
import { Wrapper, LogoNavLink } from './HeaderStyles'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'

const Header = ({ flippedColors=false, allPurple=false }) => {
    return (
        <Wrapper>
            <LogoNavLink to="/" props={{flippedColors: flippedColors}}/>
            <HamburgerMenu flippedColors={flippedColors} allPurple={allPurple} />
        </Wrapper>
    )
}

export default Header