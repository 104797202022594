export function createCookie(name, value, days, path, domain, secure) {
  let expires= null;
  if (days) {
      let date = new Date();
      date.setTime( date.getTime() + (days*24*60*60*1000));
      expires = date.toGMTString();
  } else {
        expires = "";
  }
  let cookieString = name + "=" + escape (value);
  if (expires) cookieString += "; expires=" + expires;
  if (path) cookieString += "; path=" + escape (path);
  if (domain) cookieString += "; domain=" + escape (domain);
  if (secure) cookieString += "; secure";
  document.cookie = cookieString;
}

export function readCookie(name) {
  if (document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`))) {
    return true
  }
  return null
}