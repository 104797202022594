import styled from "styled-components";

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const primaryGreen = ({ theme }) => theme.primaryGreen;
const primaryLightGreen = ({ theme }) => theme.primaryLightGreen;
const primaryRed = ({ theme }) => theme.primaryRed;

const tabletQuery = "(max-width: 768px) and (min-width: 491px)";
const mobileQuery = "(max-width: 491px)";

export const DemoFormWrapper = styled.form`
  font-family: inherit;
  font-weight: 400;
  font-size: 1.15em;
`;

export const AgreementText = styled.div`
  padding-top: 0.25em;
  padding-bottom: 0;
  font-size: 0.75em;
  white-space: pre-line;
  color: ${primaryWhite};
  // opacity: 0.8;

  a {
    color: ${primaryGreen};
    font-weight: 500;
    white-space: pre;
  }
`;

export const DemoFormBody = styled.div`
  @media screen and (min-width: 800px) and (max-width: 1125px) {
  }
`;

// Select Box
export const MobilePhoneFieldGroup = styled.div`
  display: flex;

  .select-search {
    position: relative;
    font-family: inherit;
    margin-right: 10px;
  }

  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }

  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid ${primaryWhite};
    border-bottom: 1px solid ${primaryWhite};
    pointer-events: none;
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 2em;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
    min-width: 500px;

    @media screen and ${tabletQuery} {
      max-height: 15vh;
      min-width: 45vw;
    }

    @media screen and ${mobileQuery} {
      max-height: 25vh;
      min-width: 85vw;
    }
  }

  .select-search__input {
    font-family: inherit;
    font-size: inherit;
    color: ${primaryWhite};
    border: 0;
    border-bottom: 1px solid ${primaryWhite};
    outline: 0;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    width: 3.5em;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    :-webkit-autofill:active {
      border: 0;
      border-bottom: 1px solid ${primaryWhite};
      outline: 0;
      background-color: ${primaryPurple};
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: ${primaryWhite} !important;
    }

    &::placeholder {
      color: ${primaryWhite};
      opacity: 0.7;
    }

    &:focus {
      border-width: 2px;
      border-image: linear-gradient(
        to right,
        ${primaryGreen},
        ${primaryLightGreen}
      );
      border-image-slice: 1;
      background: transparent;
      outline: 0;
    }

    &:active,
    &:visited {
      background: transparent;
      border: 0;
      border-bottom: 1px solid ${primaryWhite};
      outline: 0;
    }
  }

  .select-search__options {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .select-search__option {
    font: inherit;
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
  }

  search__option.is-selected:hover {
    background: ${primaryLightGreen};
    color: #fff;
  }

  .select-search__option.is-selected {
    background: ${primaryGreen};
    color: #fff;
  }

  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }
`;

export const DemoFormField = styled.div`
  padding-bottom: 1.25em;

  input {
    font-family: inherit;
    font-size: inherit;
    color: ${primaryWhite};
    border: 0;
    outline: 0;
    border-bottom: 1px solid ${primaryWhite};
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    border-radius: 0;
    width: 100%;
    padding-left: 5px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    :-webkit-autofill:active {
      border: 0;
      border-bottom: 1px solid ${primaryWhite};
      outline: 0;
      background-color: ${primaryPurple};
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: ${primaryWhite} !important;
    }

    &::placeholder {
      color: ${primaryWhite};
      opacity: 0.7;
    }

    &:focus {
      border-width: 2px;
      border-image: linear-gradient(
        to right,
        ${primaryGreen},
        ${primaryLightGreen}
      );
      border-image-slice: 1;
      background: transparent;
      outline: 0;
    }

    &:active,
    &:visited {
      background: transparent;
      border: 0;
      border-bottom: 1px solid ${primaryWhite};
      outline: 0;
    }
  }
`;

export const DemoFormSubmitButton = styled.div``;

export const DemoFormErrorMessage = styled.div`
  position: absolute;
  color: ${primaryRed};
  font-family: inherit;
  font-size: 0.67em;
  white-space: nowrap;
`;
