import React, { useState } from 'react'
import { OuterWrapper, Wrapper, TextWrapper, Title, Body } from './FeatureStyles'

const Feature = ({
   feature,
   featuresData,
   clickedFeature,
   setClickedFeature,
   isHovering,
   wrapperClassName,
   contentClassName,
   textWrapperClassName,
   titleClassName,
   descriptionClassName,
}) => {
   const [hovering, setHovering] = useState(isHovering);
   const { title, body, images, TextWrapperStyleProps } = featuresData[feature];
   const selected = clickedFeature === feature;

   return (
      <OuterWrapper
         className={wrapperClassName}
         images={images}
         onMouseOver={() => setHovering(true)}
         onMouseLeave={() => setHovering(false)}
         onClick={() => setClickedFeature(selected ? null : feature)}
      >
         <Wrapper className={contentClassName} images={images} clicked={selected} hovering={hovering}>
            <TextWrapper TextWrapperStyleProps={TextWrapperStyleProps} className={textWrapperClassName}>
               <Title className={titleClassName}>{title}</Title>
               <Body className={descriptionClassName}>{body()}</Body>
            </TextWrapper>
         </Wrapper>
      </OuterWrapper>
   );
};

export default Feature