import styled from 'styled-components'

const primaryPurple = ({ theme }) => theme.primaryPurple
const primaryWhite = ({ theme }) => theme.primaryWhite
const tabletQuery = '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
const desktopQuery = '(min-width: 769px) and (orientation: landscape)'
const mobileQuery = '(max-width: 491px)'

export const ButtonContainer = styled.div`
  background-color: ${primaryWhite};
  display:flex;
  align-items:center;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */


  @media screen and ${desktopQuery}{
    width: 13.9em;
    width: 22vw;
    width: 25vw;
    height: 5.185vh;
    min-height: 2.5vw;
    border-radius: 0.521vw;
  }

  @media screen and ${desktopQuery} and (max-width: 950px){
    width: 28vw;
  }

  @media screen and ${tabletQuery}{
    width: 42.58vw;
    height: 4.297vh;
    height: 5.7vw;
    min-height: 30px;
    border-radius: 0.926vh;
  }

  @media screen and ${mobileQuery}{
    width: 100%;
    height: 6.597vh;
    height: 11.8vw;
    border-radius: 10px;
  }

`;

export const Text = styled.p`
  color: ${primaryPurple};
  text-transform: uppercase;
  font-family: 'Rubik';
  font-weight:300;

  @media screen and ${desktopQuery}{
    font-size: 0.743em;
    width: 75%;
    margin-left: 9vw;
  }



  @media screen and ${tabletQuery}{
    font-size: 0.801em;
    width: 100%;
    text-align: center;
  }

  @media screen and ${mobileQuery}{
    font-size: 0.801em;
    width: 100%;
    text-align: center;
  }
`;

export const TripleSpan = styled.span`
  margin:auto;

  @media screen and ${desktopQuery}{
    font-size: 1.146vw;
    max-width: 2.292vw;
    letter-spacing: 0.104vw;
  }

  @media screen and ${tabletQuery}{
    font-size: 1.25em;
    letter-spacing: 0.104vw;  
  }

  @media screen and ${mobileQuery}{
    font-size: 1.5em;
    letter-spacing: 0.104vw;  
  }
`

export const ButtonTab = styled.button`
  position: absolute;
  background-color: ${primaryPurple};
  padding:0;
  border:none;
  display:inline-block;
  color:#fff;
  transition: margin-left;
  
  &:hover {
    cursor:pointer;
  }

  &:focus {
    outline: none;
  }

  &.not-dragging, &.triggered {
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  @media screen and ${desktopQuery}{
    width: 6.302vw;
    height: 4.444vh;
    min-height: 2vw;
    border-radius: 0.926vh;
    margin-left: 0.3083vw;

    &.triggered {
      margin-left: 15.3vw;
      margin-left: 18.3vw;
    }
  }

  @media screen and ${desktopQuery} and (max-width: 950px){
    &.triggered {
      margin-left: 21.3vw;
    }
  }

  @media screen and ${tabletQuery}{
    width: 10.16vw;
    height: 3.52vh;
    height: 4.6vw;
    min-height: 24px;
    border-radius: 0.926vh;
    margin-left: 0.5vw;
    
    &.dragging.triggered, &.triggered {
      margin-left: 32vw !important;
    }
  }

  @media screen and ${mobileQuery}{
    width: 20.805vw;
    height: 5.400vh;
    height: 9.66vw;
    border-radius: 0.926vh;
    margin-left: 0.7vw;
    border-radius:10px;

    

    &.triggered {
      margin-left: 68.2vw !important;
    }
  }
`;
