import React from 'react';


import image1 from "../../assets/images/demo-section-images/Basil Haydens_Virtual_Event.jpg";
import image2 from "../../assets/images/demo-section-images/The Nou Project_Online Commerce.png";
import image3 from "../../assets/images/demo-section-images/Frome_App Download.png";
import image4 from "../../assets/images/demo-section-images/Willa's_Brand.png";
import image5 from "../../assets/images/demo-section-images/Audemar_Piguet_Live_Event.png";
import image6 from "../../assets/images/demo-section-images/Wild For_eCommerce.png";
import image7 from "../../assets/images/demo-section-images/Legent_Virtual_Event.png";
import image9 from "../../assets/images/demo-section-images/Buddy_Insurance_Brand.PNG";
import image8 from "../../assets/images/demo-section-images/House_Beer_Product_Launch.png";

const imagePath = 'ux/';

export const filtersData = [
   { label: "all", name: "All", isChecked: true },
   { label: "marketing", name: "Marketing", isChecked: false },
   { label: "lead-generation", name: "Lead Generation", isChecked: false },
   { label: "sales", name: "Sales", isChecked: false },
//    { label: "event-management", name: "Event Management", isChecked: false },
];

export const experiencesData = {
    1: {
        id: "1", // replace with UX id later
        title: "Basil Hayden's",                
        body: "Virtual Event Activation",
        filter: ["marketing"],
        link: "https://themobux.com/works/basilhaydens",        
        images: {
            // colorPic: `${imagePath}ux_1.jpg`,
            colorPic: image1,
        }
    },
    2: {
        id: "2", // replace with UX id later
        title: "The Nou Project",                
        body: "Shoppable Instagram",
        filter: ["sales"],
        link: "https://themobux.com/works/thenouproject",               
        images: {
            // colorPic: `${imagePath}ux_2.jpg`,
            colorPic: image2,
        }
    },
    3: {
        id: "3", // replace with UX id later        
        title: "Frome",
        body: "App Download",
        filter: ["lead-generation"],
        link: "https://themobux.com/works/frome",        
        images: {
            // colorPic: `${imagePath}ux_3.jpg`,
            colorPic: image3,
        }
    },
    4: {
        id: "4", // replace with UX id later        
        title: "Willa's Kitchen",
        body: "Online Commerce",
        filter: ["sales"],
        link: "https://themobux.com/works/willaskitchen",
        images: {
            // colorPic: `${imagePath}ux_4.jpg`,
            colorPic: image4,
        }
    },
    5: {
        id: "5", // replace with UX id later        
        title: "House Beer",
        body: "Product Launch",
        filter: ["marketing"],
        link: "https://themobux.com/works/housebeer",        
        images: {
            // colorPic: `${imagePath}ux_8.jpg`,
            colorPic: image8,
        }
    },
    6: {
        id: "6", // replace with UX id later        
        title: "Audemars Piguet",
        body: "Digital Auction",
        filter: ["lead-generation"],
        link: "https://themobux.com/works/audemarspiguet",        
        images: {
            // colorPic: `${imagePath}ux_6.jpg`,
            colorPic: image5,
        }
    },
    7: {
        id: "7", // replace with UX id later        
        title: "Legent",
        body: "Digital Event Companion",
        filter: ["lead-generation"],
        link: "https://themobux.com/works/legent",        
        images: {
            // colorPic: `${imagePath}ux_6.jpg`,
            colorPic: image7,
        }
    },
    8: {
        id: "8", // replace with UX id later        
        title: "Wild For",
        body: "Podcast Landing Page",
        filter: ["lead-generation"],
        link: "https://themobux.com/works/wildfor",        
        images: {
            // colorPic: `${imagePath}ux_6.jpg`,
            colorPic: image6,
        }
    },
    9: {
        id: "9", // replace with UX id later        
        title: "Buddy Insurance",
        body: "Lead Generation",
        filter: ["lead-generation"],
        link: "https://themobux.com/works/buddyinsurance",        
        images: {
            // colorPic: `${imagePath}ux_6.jpg`,
            colorPic: image9,
        }
    }
 
}