import styled from "styled-components";
import mapHi from '../../assets/images/map-hi-pin.png'

const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const secondaryGrey = ({ theme }) => theme.secondaryGrey;
const primaryBlack = ({ theme }) => theme.primaryBlack;

const tabletQuery = '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
const desktopQuery = '(min-width: 769px) and (orientation: landscape)'
const mobileQuery = '(max-width: 491px)'
const smallDesktopQuery = `${desktopQuery} and (max-height: 800px)`

export const Container = styled.div`
   display: grid;
   
   @media only screen and ${desktopQuery} {
      grid-template-rows: auto 1fr;
      grid-template-columns: 37.5% 62.5%;
      grid-template-areas: "head head"
                           "text grid";
      height: min-content;
      min-height: 100vh;
      background: linear-gradient(-60deg, #310854 1%, ${primaryPurple} 50.5%, ${primaryWhite} 50.5%);             
   }

    @media only screen and ${tabletQuery}{
        background:  -webkit-linear-gradient(90deg, ${primaryPurple} 50%, ${primaryWhite} 50%);
        grid-template-rows: repeat(3, auto) 20px;
        grid-template-areas: "head"
                             "text"
                             "grid"
                             "bloc";
    }

    @media only screen and ${mobileQuery}{
        height: min-content;
        min-height: 100vh;
        background:  -webkit-linear-gradient(90deg, ${primaryPurple} 50%, ${primaryWhite} 50%);
        grid-template-rows: repeat(2, auto) auto 100px;
        grid-template-areas: "head"
                             "text"
                             "grid"
                             "grid";
    }
`;

export const BackgroundDiagonal = styled.div` 
background: linear-gradient(-20deg, #310854 1%, ${primaryPurple} 55.5%, ${primaryWhite} 50.5%);
    
    @media screen and ${desktopQuery} {
            display: none;
    }

    @media screen and ${tabletQuery} {
        margin-top: 18vw;
        grid-area: 2 / 1 / 4 / 2;
    }

    @media screen and ${mobileQuery} {
        background: linear-gradient(-25deg, #310854 1%, ${primaryPurple} 70%, ${primaryWhite} 50.5%);
        margin-top: 45vw;
        grid-area: 2 / 1 / 4 / 2;
    }

    @media screen and ${mobileQuery} and (max-width: 320px) {
       
    }
`;

export const PurpleSpace = styled.div`
    grid-area: bloc;

    @media screen and ${desktopQuery}{
        display: none;
    }

    @media screen and ${tabletQuery}{
        display: block;
    }

    @media screen and ${mobileQuery}{
        display: none;
    }
`;

export const HeaderWrapper = styled.div`
    grid-area: head;
`;

export const Text = styled.div` 
    grid-area: text;
    
    @media screen and ${desktopQuery}{
        margin: 25vh 0 0 4.115vw;
        height: min-content;
        width: 28.23vw;
    }

    @media screen and ${tabletQuery}{
        text-align: center;
        width: 80%;
        margin: 0 auto;
        margin-top: 9.22vw;
        margin-bottom: 8vw;
    }

    @media screen and ${mobileQuery}{
        margin: 7vw 0 5.4vw 8vw;
        width: 84.267vw;
        text-align: center;
    }
`;

export const Heading = styled.div`
   color: ${secondaryGrey};
   font-weight: 500;

   & span.purple-text {
       color: ${primaryPurple};
   }

    @media only screen and ${desktopQuery}{
        font-size: 3.077em;
    }

    @media only screen and ${smallDesktopQuery}{
        font-size: 2.266em;
    }

    @media only screen and ${tabletQuery}{
        font-size: 2.765em;
    }

    @media only screen and ${mobileQuery} {
        font-size: 30px;
        margin-bottom: 0.2em;
    }
`;

export const SubHeading = styled.p`
   margin: 0;
   font-weight: 400;
   color: ${secondaryGrey};
   margin-top: 0.5em;
       
    

    @media only screen and ${desktopQuery}{
        font-size: 1.154em;      
    }

    @media only screen and ${smallDesktopQuery}{
        font-size: 0.85em;
    }

    @media only screen and ${tabletQuery} { 
        font-size: 20px;   
        line-height: 1.3;  
        margin-top:2em;
    }   

    @media only screen and ${mobileQuery} {     
        font-size: 1.11em;   
        margin-bottom: 1.11em;  
        margin-top:1.3em;
    }
`;

export const GridWrapper = styled.div`
    grid-area: grid;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and ${desktopQuery}{
        height: 100%;
    }

    @media only screen and ${tabletQuery}{
        height: 98vw;
    }

    @media only screen and ${mobileQuery}{
        height: 140vw;
        width: 100vw;
    }
`;


        // height: 42.4vw;
        // width: 62.5vw;
        // margin-top: 3.63vh;
        // margin-right: 5.739vw;

        // display: grid;
        // grid-template-rows: repeat(3, 1fr);
        // grid-template-columns: repeat(4, 1fr);
        // grid-gap: 1.042vw;


export const ClientsGrid = styled.div`
    overflow: hidden;
    grid-auto-rows: 0;

    @media only screen and ${desktopQuery}{
        
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.8vw;
    }

    @media only screen and ${tabletQuery}{
        width: 90vw;
        height: 97.3vw;
        width: 94.66vw;
        
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2.604vw;
        
    }

    @media screen and ${mobileQuery}{
        width: 90.9vw;
        height: 139.2vw;
        
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2.604vw;
    }
`;

// height: 139.2vw;

export const ClientBox = styled.div`
    overflow: hidden;

    @media screen and ${desktopQuery}{
        height: 160px;
        width: 160px;
    }

    @media screen and ${desktopQuery} and (min-height: 800px){
        height: 180px;
        width: 180px;
    }

    @media screen and ${desktopQuery} and (min-height: 900px){
        height: 230px;
        width: 230px;
    }

    @media screen and ${desktopQuery} and (min-height: 1000px){
        height: 270px;
        width: 270px;
    }

    @media screen and ${mobileQuery}{
    }
`;
// height: 43vw;

export const ClientBoxWide = styled.div`
    @media screen and ${mobileQuery}{
        grid-column: span 2;
    }
    
    @media screen and ${mobileQuery}{
        display: none
    }
`;