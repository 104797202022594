import React from "react";
import richMediaColor from "../../assets/images/features-richmedia-color.png";
import richMediaGray from "../../assets/images/features-richmedia-gray.png";
import richMediaFull from "../../assets/images/features-richmedia-full.png";
import interactiveGray from "../../assets/images/features-interactive-gray.png";
import interactiveColor from "../../assets/images/features-interactive-color.png";
import interactiveFull from "../../assets/images/features-interactive-full.png";
import customizedGray from "../../assets/images/features-customized-gray.png";
import customizedColor from "../../assets/images/features-customized-color.png";
import customizedFull from "../../assets/images/features-customized-full.png";
import highImpactGray from "../../assets/images/features-highimpact-gray.png";
import highImpactColor from "../../assets/images/features-highimpact-color.png";
import highImpactFull from "../../assets/images/features-highimpact-full.png";
import quickGray from "../../assets/images/features-quick-gray.png";
import quickColor from "../../assets/images/features-quick-color.png";
import quickFull from "../../assets/images/features-quick-full.png";
import immersiveGray from "../../assets/images/features-immersive-gray.png";
// import immersiveColor from '../../assets/images/features-immersive-color.png'
// import immersiveColor from "../../assets/images/features-immresive-color-with-text.png";
import immersiveColor from "../../assets/images/featuresimmersivecolorimage-min.jpg";
import immersiveFull from "../../assets/images/features-immersive-full.png";

export const featuresData = {
  interactive: {
    title: "INTERACTIVE",
    body: () => (
      <>
        Navigate the way we are used to on mobile: swiping & not scrolling, not
        clicking & menus
      </>
    ),
    TextWrapperStyleProps: {
      marginTop: "auto",
      marginRight: "0px",
    },
    TextWrapperFullProps: {
      tablet: {
        marginRight: "30vw",
        marginTop: "24vh",
        marginLeft: "3.906vw",
      },
      mobile: {
        marginRight: "30vw",
        marginTop: "30vh",
        marginLeft: "3.906vw",
      },
    },
    imageFull: {
      tablet: {
        backgroundPosition: "center 60%",
        backgroundSize: "cover",
      },
      mobile: {
        backgroundPosition: "center 60%",
        backgroundSize: "cover",
      },
    },
    images: {
      colorPic: `${interactiveColor}`,
      grayPic: `${interactiveGray}`,
      fullPic: `${interactiveFull}`,
      opacity: "0.17",
      backgroundPositionDesktop: "60% center",
      backgroundSizeDesktop: "cover",
      backgroundPositionTablet: "90%",
      backgroundSizeTablet: "cover",
      backgroundPositionMobile: "80% 60%",
      backgroundSizeMobile: "250%",
    },
  },

  richMedia: {
    title: "RICH MEDIA",
    body: () => (
      <>
        Create defining brand moments with high&#8209;impact multimedia visuals
      </>
    ),
    TextWrapperStyleProps: {
      marginTop: "0",
      marginRight: "10vw",
    },
    TextWrapperFullProps: {
      tablet: {
        marginTop: "4.88vh",
        marginLeft: "3.906vw",
        marginRight: "42vw",
      },
      mobile: {
        marginTop: "4.88vh",
        marginLeft: "3.906vw",
        marginRight: "35vw",
      },
    },
    imageFull: {
      tablet: {
        backgroundPosition: "75%",
        backgroundSize: "cover",
      },
      mobile: {
        backgroundPosition: "65%",
        backgroundSize: "cover",
      },
    },
    images: {
      colorPic: `${richMediaColor}`,
      grayPic: `${richMediaGray}`,
      fullPic: `${richMediaFull}`,
      opacity: "0.83",
      backgroundPositionDesktop: "center",
      backgroundSizeDesktop: "cover",
      backgroundPositionTablet: "75%",
      backgroundSizeTablet: "cover",
      backgroundPositionMobile: "75%",
      backgroundSizeMobile: "200%",
    },
  },

  quick: {
    title: "QUICK",
    body: () => (
      <>Launch an experience in hours and make edits just as quickly</>
    ),
    TextWrapperStyleProps: {
      marginTop: "0",
      marginRight: "-0.5vw",
    },
    TextWrapperFullProps: {
      tablet: {
        marginTop: "4.88vh",
        marginLeft: "40vw",
      },
      mobile: {
        marginTop: "4.88vh",
        marginLeft: "40vw",
      },
    },
    imageFull: {
      tablet: {
        backgroundPosition: "40% center",
        backgroundSize: "cover",
      },
      mobile: {
        backgroundPosition: "40% center",
        backgroundSize: "cover",
      },
    },
    images: {
      colorPic: `${quickColor}`,
      grayPic: `${quickGray}`,
      fullPic: `${quickFull}`,
      opacity: "0.17",
      backgroundPositionDesktop: "left",
      backgroundSizeDesktop: "cover",
      backgroundPositionTablet: "24% 60%",
      backgroundSizeTablet: "380%",
      backgroundPositionMobile: "23% 60%",
      backgroundSizeMobile: "380%",
    },
  },

  highImpact: {
    title: "HIGH IMPACT",
    body: () => <>Engage customers longer and convert more on mobile</>,
    TextWrapperStyleProps: {
      marginTop: "0",
      marginRight: "3vw",
    },
    TextWrapperFullProps: {
      tablet: {
        marginTop: "64.88vh",
        marginLeft: "3.906vw",
        marginRight: "30vw",
      },
      mobile: {
        marginTop: "64.88vh",
        marginLeft: "3.906vw",
        marginRight: "30vw",
      },
    },
    imageFull: {
      tablet: {
        backgroundPosition: "10% center",
        backgroundSize: "cover",
      },
      mobile: {
        backgroundPosition: "10% center",
        backgroundSize: "cover",
      },
    },
    images: {
      colorPic: `${highImpactColor}`,
      grayPic: `${highImpactGray}`,
      fullPic: `${highImpactFull}`,
      opacity: "0.83",
      backgroundPositionDesktop: "center",
      backgroundSizeDesktop: "cover",
      backgroundPositionTablet: "15%",
      backgroundSizeTablet: "cover",
      backgroundPositionMobile: "13%",
      backgroundSizeMobile: "cover",
    },
  },

  customized: {
    title: "CUSTOMIZED",
    body: () => (
      <>
        Build a mobUX experience for every occasion, message, ad, and audience
      </>
    ),
    TextWrapperStyleProps: {
      marginTop: "0",
      marginRight: "0px",
    },
    TextWrapperFullProps: {
      tablet: {
        marginTop: "4.88vh",
        marginLeft: "3.906vw",
        marginRight: "40vw",
      },
      mobile: {
        marginTop: "4.88vh",
        marginLeft: "3.906vw",
        marginRight: "40vw",
      },
    },
    imageFull: {
      tablet: {
        backgroundPosition: "25% center",
        backgroundSize: "cover",
      },
      mobile: {
        backgroundPosition: "38% center",
        backgroundSize: "cover",
      },
    },
    images: {
      colorPic: `${customizedColor}`,
      grayPic: `${customizedGray}`,
      fullPic: `${customizedFull}`,
      opacity: "0.83",
      backgroundPositionDesktop: "center",
      backgroundSizeDesktop: "cover",
      backgroundPositionTablet: "center 20%",
      backgroundSizeTablet: "200%",
      backgroundPositionMobile: "center 20%",
      backgroundSizeMobile: "200%",
    },
  },

  immersive: {
    title: "IMMERSIVE",
    body: () => (
      <>
        Showcase content in a truly mobile&#8209;first format. Even save
        experiences as a tile on your phone
      </>
    ),
    TextWrapperStyleProps: {
      marginTop: "0",
      marginRight: "6.5vw",
    },
    TextWrapperFullProps: {
      tablet: {
        marginTop: "65vh",
        marginLeft: "3.906vw",
        marginRight: "10vw",
      },
      mobile: {
        marginTop: "65vh",
        marginLeft: "3.906vw",
        marginRight: "10vw",
      },
    },
    imageFull: {
      tablet: {
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      mobile: {
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
    },
    images: {
      colorPic: `${immersiveColor}`,
      grayPic: `${immersiveGray}`,
      fullPic: `${immersiveFull}`,
      opacity: "0.83",
      backgroundPositionDesktop: "70%",
      backgroundSizeDesktop: "cover",
      backgroundPositionTablet: "60%",
      backgroundSizeTablet: "300%",
      backgroundPositionMobile: "60%",
      backgroundSizeMobile: "300%",
    },
  },
};
