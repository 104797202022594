import styled from "styled-components";

const primaryWhite = ({ theme }) => theme.primaryWhite;
const overlayBlack = ({ theme }) => theme.overlayBlack;

export const ModalContainer = styled.aside`
  z-index: 98;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${overlayBlack};
`;

export const ModalArea = styled.div`
  font: inherit;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 4vmin;
  right: 4vmin;
  padding: 0.5em;
  line-height: 1;
  background: ${overlayBlack};
  border: 0;
  box-shadow: 0;
  cursor: pointer;
  outline: 0;
`;

export const CloseSpan = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`;

export const CloseIcon = styled.svg`
  width: 2.175em;
  height: 2.175em;
  fill: transparent;
  stroke: ${primaryWhite};
  stroke-linecap: round;
  stroke-width: 5;
`;

export const ModalBody = styled.div`
  padding-top: 0.25em;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
`;
