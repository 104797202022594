// Mobile S - 320px
// Mobile M - 375px
// Mobile L - 425px
// Tablet - 768px
// Laptop - 1024px
// Laptop L - 1440px
// 4K - 2560px

const size = {
   mobileS: "320px",
   mobileM: "375px",
   mobileL: "425px",
   mobileXL: "479px",
   tablet: "768px",
   tabletL: "991px",
   laptop: "1024px",
   laptopL: "1440px",
   desktop: "2560px",
};

export const responsive = {
   mobileS: `(min-width: ${size.mobileS})`,
   mobileM: `(min-width: ${size.mobileM})`,
   mobileL: `(min-width: ${size.mobileL})`,
   mobileXL: `(min-width: ${size.mobileXL})`,
   tablet: `(min-width: ${size.tablet})`,
   tabletL: `(min-width: ${size.tabletL})`,
   laptop: `(min-width: ${size.laptop})`,
   laptopL: `(min-width: ${size.laptopL})`,
   desktop: `(min-width: ${size.desktop})`,  
   desktopL: `(min-width: ${size.desktop})`,
};


// /*
//  * Author: http://stuffandnonsense.co.uk/blog/about/hardboiled_css3_media_queries/
//  */

// /* Smartphones (portrait and landscape) ----------- */
// @media only screen 
// and (min-device-width : 320px) 
// and (max-device-width : 480px) {
// /* Styles */
// }

// /* Smartphones (landscape) ----------- */
// @media only screen 
// and (min-width : 321px) {
// /* Styles */
// }

// /* Smartphones (portrait) ----------- */
// @media only screen 
// and (max-width : 320px) {
// /* Styles */
// }

// /* iPads (portrait and landscape) ----------- */
// @media only screen 
// and (min-device-width : 768px) 
// and (max-device-width : 1024px) {
// /* Styles */
// }

// /* iPads (landscape) ----------- */
// @media only screen 
// and (min-device-width : 768px) 
// and (max-device-width : 1024px) 
// and (orientation : landscape) {
// /* Styles */
// }

// /* iPads (portrait) ----------- */
// @media only screen 
// and (min-device-width : 768px) 
// and (max-device-width : 1024px) 
// and (orientation : portrait) {
// /* Styles */
// }

// /* Desktops and laptops ----------- */
// @media only screen 
// and (min-width : 1224px) {
// /* Styles */
// }

// /* Large screens ----------- */
// @media only screen 
// and (min-width : 1824px) {
// /* Styles */
// }

// /* iPhone 4 ----------- */
// @media
// only screen and (-webkit-min-device-pixel-ratio : 1.5),
// only screen and (min-device-pixel-ratio : 1.5) {
// /* Styles */
// }