export const theme = {
   primaryPurple: '#5f10a3',
   primaryDarkPurple: '#310854',
   primaryGreen: '#34e0b3',
   primaryLightGreen: '#62EED2',
   secondaryGrey: '#414141',
   primaryBlack: '#000000',
   primaryWhite: '#ffffff',
   primaryRed: '#FF0000',
   darkViolet: '#310854',
   secondaryLightGrey: '#707070',
   overlayBlack : '#000000d9'
};