import styled from 'styled-components'

// const tabletQuery = '(max-width: 768px) and (min-width: 491px)'
// const desktopQuery = '(min-width: 769px)'
// const mobileQuery = '(max-width: 491px)'

const tabletQuery = '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
const desktopQuery = '(min-width: 769px) and (orientation: landscape)'
const mobileQuery = '(max-width: 491px)'
const smallDesktopQuery = `${desktopQuery} and (max-height: 800px)`

export const OuterWrapper = styled.div` 
    height: 100%;
    width: 100%;
    background: #000000 0% 0% no-repeat padding-box; 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */


    &:hover {
        @media screen and ${tabletQuery}, ${mobileQuery}{
            cursor: pointer;
        } 
    }
`;

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex; 
    flex-direction: column;
    background-repeat: no-repeat;
    background: ${({ images }) => `linear-gradient(to bottom, rgba(0, 0, 0, ${images.opacity}), rgba(0, 0, 0, ${images.opacity})), url(${images.grayPic})`};
    
    @media screen and ${desktopQuery}{
        background: ${({ images, hovering }) => hovering
            ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${images.colorPic})`
            : `linear-gradient(to bottom, rgba(0, 0, 0, ${images.opacity}), rgba(0, 0, 0, ${images.opacity})), url(${images.grayPic})`};
        background-position: ${({ images }) => images.backgroundPositionDesktop};
        background-size: ${({ images }) => images.backgroundSizeDesktop};
    }

    @media screen and ${tabletQuery}{
        background-position: ${({ images }) => images.backgroundPositionTablet};
        background-size: ${({ images }) => images.backgroundSizeTablet};
    }

    @media screen and ${mobileQuery}{
        background-position: ${({ images }) => images.backgroundPositionMobile};
        background-size: ${({ images }) => images.backgroundSizeMobile};
    }

    @media screen and ${tabletQuery}, ${mobileQuery}{
        justify-content: center;
        align-items: center;
    }
`;

export const TextWrapper = styled.div`
    color: white;

    @media screen and ${desktopQuery}{
        padding: 0.73em 0.692em 1em;
        margin-top: ${({TextWrapperStyleProps}) => TextWrapperStyleProps.marginTop};
        margin-right: ${({TextWrapperStyleProps}) => TextWrapperStyleProps.marginRight};
    }
`;

export const Title = styled.h2`
    font-weight: bold;
    margin: 0;

    @media screen and ${desktopQuery}{
        font-size: 1.154em;
        margin-bottom: 0.6em;
    }

    @media screen and ${smallDesktopQuery}{
        font-size: 1em;
    }

    @media screen and ${tabletQuery}{
        font-size: 1.44em;
        margin: 0 0.2em;
    }

    @media screen and ${mobileQuery}{
        font-size: 1.388em;
    }
`;

export const Body = styled.div`
    font-weight: 400;

    @media screen and ${desktopQuery}{
        font-size: 0.769em;
    }

    @media screen and ${smallDesktopQuery}{
        font-size: 0.666em;
    }

    @media screen and ${tabletQuery}, ${mobileQuery}{
        display: none;
    }
`;