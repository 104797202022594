import React, { useState, useRef, useEffect } from 'react'
import { Container, HeaderWrapper, Heading, Text, BackgroundDiagonal,
         SubHeading, ClientsGrid, PurpleSpace, ClientBox, 
         ClientBoxWide, GridWrapper } from "./OurClientsStyles";
import Header from '../Header/Header';
import Client from './Client'
import useWindowDimensions from './useWindowDimensions'
import Carousel from './Carousel'
import { clientOrder } from './clientData'


const ContactUs = () => {
    const [carouselClient, _setCarouselClient] = useState(null)
    const carouselClientRef = useRef(carouselClient)

    const setCarouselClient = data => {
        carouselClientRef.current = data 
        _setCarouselClient(data)
    }

    const [desktop, _setDesktop] = useState(true)
    const desktopRef = useRef(desktop)

    const setDesktop = data => {
        desktopRef.current = data
        _setDesktop(data)
    }

    const { height, width } = useWindowDimensions()

    useEffect(() => {
        '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
        if (width <= 768 || height > width){
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }, [height, width])

    useEffect(() => {
        const wrapper = document.querySelector('.our-clients-container');
        wrapper.addEventListener('touchmove', touchMoveListener, false)
        return () =>  wrapper.removeEventListener('touchmove', touchMoveListener)
    }, [])

    const touchMoveListener = (e) => {
        // if (carouselClientRef.current && desktopRef.current) e.preventDefault()
    }

    const renderClients = () => {
       return [0,1,2,3,4,5,6,7,8].map(index => {
           const last = clientOrder.length - 1 === index
           const clientExists = clientOrder.length > index
           if (clientExists && last && index % 2 === 0){
            return <ClientBoxWide key={`client-${index}`}>
                        <Client 
                            client={clientOrder[index]} 
                            setCarouselClient={setCarouselClient} 
                            desktop={desktop} 
                        />
                    </ClientBoxWide>
           } else if (clientExists) {
            return <ClientBox key={`client-${index}`}>
                        <Client 
                            client={clientOrder[index]} 
                            setCarouselClient={setCarouselClient} 
                            desktop={desktop} 
                        />
                    </ClientBox>
           } else {
               return <ClientBox key={`client-${index}`}>
                        <Client setCarouselClient={setCarouselClient} />
                      </ClientBox>
           }
       })        
    }

    const renderClientsPage = () => (
        <Container>
            <HeaderWrapper>
                <Header />
            </HeaderWrapper>
            <BackgroundDiagonal />
            <PurpleSpace />
            <Text>
                <Heading>Our <span className="purple-text">Experiences</span></Heading>
                <SubHeading>
                    At mobUX, we work with a variety of clients ranging from FMCG brands to agencies who’d like to activate for their clients digitally. 
                    <br/><br/>
                    Like to find out more? Click on a client to see how we’ve activated with them. 
                </SubHeading>
            </Text> 
            <GridWrapper>
                <ClientsGrid>
                    {renderClients()}
                </ClientsGrid>  
            </GridWrapper>        
        </Container>
    )

    return (
        <div className="our-clients-container">
            {carouselClient
                ? <Carousel carouselClient={carouselClient} setCarouselClient={setCarouselClient}/>
                : null}
            {carouselClient && !desktop ? null : renderClientsPage()}
        </div>
    )
}

export default ContactUs;