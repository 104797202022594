import { SHOW_COOKIE_NOTIFICATION, HIDE_COOKIE_NOTIFICATION} from '../modules/constants.js'

const initialState = {
  showCookieNotification: false
}

const cookiesReducer = (state= initialState, action) => {
  switch(action.type){
    case SHOW_COOKIE_NOTIFICATION: {
      return {
        ...state,
        showCookieNotification: true
      }
    }
    case HIDE_COOKIE_NOTIFICATION: {
      return {
        ...state,
        showCookieNotification: false
      }
    }
    default:
      return state
    }
  }
  
export default cookiesReducer