const validation = require('../../modules/validate.js');

const validate = (values, field) => {   
   let errors = {};

   if (field === "all") {
      if (validation.isEmptyString(values.personName)) {
         errors.personName = "Name is required.";
      }

      if (validation.isEmptyString(values.emailAddress)) {
         errors.emailAddress = "Email address is required.";
      } else if (!validation.isValidEmailAddress(values.emailAddress)) {
         errors.emailAddress = "Email address is not valid.";
      }

      if (validation.isEmptyString(values.subject)) {
         errors.subject = "Subject can not be empty.";
      }

      if (validation.isEmptyString(values.message)) {
         errors.message = "Message can not be empty.";
      } 
   }
   // if on change a field, run validation only on that field's value
   else {
      switch (field) {
         case "personName":
            if (validation.isEmptyString(values.personName)) {
               errors.personName = "Name is required.";
            }
            break;
         case "emailAddress":
            if (validation.isEmptyString(values.emailAddress)) {
               errors.emailAddress = "Email address is required.";
            } else if (!validation.isValidEmailAddress(values.emailAddress)) {
               errors.emailAddress = "Email address is not valid.";
            }
            break;
         case "subject":
            if (validation.isEmptyString(values.subject)) {
               errors.subject = "Subject can not be empty.";
            }
            break;
         case "message":
            if (validation.isEmptyString(values.message)) {
               errors.message = "Message can not be empty.";
            }
            break;
         default:
            break;
      }
   }

   return errors;   
}

export default validate;