import React, { Fragment, useEffect } from "react";
import { ScrollLogo, ScrollWrapper, DownArrow } from "./scrollIndicatorStyles";

export default function ScrollIndicator() {
  const scrollEventHandler = (e) => {
    const rootScrollPosition = document
      .getElementById("root")
      .getBoundingClientRect();
    if (rootScrollPosition.top < -50) {
      document.getElementById("slider-indicator").style.opacity = 0;
    } else {
      document.getElementById("slider-indicator").style.opacity = 1;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEventHandler);
    return () => {
      window.removeEventListener("scroll", scrollEventHandler);
    };
  });

  return (
    <Fragment>
      <ScrollWrapper id="slider-indicator">
        <ScrollLogo>
          <div className="dot" />
        </ScrollLogo>
        <DownArrow className="down-arrrow" size={20} />
      </ScrollWrapper>
    </Fragment>
  );
}
