import React from "react";
import {
  Container,
  HeaderWrapper,
  Heading,
  Text,
  BackgroundDiagonal,
  SubHeading,
  FormSection,
  Map,
  PurpleSpace,
} from "./ContactUsStyles";
import ContactUsForm from "./ContactUsForm";
import Header from "../Header/Header";
import Notification from "../Notification/Notification";

const ContactUs = () => {
  return (
    <Container>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <BackgroundDiagonal />
      <PurpleSpace />
      <Text>
        <Heading>Let's Chat!</Heading>
        <SubHeading>
          <span className="sub-good">
            Good, bad or if you’d just like to chat over a{" "}
          </span>
          <span className="sub-coffee">coffee, we would </span>
          <span className="sub-love">love to hear from you. </span>
          <span className="sub-get">Get in </span>
          <span className="sub-touch">touch, leaving us a message </span>
          <div className="purple-address">
            <span className="sub-150">150 W 28th St., Fl 2 </span>
            <span className="sub-newyork">New York, NY 10001</span>
          </div>
        </SubHeading>
      </Text>
      <Map
        href="https://www.google.com/maps/place/150+W+28th+St+Fl+2,+New+York,+NY+10001/@40.7467431,-73.9950684,17z/data=!3m1!4b1!4m5!3m4!1s0x89c259af8a6af743:0x44547559130c7df4!8m2!3d40.7467391!4d-73.9928797"
        target="_blank"
      />
      <FormSection>
        <ContactUsForm />
      </FormSection>
      <Notification />
    </Container>
  );
};

export default ContactUs;
