import React from 'react';
import { NavLink } from 'react-router-dom';
import { Wrapper, Logo, Text, Heading, Description, Image } from './SubscriptionStyles';
import LogoImage from "../../assets/images/logo-white-green-hires.png";

const Subscription = ({ isSubscribed }) => {
   const { headingText, descriptionText, link } = subscription[isSubscribed];

   return (
      <Wrapper>
         <NavLink to='/'>
            <Logo src={LogoImage} alt='mobUX Home Page' />
         </NavLink>
         <Text>
            <Heading>{headingText}</Heading>
            <Description>
               {descriptionText} {link()}
            </Description>
         </Text>
         <Image isSubscribed={isSubscribed}></Image>
      </Wrapper>
   );

}
   

export default Subscription;

const subscription = {
   true: {
      headingText: `You're subscribed!`,
      descriptionText: `Congratulations, you’ve subscribed to our newsletter. \n You can view our site`,
      link: () => <NavLink to='/'>here!</NavLink>,
   },
   false: {
      headingText: `We're sorry to see you go!`,
      descriptionText: `But if you make a mistake or miss us \n already, subscribe with us`,
      link: () => <NavLink to='/'>here!</NavLink>,
   },
};
