import React, { useState, useEffect } from 'react';
import { debounce } from '../../modules/debounce';

const useWindowSize = () => {
   const isWindowClient = typeof window === "object";
   const [windowSize, setWindowSize] = useState(
      isWindowClient ? window.innerWidth : undefined
   );

   useEffect(() => {
      const debouncedHandleResize = debounce(() => {
         setWindowSize(window.innerWidth);
      }, 1000);

      if (isWindowClient) {
         window.addEventListener("resize", debouncedHandleResize);
      };

      return () => window.removeEventListener("resize", debouncedHandleResize);
   }, [isWindowClient, setWindowSize]);
   
   return windowSize;
}

export default useWindowSize;