import styled from 'styled-components';

const primaryGreen = ({ theme }) => theme.primaryGreen;
const primaryRed = ({ theme }) => theme.primaryRed;
const primaryWhite = ({ theme }) => theme.primaryWhite;

const desktopQuery = "(min-width: 769px) and (orientation: landscape)";
const tabletQuery = "(max-width: 768px) and (min-width: 491px), (orientation: portrait)";
const mobileQuery = "(max-width: 491px)";


export const Content = styled.div`   
   text-align: center;

   .message {
      font: inherit;      
      font-size: 1.154em;
      line-height: 1.308em;   
      color: ${primaryWhite};   

      .highlight {
         font-weight: 500;     
      }
      
      .error {      
         color: ${primaryRed};       
      }

      .success {         
         color: ${primaryGreen};      
      }
   }

   .icon {
      padding-bottom: 1em;

      svg {
         width: 260px;
         height: 244px;
         fill: transparent;
         stroke: ${primaryWhite};
         stroke-linecap: round;         
      }
   }  

   img {
      width: 53vw;
   }
   
   @media only screen and ${desktopQuery} {
      img {
         width: 35vw;
      }
   }   
`;