import React from "react";
import {
  LinkWrapper,
  NavLinkWrapper,
  Text,
  ArrowImage,
  DisabledLink,
} from "./ArrowLinkStyles";
import { TermsAndConditionsNavLink } from "../HamburgerMenu/HamburgerMenuStyles";

const ArrowLink = ({
  text,
  link,
  white,
  navLink,
  smaller = false,
  disable = false,
  showArrow = true
}) => {
  const renderLink = () => (
    <LinkWrapper className="arrowlink-wrapper" href={link} target="_blank">
      <Text className="arrowlink-text" smaller={smaller} white={white}>
        {text}
      </Text>
      <ArrowImage white={white} />
    </LinkWrapper>
  );

  const renderNavLink = () => (
    <NavLinkWrapper
      className="arrownavlink-wrapper"
      href={navLink}
      data-value="explore-work-referal"
      id="arrow-link"
    >
      <Text className="arrowlink-text" white={white} smaller={smaller}>
        {text}
      </Text>
       <ArrowImage white={white} />
    </NavLinkWrapper>
  );

  const renderDisabledLink = () => (
    <DisabledLink className="disabledlink-wrapper">
      <Text className="disabledlink-text" white={white} smaller={smaller}>
        {text}
      </Text>
      {showArrow && <ArrowImage white={white} />}
    </DisabledLink>
  );

  return disable
    ? renderDisabledLink()
    : navLink
    ? renderNavLink()
    : renderLink();
};

ArrowLink.defaultProps = {
  text: "EXPLORE OUR WORK",
  link: "https://themobux.com/",
};

export default ArrowLink;
