import React, { useState, useEffect, useRef } from 'react'
import Header from '../Header/Header'
import Feature from './Feature'
import FullFeature from './FullFeature'
import { OuterWrapper, ContentGrid, TextSection, LargeText, SmallText,
         PurpleText, FeaturesGrid, Interactive, RichMedia, HeaderWrapper,
         Customized, Quick, HighImpact, Immersive, BackgroundDiagonalMobile } from './FeaturesContainerStyles'
import { featuresData } from "./featuresData";

const Features = () => {
    const [clickedFeature, _setClickedFeature] = useState(null)
    const clickedFeatureRef = useRef(clickedFeature)

    const setClickedFeature = data => {
        clickedFeatureRef.current = data 
        _setClickedFeature(data)
    }

    useEffect(() => {
        const wrapper = document.querySelector('.outer-wrapper');
        wrapper.addEventListener('touchmove', touchMoveListener, false)
        return () =>  wrapper.removeEventListener('touchmove', touchMoveListener)
    }, [])

    const touchMoveListener = (e) => {
        if(clickedFeatureRef.current) e.preventDefault()
    }

    const featureProps = {
       clickedFeature: clickedFeature,
       setClickedFeature: setClickedFeature,
       className: "feature",
       isHovering: false,
       wrapperClassName: "feature-outer-wrapper",
       contentClassName: "feature-inner-wrapper",
       textWrapperClassName: null,
       titleClassName: "feature-title",
       descriptionClassName: "feature-body",
       featuresData: featuresData
    };

    return (
        <OuterWrapper className="outer-wrapper">
            <FullFeature className="full-feature" clickedFeature={clickedFeature} setClickedFeature={setClickedFeature} />
            <ContentGrid>
                <BackgroundDiagonalMobile />
                <HeaderWrapper>
                    <Header allPurple={true} />
                </HeaderWrapper>
                <TextSection>
                    <LargeText>We Create<PurpleText> Robust Features</PurpleText> <br/><span className="experience-text">with every experience</span></LargeText>
                    <SmallText>Our constantly evolving technology ensures your ongoing success.</SmallText>
                </TextSection>
                <FeaturesGrid>
                    <Interactive><Feature feature="interactive" {...featureProps} /></Interactive>
                    <RichMedia><Feature feature="richMedia" {...featureProps} /></RichMedia>
                    <Customized><Feature feature="customized" {...featureProps} /></Customized>
                    <Quick><Feature feature="quick" {...featureProps} /></Quick>
                    <HighImpact><Feature feature="highImpact" {...featureProps} /></HighImpact>
                    <Immersive><Feature feature="immersive" {...featureProps} /></Immersive>
                </FeaturesGrid>
            </ContentGrid>
        </OuterWrapper>
    )
}

export default Features
