import styled from 'styled-components'
const primaryPurple = ({ theme }) => theme.primaryPurple;
const primaryWhite = ({ theme }) => theme.primaryWhite;
const primaryBlack = ({ theme }) => theme.primaryBlack;
const secondaryGrey = ({theme}) => theme.secondaryGrey;
// const tabletQuery = '(max-width: 768px) and (min-width: 491px)'
// const desktopQuery = '(min-width: 769px)'
// const mobileQuery = '(max-width: 491px)'

const tabletQuery = '(max-width: 768px) and (min-width: 491px), (min-width: 769px) and (orientation: portrait)'
const desktopQuery = '(min-width: 769px) and (orientation: landscape)'
const mobileQuery = '(max-width: 491px)'

const smallDesktopQuery = `${desktopQuery} and (max-height: 800px)`

export const OuterWrapper = styled.div`
    @media screen and ${tabletQuery}{
        background: ${primaryPurple};
    }
`;

export const ContentGrid = styled.div`
    @media screen and ${tabletQuery} {
        display: grid;
        min-height: 100vh;
        height: min-content;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas: "head"
                             "text"
                             "feat";
        background: linear-gradient(-50deg, ${primaryPurple} 41.5%, ${primaryWhite} 41.5%);             
    }

    @media screen and ${tabletQuery} and (max-height: 800px) {
        background: linear-gradient(-20deg, ${primaryPurple} 39%, ${primaryWhite} 39%);             
    }

  
    

    @media screen and ${mobileQuery} {
        display: grid;
        height: min-content;
        grid-template-columns: 1fr 0px;
        grid-template-rows: repeat(3, auto);
        grid-template-areas: "head head"
                             "text diag"
                             "feat diag";
        background-image: -webkit-linear-gradient(90deg, ${primaryPurple} 50%, ${primaryWhite} 50%);
        background-color: ${primaryWhite};
    }
`;

export const BackgroundDiagonalMobile = styled.div` 
    grid-area: diag;

    @media screen and ${desktopQuery}, ${tabletQuery}{
        display: none;
    }

    @media screen and ${mobileQuery}{
        background-color: ${primaryPurple};
        background: linear-gradient(-20deg, ${primaryPurple} 39%, ${primaryWhite} 39%);             
        margin: 35vw 0 0 -100vw;
        width: 100vw;
        height: 67vh;
    }

    @media screen and ${mobileQuery} and (max-width: 350px) {
        margin-top: 45vw;
    }
`;

export const HeaderWrapper = styled.div`
    @media screen and ${tabletQuery}, ${mobileQuery}{
        grid-area: head;
    }
`;

export const TextSection = styled.div` 
    text-align: center;
    grid-area: text;
    color: ${secondaryGrey};

    @media screen and ${desktopQuery}{
        margin: 4.48vh auto 6.267vh;
        width: 39.31em;
    }
    
    @media screen and ${smallDesktopQuery}{
        width: 28em;
    }

    @media screen and ${tabletQuery}{
        margin: 7.1vh auto 6.65vh;
        width: 30em;
        height: max(21.7vh, content);
        // background-color:red
    }

    @media screen and ${mobileQuery}{
        margin: 2em auto 2.8em;
        width: min(22em, 90vw);
    }
`;

export const LargeText = styled.h1`
    margin: 0;
    font-weight: 500;
    color:black;

    @media screen and ${desktopQuery}{
        font-size: 2.077em;
        // line-height: 0.825em;
        margin-bottom: 0.15em;
    }

    @media screen and ${smallDesktopQuery}{
        font-size: 2em;
    }

    @media screen and ${tabletQuery}{
        font-size: 2.265em;
        margin-bottom: 0.3em;
        // line-height: 1.08em;

        .experience-text{
            display:block;
            line-height:2.5;
            font-size: 0.8em;
        }
    }

    @media screen and ${mobileQuery}{
        font-size: 1.559em;
    }
`;

export const PurpleText = styled.span`
    color: ${primaryPurple};
`;

export const SmallText = styled.p` 
    margin: 0;
    margin-top:1em;

    @media screen and ${desktopQuery}{
        font-size: 1em;
    }

    @media screen and ${smallDesktopQuery}{
        font-size: 0.9em;
    }

    @media screen and ${tabletQuery}{
        font-size: 1.1em;
        width:70%;
        margin-left:auto;
        margin-right:auto;
        // line-height: 1.15em;
    }

    @media screen and ${mobileQuery}{
        font-size: 1.11em;
    }
`;

export const FeaturesGrid = styled.div`
    display: grid;
    grid-area: feat;

    @media screen and ${desktopQuery}{
        grid-template-rows: 54fr 46fr;
        grid-template-columns: repeat(7, 1fr);
        grid-template-areas: "in in rm rm cu im im"
                             "in in qu hi hi im im";
        grid-gap: 0.78vw;
        width: 91.8vw;
        height: 52.59vh;
        margin: 0 auto 8vh;
    }

    @media screen and ${tabletQuery}{
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "in rm qu"
                             "hi cu im";
        grid-gap: 1.5625vw;
        width: 91.8vw;
        height: 50.59vh;
        min-height: 60vw;
        margin: 0 auto 9.2vh;
    }

    @media screen and ${mobileQuery}{
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "in rm"
                             "qu hi"
                             "cu im";
        grid-gap: 2vw;
        width: 91.8vw;
        height: 85.71vh;
        max-height: 572px;
        margin: 0 auto 4.95vh;
    }
`;

export const Interactive = styled.div`
    grid-area: in;
`;

export const RichMedia = styled.div`
    grid-area: rm;
`;

export const Quick = styled.div`
    grid-area: qu;
`;

export const HighImpact = styled.div`
    grid-area: hi;
`;

export const Customized = styled.div`
    grid-area: cu;
`;

export const Immersive = styled.div`
    grid-area: im;
`;