const validate = (values, field) => {
   let errors = {};   
   let phoneNumber = values.countryCode + values.phoneNumber;

   // if submitting, check all validation of all fields
   if (field === 'all') {
      if (isEmptyString(values.personName)) {
         errors.personName = "Name is required";
      }

      if (isEmptyString(values.countryCode)) {
         errors.countryCode = "Country code is required, for example +65";
      }

      if (isEmptyString(values.phoneNumber)) {
         errors.phoneNumber = "Phone number is required.";
      } else if (!isValidPhoneNumberFormat(phoneNumber)) {
         errors.phoneNumber = "Phone number is not valid";
      }

      if (isEmptyString(values.emailAddress)) {
         errors.emailAddress = "Email address is required";
      } else if (!isValidEmailAddress(values.emailAddress)) {
         errors.emailAddress = "Email address is not valid";
      }
   }
   // if on change a field, run validation only on that field's value
   else {
      switch (field) {
         case "personName":
            if (isEmptyString(values.personName)) {
               errors.personName = "Name is required";
            }
            break;
         case "countryCode":
            if (isEmptyString(values.countryCode)) {
               errors.countryCode = "Country code is required, for example +65";
            }
            break;
         case "phoneNumber":
            if (isEmptyString(values.phoneNumber)) {
               errors.phoneNumber = "Phone number is required.";
            } else if (!isValidPhoneNumberFormat(phoneNumber)) {
               errors.phoneNumber = "Phone number is not valid";
            }
            break;
         case "emailAddress":
            if (isEmptyString(values.emailAddress)) {
               errors.emailAddress = "Email address is required.";
            } else if (!isValidEmailAddress(values.emailAddress)) {
               errors.emailAddress = "Email address is not valid";
            }
            break;
         default:
            break;
      }
   }   

   return errors;
}

const isEmptyString = (str) => {
   if (!str || str === "") {
      return true;
   }
   return false;
}

const isValidPhoneNumberFormat = (str) => {
   let regex = /^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/;
   
   if (regex.test(str)) {      
      return true;
   }
   return false;
}

const isValidEmailAddress = (str) => {
   let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

   if (regex.test(str)) {
      return true;
   }
   return false;
}



export default validate;