import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select-search';
import validate from './DemoFormValidation';
import data from './CountryCodes.json';
import { NavLink } from 'react-router-dom';
import {
   DemoFormWrapper,
   DemoFormField,
   DemoFormBody,
   MobilePhoneFieldGroup,
   DemoFormSubmitButton,
   DemoFormErrorMessage,
   AgreementText,
} from './DemoFormStyles';
import Button from '../Button/Button';
import { ModalContext } from '../Modal/modalContext';

const INITIAL_PHONE_CODE = '+1';
const PHONE_NUMBER_PATTERN = '^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/';
const EMAIL_ADDRESS_PATTERN = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$';
const ACTIVE_CAMPAIGN_DEMO_API_URL = `https://api.themobux.com/v1/contact`;
const PROSPECT_LIST_ID = 8;

const countryCodeList = data.map((item, idx) => ({
   key: idx,
   value: item.dial_code,
   name: item.name,
}));

const renderCountryCodeValue = (valueProps, snapshot, className) => {      
   return (
      <input {...valueProps} className={className} value={snapshot.value ? snapshot.value.value : INITIAL_PHONE_CODE} />
   );
}

const renderCountryName = (props, option, snapshot, className) => {
   const countryCodeStyle = {
      color: '#414141',
      opacity: '0.8'
   };

   return (
      <button {...props} className={className} type='button'>
         <span>
            <span style={countryCodeStyle}>{option.name}</span>&nbsp;
            <span>{option.value}</span>
         </span>
      </button>
   );
}

const DemoForm = ({ initFields, agreementText }) => {      
   const [values, setValues] = useState(initFields);   
   const [errors, setErrors] = useState({});   
   const [isSubmitting, setIsSubmitting] = useState(false);
   const { handleModal } = useContext(ModalContext);

   useEffect(() => {                  
      if (Object.keys(errors).length === 0 && isSubmitting) {                  
         handleModal('loading');
         sendMessage();
      } 
      else if (isSubmitting) {
         setIsSubmitting(false);
      }
   }, [values, errors]);

   const resetFields = () => {
      setValues({ ...initFields });
   };

   const handleChange = (e) => {  
      if (e instanceof Object) {
         e.persist();
         setValues((values) => {
            let newValues = { ...values, [e.target.name]: e.target.value };
            setErrors(validate(newValues, e.target.name));            
            return newValues;
         });
      } else {
         setValues((values) => {
            let newValues = { ...values, countryCode: e };
            setErrors(validate(newValues, 'countryCode'));
            return newValues;
         });
      }     
   };

   const _buildData = () => {
      const data = {
         'list': PROSPECT_LIST_ID,
         'name': values.personName,
         'phone': values.countryCode + values.phoneNumber,
         'email': values.emailAddress
      };

      return data;
   }

   const sendMessage = () => {   
      const data = _buildData();  
      
      fetch(ACTIVE_CAMPAIGN_DEMO_API_URL, {
         method: 'POST',
         headers: {
            'Content-Type': 'text/plain',
         },
         body: JSON.stringify(data),
         mode: 'cors'
      })
      .then((response) => {                                                       
         if (response.ok) {                 
            resetFields();         
            handleModal('sent-success');            
         }
         else {
            handleModal('sent-error');
         }         
      })            
      .catch((error) => {             
         handleModal('sent-error');
      });     
      
      setIsSubmitting(false);
   };

   const handleSubmit = async (e) => {      
      if (e) e.preventDefault();           
      setIsSubmitting(true);       
      setErrors(validate(values, 'all'));               
   };

   return (
      <DemoFormWrapper id='_form_5_' method='post'>
         <DemoFormBody>
            <DemoFormField>
               <input
                  style={{ marginRight: `5px` }}
                  type='text'
                  id='personName'
                  name='personName'
                  value={values.personName}
                  placeholder='Name'
                  onChange={handleChange}
                  aria-label='Name'
                  aria-describedby='errorName'
                  aria-invalid={errors.personName ? 'true' : 'false'}
               />
               <DemoFormErrorMessage>
                  <span id='errorName'>{errors.personName}</span>
               </DemoFormErrorMessage>
            </DemoFormField>

            <DemoFormField>
               <MobilePhoneFieldGroup>
                  {/* TODO: Add aria accessibility in the component                */}
                  <Select
                     // id='countryCode'
                     // aria-label='International Country Code'
                     // aria-required='true'
                     // required
                     // className='select-search__input'
                     value={values.countryCode}
                     options={countryCodeList}
                     renderValue={renderCountryCodeValue}
                     renderOption={renderCountryName}
                     placeholder='+1'
                     // printOptions='always'
                     onChange={handleChange}
                  />

                  <input
                     type='text'
                     id='phoneNumber'
                     name='phoneNumber'
                     pattern={`${PHONE_NUMBER_PATTERN}`}
                     value={values.phoneNumber}
                     placeholder='Mobile Number'
                     onChange={handleChange}
                     aria-label='Mobile Phone Number'
                     aria-describedby='errorPhoneNumber'
                     aria-invalid={errors.phoneNumber ? 'true' : 'false'}
                  />
               </MobilePhoneFieldGroup>
               <DemoFormErrorMessage>                  
                  <span id='errorPhoneNumber'>
                     {errors.countryCode}
                     {errors.phoneNumber}
                  </span>                  
               </DemoFormErrorMessage>
            </DemoFormField>
            <DemoFormField>
               <input
                  type='text'
                  id='emailAddress'
                  name='emailAddress'
                  pattern={`${EMAIL_ADDRESS_PATTERN}`}
                  value={values.emailAddress}
                  placeholder='Email Address'
                  onChange={handleChange}
                  aria-label='Email Address'
                  aria-describedby='errorEmailAddress'
                  aria-invalid={errors.emailAddress ? 'true' : 'false'}
               />
               <DemoFormErrorMessage>
                  <span id='errorEmailAddress'>{errors.emailAddress}</span>
               </DemoFormErrorMessage>
            </DemoFormField>
            <DemoFormField>
               <AgreementText>
                  {agreementText.text1} {agreementText.termConditionLink()}
                  {', '}
                  {agreementText.privacyPolicyLink()}
                  {', '}
                  {agreementText.cookiePolicyLink()}
                  {'. '}
                  {agreementText.text2}
               </AgreementText>
            </DemoFormField>
         </DemoFormBody>
         <DemoFormSubmitButton>
            <Button handleSubmit={handleSubmit} />
         </DemoFormSubmitButton>
         <DemoFormErrorMessage>
            <span id='errorServer'>{errors.server}</span>
         </DemoFormErrorMessage>
      </DemoFormWrapper>
   );
};

DemoForm.defaultProps = {
   initFields: {
      personName: '',
      countryCode: INITIAL_PHONE_CODE,
      phoneNumber: '',
      emailAddress: '',
   },
   headingText: `Interested to find out more? \n Get a demo on us today!`,   
   agreementText: {
      text1: `By submitting you are agreeing to mobUX’s`,
      termConditionLink: () => <NavLink to='/terms-and-conditions'>Terms of Service</NavLink>,
      privacyPolicyLink: () => <NavLink to='/privacy-policy'>Privacy Policy</NavLink>,
      cookiePolicyLink: () => <NavLink to='/cookie-policy'>Cookie Policy</NavLink>,
      text2: `Message and Data rates may apply.`,
   },
};

export default DemoForm;


